import Input from "components/shared/Input/Input";
import SelectSpeed from "components/componenetInput/SelectSpeed";
import { useContext, useEffect, useState, useRef } from "react";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import axios from "axios";
import { baseURL } from "config";
import { UserDataContext } from "App";
import { useNavigate } from "react-router-dom";
import { apiFetchTaxRateFromZipCode } from "components/searchCars/request/apiFetchTaxRateFromZipCode";
import { BreakDownPartCalculator } from "./CalculatorParts";
import Button from "components/shared/Button";

import cn from "classnames";

const Calculator = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const [userData] = useContext(UserDataContext);
  const navigate = useNavigate();
  const refUploadInfo = useRef(null);
  const [zipCodeError, setZipCodeError] = useState(true);
  const [isZipCodeChecked, setIsZipCodeChecked] = useState(false);
  const [form, setForm] = useState({
    price: modalCar?.calc?.price ?? modalCar?.price ?? 0,
    downPayment: modalCar?.calc?.downPayment ?? 0,
    taxRate: modalCar?.calc?.taxRate * 100 || 0,
    needFinancing: modalCar?.calc?.needFinancing ?? true,
    monthlyPayment: modalCar?.calc?.monthlyPayment ?? 0,
    totalDue: modalCar?.calc?.totalDue ?? 0,
    apr: modalCar?.calc?.apr ?? 8.99,
    loanTerm: modalCar?.calc?.loanTerm ?? 72,

    hasTradeIn: modalCar?.calc?.hasTradeIn ?? false,

    tradeInValue: parseInt(modalCar?.calc?.tradeInValue) || 0,
    tradeInLoanBalance: parseInt(modalCar?.calc?.tradeInLoanBalance) || 0,

    zipCode: modalCar?.user_zip_code ?? "",
  });

  useEffect(() => {
    refUploadInfo.current.classList.add("hidden");
    // refUploadInfo.current.querySelector(".counter").innerHTML = `(${counterUploads} / ${modalCar?.trade?.images?.length})`;
    refUploadInfo.current.nextElementSibling.removeAttribute("disabled");

    // Check Zip Code validity and set the Tax Rate
    apiFetchTaxRateFromZipCode(form.zipCode, (error, data) => {
      if (error || data?.success === 0) {
        setZipCodeError(true);
        return;
      }
      setForm((prev) => {
        return {
          ...prev,
          taxRate: parseFloat((data?.rate * 100).toFixed(2)),
        };
      });
      setZipCodeError(false);
      setIsZipCodeChecked(true);
    });
  }, []);

  useEffect(() => {
    dispatchCalc();
  }, [form]);

  function dispatchCalc() {
    let { zipCode, taxRate, ...otherForm } = form;
    taxRate = parseFloat(taxRate) / 100 || 0;
    dispatchModalCar({
      type: "calc-update",
      payload: {
        ...otherForm,
        taxRate,
      },
    });
    dispatchModalCar({ type: "calculate" });
  }

  function handleChangesForm(e) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    let name = e.target.name;
    let value = e.target.value;
    if (
      name === "price" ||
      name === "downPayment" ||
      name === "tradeInValue" ||
      name === "tradeInLoanBalance"
    ) {
      if (isNaN(value)) {
        value = 0;
      }
      value = parseInt(value);
      value = value > 500_000 ? 500_000 : value;
    }
    if (name === "apr" || name === "taxRate") {
      // value = parseFloat(value);
      value = value > 100 ? 100 : value;
    }

    setForm({
      ...form,
      [e.target.name]: value,
    });
  }

  function handleUpdateNeedFinancing(option) {
    setForm({
      ...form,
      needFinancing: option,
    });
  }

  function handleTradeInSubModal() {
    dispatchModalCar({
      type: "update",
      payload: {
        currentSubModal: "tradeByType",
      },
    });
  }

  function handleChangesZipCode(e) {
    setForm({
      ...form,
      zipCode: e.target.value,
      taxRate: 0,
    });

    apiFetchTaxRateFromZipCode(e?.target?.value, (error, data) => {
      if (error || data?.success === 0) {
        setZipCodeError(true);
        return;
      }
      setForm((prev) => {
        return {
          ...prev,
          taxRate: parseFloat((data?.rate * 100).toFixed(2)),
        };
      });
      setZipCodeError(false);
    });
  }

  const validateForm = () => {
    let { price, zipCode, taxRate } = form;
    if (
      !price ||
      price === 0 ||
      taxRate === "" ||
      zipCode === "" ||
      zipCodeError ||
      isZipCodeChecked
    ) {
      return false;
    }
    return true;
  };

  function handleMakeThisOffer() {
    let counterUploads = 0;
    apiRequestMakeThisOfferStart(modalCar, userData.token, (error, data) => {
      let { success, ...otherData } = data;

      if (success === 0) {
        dispatchModalCar({
          type: "update",
          payload: {
            msg: {
              type: "error",
              message: data?.error,
            },
          },
        });
        return;
      }
      let { negotitation_code, car_id } = otherData;
      // http://localhost:3000/negotiations?code=
      // navigate(`/negotiations?code=${negotitation_code}`);
      for (let i = 0; i < modalCar?.trade?.images?.length; i++) {
        let { File, local_id, progress, status } = modalCar?.trade?.images[i];
        apiUploadImage(
          { file: File, car_id, token: userData.token },
          {
            doneCallback: (error, data) => {
              counterUploads++;
              if (counterUploads === modalCar?.trade?.images?.length) {
                navigate(`/negotiations?code=${negotitation_code}`);
              }
              refUploadInfo.current.classList.remove("d-none");
              refUploadInfo.current.querySelector(
                ".counter"
              ).innerHTML = `(${counterUploads} / ${modalCar?.trade?.images?.length})`;
              refUploadInfo.current.nextElementSibling.setAttribute(
                "disabled",
                true
              );
            },
          }
        );
      }
      if (!modalCar?.trade?.images || modalCar?.trade?.images?.length === 0) {
        navigate(`/negotiations?code=${negotitation_code}`);
      }
    });
  }

  if (!modalCar?.calc) {
    return <></>;
  }
  function handleBackButton() {
    dispatchModalCar({
      type: "update",
      payload: {
        currentSubModal: "yourAccount",
      },
    });
  }
  const isFormValid = validateForm();
  return (
    <div className="relative before:absolute before:content-[''] before:top-0 before:w-1/2 before:left-1/4 before:h-[1px] before:bg-neutral-light mt-4 pt-4 flex-col w-full">
      <div className="flex flex-col xl:flex-row">
        <div className="relative xl:before:absolute xl:before:w-[1px] xl:before:h-1/2 xl:before:right-0 xl:before:top-1/4 before:bg-neutral-light w-full xl:w-1/2 xl:pr-14 md:pr-0">
          <div className="flex flex-col gap-4">
            <div>
              <Input
                name="price"
                type="number"
                onChange={handleChangesForm}
                value={form.price}
                isInvalid={!form.price || form.price === 0}
                errorText="Price must be greater than 0"
                label="Car Price"
                icon="$"
                rounded="xs"
                placeholder="Enter here..."
              />
            </div>
            <div className="flex gap-1">
              <Input
                value={form.zipCode}
                name="zipCode"
                isInvalid={!form.zipCode || zipCodeError}
                errorText="Invalid Zip Code"
                type="number"
                onChange={handleChangesZipCode}
                containerClassName="w-1/2"
                label="Zip Code"
                rounded="xs"
                placeholder="Enter Code..."
              />
              <Input
                value={form.taxRate}
                name="taxRate"
                isInvalid={form.taxRate === ""}
                errorText="Invalid Tax Rate"
                type="number"
                onChange={handleChangesForm}
                containerClassName="w-1/2"
                label="Tax Rate"
                icon="%"
                rounded="xs"
                placeholder="Enter Tax here..."
              />
            </div>

            {!form.hasTradeIn && (
              <div className="mb-4">
                <div className="text-base font-medium leading-normal text-primary my-1 ">
                  Trade-in / Loan Balance
                </div>
                <Button
                  onClick={handleTradeInSubModal}
                  style={{ backgroundColor: "#1C74C2" }}
                  className="w-full"
                >
                  + Add Trade-In
                </Button>
              </div>
            )}

            {form.hasTradeIn && (
              <div className="flex my-1">
                <Input
                  className="text-base"
                  name="tradeInValue"
                  value={form.tradeInValue}
                  onChange={handleChangesForm}
                  containerClassName="w-1/2"
                  label="Trade in value"
                  icon="$"
                  rounded="xs"
                  placeholder="Enter Trade in value"
                />
                <Input
                  className="text-base"
                  name="tradeInLoanBalance"
                  value={form.tradeInLoanBalance}
                  onChange={handleChangesForm}
                  containerClassName="w-1/2"
                  label="Loan Balance"
                  icon="$"
                  rounded="xs"
                  placeholder="Enter Trade-in Loan Balance"
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full xl:w-1/2 xl:pl-14">
          <div className="flex flex-col">
            {modalCar.calc.needFinancing ? (
              <>
                <div className="flex gap-1">
                  <Input
                    className="text-base"
                    name="downPayment"
                    type="number"
                    onChange={handleChangesForm}
                    value={form.downPayment}
                    containerClassName="w-1/2"
                    label="Down-Payment"
                    icon="$"
                    rounded="xs"
                    placeholder="Enter here..."
                  />

                  <SelectSpeed
                    name="loanTerm"
                    onChange={handleChangesForm}
                    value={form.loanTerm}
                    containerClassName="w-1/2 flex flex-col"
                    selectClassName="rounded-[2px] bg-neutral-light hover:cursor-pointer flex-1"
                    label="Length of Loan"
                    options={[
                      { value: "12", label: "12 Months" },
                      { value: "24", label: "24 Months" },
                      { value: "36", label: "36 Months" },
                      { value: "48", label: "48 Months" },
                      { value: "60", label: "60 Months" },
                      { value: "72", label: "72 Months" },
                    ]}
                  />
                </div>
                <div className="mt-4">
                  <Input
                    name="apr"
                    onChange={handleChangesForm}
                    value={form.apr}
                    label="Annual Percentage rate (APR)"
                    icon="%"
                    rounded="xs"
                    placeholder="Enter APR here..."
                  />
                </div>

                <div className="flex justify-end">
                  <Button
                    onClick={() => handleUpdateNeedFinancing(false)}
                    className="text-[#1C74C2] text-sm font-normal !px-0 pt-[5px] underline hover:no-underline"
                    variant="link"
                  >
                    No Finance
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Button
                  onClick={() => handleUpdateNeedFinancing(true)}
                  className="w-full mt-7"
                >
                  + Add Financing
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="bg-neutral-light relative before:absolute before:w-[150%] before:bg-neutral-light before:-left-[10%] before:h-[110%] before:z-0 before:top-0  flex flex-col md:flex-row mt-4 md:justify-around md:items-end w-full py-4 pb-2 md:py-9 md:pb-12 px-4 space-y-4 md:px-12">
        <div className="flex flex-col z-10">
          <p className="text-base font-medium text-black">
            {modalCar.calc.needFinancing ? "Total Amount:" : "Total Due:"}
            <span className="ml-2 text-xl text-secondary">
              ${Math.round(modalCar.calc.totalDue).toLocaleString()}
            </span>
          </p>
          {modalCar.calc.needFinancing && (
            <p className="text-2xl text-secondary leading-10">
              $ {Math.round(modalCar?.calc?.monthlyPayment).toLocaleString()}
              <sub className="text-sm text-[#39393999] font-medium">
                {" "}
                /Monthly Payment
              </sub>
            </p>
          )}
          <BreakDownPartCalculator data={modalCar} />
        </div>
        <div className=" z-10">
          <div
            className="text-center text-neutral hidden"
            style={{ fontSize: "14px" }}
            ref={refUploadInfo}
          >
            Uploading images... <span className="counter">( X / X)</span>
          </div>
          <Button
            disabled={!isFormValid}
            onClick={handleMakeThisOffer}
            className={cn("w-full h-full disabled:opacity-75 !px-16 !py-4 text-base font-semibold leading-5", !isFormValid ? 'cursor-not-allowed' : 'cursor-pointer')}
          >
            Make this Offer
          </Button>
        </div>
        <div className="relative md:absolute bottom-0 flex justify-center items-center !mt-6 gap-x-2">
          <div
            className="w-3 h-3 rounded-full bg-neutral cursor-pointer"
            onClick={handleBackButton}
          ></div>
          <div className="w-3 h-3 rounded-full bg-secondary"></div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;

/*
{ inventory_code: String, price: Decimal, down_payment: Decimal, trade_in: Decimal, api: Decimal, tax_rate: Decimal, loan_term: Number,
     monthly_payment: Decimal, buy_now: 1/0, financing: 1/0,
trade: 1/0, trade_method: ”vin/plate/manual”, trade_mileage: Number, trade_condition_id: Number, trade_year: Number, trade_make: String, 
trade_model: String, trade_trim: String, trade_vin: String, trade_plate: String, trade_state: String, trade_asking_price: Number, trade_vin_id: Number,
 trade_loan_balance: Number }
*/
function apiRequestMakeThisOfferStart(
  modalCarData,
  token,
  callback = () => {}
) {
  const params = new URLSearchParams();
  params.append("inventory_code", modalCarData.code);
  params.append("price", parseInt(modalCarData.calc.price));
  params.append("down_payment", modalCarData.calc.downPayment);
  params.append("trade_in", modalCarData.calc.tradeInValue);
  modalCarData?.calc?.hasTradeIn &&
    params.append("trade_asking_price", modalCarData.calc.tradeInValue);
  params.append("apr", modalCarData.calc.apr);
  params.append("tax_rate", parseFloat(modalCarData?.calc?.taxRate) * 100 || 0);
  params.append("loan_term", modalCarData.calc.loanTerm);
  params.append("monthly_payment", modalCarData.calc.monthlyPayment);
  // params.append('buy_now', modalCarData.calc.buyNow ? 1 : 0);
  params.append("financing", modalCarData.calc.needFinancing ? 1 : 0);
  params.append("trade", modalCarData.calc.hasTradeIn ? 1 : 0);

  if (modalCarData?.calc?.hasTradeIn) {
    params.append("trade_year", modalCarData?.trade?.year);
    params.append("trade_make", modalCarData?.trade?.make);
    params.append("trade_model", modalCarData?.trade?.model);
    params.append("trade_trim", modalCarData?.trade?.trim);
    params.append("trade_mileage", modalCarData?.trade?.currentMileage);
    params.append("trade_condition_id", modalCarData?.trade?.carCondition);
    params.append(
      "trade_loan_balance",
      modalCarData?.calc?.tradeInLoanBalance || 0
    );
  }
  switch (modalCarData?.trade?.tradeType) {
    case "manual":
      params.append("trade_method", "manual");
      break;
    case "vin":
      params.append("trade_method", "vin");
      params.append("trade_vin", modalCarData.trade.vin);
      params.append("trade_vin_id", modalCarData.trade.vin_id);
      break;
    case "plate":
      params.append("trade_method", "plate");
      params.append("trade_plate", modalCarData.trade.plate);
      params.append("trade_state", modalCarData.trade.state);
      params.append("trade_vin_id", modalCarData.trade.vin_id);
      params.append("trade_vin", modalCarData.trade.vin);

      break;
    default:
      console.warn("No trade-in method selected");
  }

  axios({
    method: "post",
    url: baseURL + "/seller/negotiation/start",
    headers: {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: params,
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      callback(error, null);
    });
}

function apiUploadImage(
  { file, car_id, token, controller },
  { progressCallback = () => {}, doneCallback = () => {} }
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("car_id", car_id);
  axios({
    method: "POST",
    url: baseURL + "/seller/upload",
    headers: {
      Authorization: token,
    },
    data: formData,
    signal: controller ? controller?.signal : null,
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      progressCallback(percentCompleted);
    },
  })
    .then((response) => {
      doneCallback(null, response.data);
    })
    .catch((error) => {
      doneCallback(error);
    });
}
