import { forwardRef, useCallback } from "react";
import { formatMapper } from "./Input.helpers";

import cn from "classnames";
import { ROUNDED_TYPE, roundedMapper, variantMapper } from "./Input.constants";

const Input = forwardRef(
	(
		{
			value,
			format,
			containerClassName,
			onChange,
			placeholder,
			className,
			isInvalid,
			errorText,
			pattern,
			patternError,
			label,
			icon,
			rounded = "default",
			variant = "neutral",
			...args
		},
		ref
	) => {
		const id = Math.random().toString(36).substring(2, 15);

		const handleChange = useCallback(
			(e) => {
				if (format) {
					if (formatMapper[format]) {
						e.target.value = formatMapper[format](e);
					} else {
						throw new Error("Format not found");
					}
				}

				if (!onChange) {
					return;
				}

				onChange(e);
			},
			[format, onChange]
		);
		const handleInputFocus = () => {
			if (!ref) return;
			ref.current.focus();
		};

		return (
			<div className={cn("relative z-0", containerClassName)}>
				{label && (
					<label
						htmlFor={id}
						className={cn(
							"flex mb-1 text-primary text-md font-medium text-nowrap truncate"
						)}
					>
						{label}
					</label>
				)}
				<div className="relative" onClick={handleInputFocus}>
					<input
						id={id}
						{...args}
						ref={ref}
						value={value}
						onChange={handleChange}
						placeholder={placeholder}
						className={cn(
							"block py-3 pr-4 w-full text-md font-normal appearance-none focus:outline-none focus:ring-1 focus:border-primary-light peer",
							icon ? "pl-8" : "pl-4",
							roundedMapper?.[rounded] ?? roundedMapper.default,
							variantMapper?.[variant] ?? variantMapper.primary,
							isInvalid ? "border border-danger mb-4" : "border-neutral-light",
							className
						)}
					/>
					{!icon ? null : (
						<div
							className={cn(
								"absolute w-8 pl-2 h-full left-0 bottom-0 top-0 flex items-center justify-center",
								variantMapper?.[variant] ?? variantMapper.primary,
								ROUNDED_TYPE.includes(rounded) && rounded === "full"
									? "rounded-l-full"
									: "rounded-l-[10px]",
								ROUNDED_TYPE.includes(rounded) && rounded === "small"
									? "rounded-l-[10px]"
									: "",
								isInvalid
									? "border-t border-b border-l border-danger"
									: "border-neutral-light"
							)}
						>
							{icon}
						</div>
					)}
					{isInvalid && (
						<div className="absolute top-[95%] pt-1 text-danger">
							<span className="text-sm">{errorText}</span>
						</div>
					)}
				</div>
			</div>
		);
	}
);

export default Input;
