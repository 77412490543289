import React, { useState, useContext } from "react";
import { GoAlert } from "@react-icons/all-files/go/GoAlert";
import Button from "components/shared/Button";
import axios from "axios";
import { baseURL } from "config.js";
import { UserDataContext } from "App";
import Modal from "components/shared/Modal";
import { TrashIcon } from "@heroicons/react/24/outline";

const ModalConfirm = ({ configModal, offer, onConfirm = () => {} }) => {
  const userData = useContext(UserDataContext)[0];
  const [modalStatus, setModalStatus] = useState({
    show: false,
    ...configModal,
  });

  function handleCloseModal() {
    setModalStatus({
      ...modalStatus,
      show: false,
    });
  }

  function handleOpenModal() {
    setModalStatus({
      ...modalStatus,
      show: true,
    });
  }

  function requestRemoveTradeIn() {
    axios({
      method: "DELETE",
      url: `${baseURL}/seller/negotiation/${offer.code}/trade`,
      headers: {
        Authorization: userData.token,
      },
    }).then((res) => {
      onConfirm();
    });
  }

  return (
    <>
      <Button variant="none" className="w-auto shrink-0" onClick={handleOpenModal}>
        <TrashIcon className="w-5 h-5" />
      </Button>
      <Modal show={modalStatus.show} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="flex justify-center mt-3">
            <GoAlert size={60} color="#B72949" />
          </div>
          <div className="text-center my-3">
            Are you sure you want to remove this trade in?
          </div>
          <div className="flex gap-4">
            <Button
              onClick={requestRemoveTradeIn}
              className="w-full py-2 mx-2"
              variant="secondary"
              label="Remove"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirm;
