import React from "react";
import { apiFetchOffers } from "components/home/request/apiFetchOffers";
import { useEffect, useContext, useState } from "react";
import { UserDataContext } from "App";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "components/shared/Button";
import Loading from "components/Loading";

const IncomingOffersCard = () => {
  const [userData] = useContext(UserDataContext);
  const [isBidClose, setIsBidClose] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bidsSpliced, setBidsSpliced] = useState([]);
  const [visibleList] = useState(true);
  const [toggleNegotationChat, setToggleNegotationChat] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    apiFetchOffers(userData.token, responseFetchOffers);
  }, [userData.token]);

  function responseFetchOffers(error, data) {
    if (error) {
      throw error;
    }
    let arrayBids = data;

    // find if winner= 1 in arrayBids if it is true then set isBidClose to true
    let isBidClose = arrayBids.findIndex((bid) => {
      return bid.winner === 1;
    });
    if (isBidClose !== -1) {
      setIsBidClose(true);
    }

    setIsLoading(false);

    // get only the last 4 bids (the highest) for each dealer_name
    let bidsSpliced = arrayBids.reduce((acc, curr) => {
      let dealer_name = curr.dealer_name;
      let index = acc.findIndex((obj) => obj.dealer_name === dealer_name);
      if (index === -1) {
        acc.push({
          dealer_name: dealer_name,
          bids: [curr],
        });
      } else {
        acc[index].bids.push(curr);
      }
      return acc;
    }, []);

    // sort the bids by highest price
    bidsSpliced.forEach((dealer) => {
      dealer.bids.sort((a, b) => {
        return b.price - a.price;
      });
    });
    // get only one bid from 4 bids (the highest) for each dealer_name
    bidsSpliced.forEach((dealer) => {
      dealer.bids = dealer.bids.slice(0, 1);
    });

    // dublicate bidsSpliced 6 times
    // bidsSpliced = bidsSpliced.concat(bidsSpliced, bidsSpliced, bidsSpliced, bidsSpliced, bidsSpliced);

    setBidsSpliced(bidsSpliced.slice(0, 6));
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="my-3 rounded shadow bg-white">
      <div className="flex justify-between px-4 py-6 xl:px-4 xl:py-6 gap-3 items-center">
        <p className="font-bold mb-0 text-base text-primary">Incoming Offers</p>
        <div className="flex">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            className="text-primary disabled:cursor-not-allowed xl:hidden"
            onClick={() => setToggleNegotationChat(!toggleNegotationChat)}
            type="button"
          >
            {toggleNegotationChat ? (
              <ChevronUpIcon className="w-6 h-6 text-primary" />
            ) : (
              <ChevronDownIcon className="w-6 h-6 text-primary" />
            )}
          </button>
        </div>
      </div>
      <div
        id="dropdown"
        className={cn("z-10 w-full xl:pb-6", {
          hidden: toggleNegotationChat === false,
        })}
      >
        {bidsSpliced.length > 0 ? (
          <div className="flex-col justify-between bg-white [&>*:first-child]:bg-[#08315159]">
            {visibleList &&
              !isBidClose &&
              bidsSpliced.slice(0, 6).map((bid, index) => {
                return (
                  <div
                    key={`${bid?.dealer_name}-${index}`}
                    onClick={() => navigate("/messages", { state: bid })}
                    className="flex-col cursor-pointer justify-between p-1 xl:p-2 mb-3"
                  >
                    <div
                      className={cn("my-1 xl:my-2 justify-between", {
                        flex: index === 0,
                        hidden: index !== 0,
                      })}
                    >
                      <span className="font-bold text-primary uppercase text-xs">
                        Highest offer
                      </span>
                      <img
                        alt="highest_price_icon"
                        src="/images/icons/award.svg"
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <span
                          className={cn("text-primary text-base", {
                            "font-bold": index === 0,
                            "font-normal": index !== 0,
                          })}
                        >
                          {bid.dealer_name}
                        </span>
                      </div>
                      <span
                        className={cn("text-primary text-base", {
                          "font-bold": index === 0,
                          "font-normal": index !== 0,
                        })}
                      >
                        ${bid.bids[0].bid_amount.toLocaleString()}
                      </span>
                    </div>
                  </div>
                );
              })}
            <div className="mx-auto flex justify-center text-center mb-3 w-full xl:w-[90%]">
              <Button
                variant="primary"
                label="See All"
                className="w-full"
                onClick={() => navigate("/messages")}
              />
            </div>
          </div>
        ) : (
          <div className="h-12 xl:h-64 flex justify-center items-center text-neutral">
            No Incoming Offers Found!
          </div>
        )}
      </div>
    </div>
  );
};

export default IncomingOffersCard;
