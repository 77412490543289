import { UserDataContext } from "App";

const { default: axios } = require("axios");
const { baseURL } = require("config");
const { useEffect, useState, useContext, useCallback } = require("react");

const useLookingForCar = () => {
  const [userData] = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    isLooking: false,
    description: "",
  });

  const fetchLookingForCar = useCallback(() => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: baseURL + "/seller/looking",
      headers: {
        Authorization: userData.token,
      },
    })
      .then((response) => {
        setFormData({
          isLooking: !!response.data.looking_for_car,
          description: response.data.car_description || "",
        });
      })
      .catch((error) => {
        console.log("errror:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userData.token]);

  useEffect(() => {
    fetchLookingForCar();
  }, []);

  return {
    isLoading,
    lookingForCar: formData,
    setLookingForCar: setFormData,
    refetch: fetchLookingForCar,
  };
};

export { useLookingForCar };
