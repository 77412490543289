import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Loading from "../Loading";
import { UserDataContext } from "../../App";
import ReciverMsg from "./messageTypes/ReciverMsg";
import SenderMsg from "./messageTypes/SenderMsg";
import ReciverMsgImage from "./messageTypes/ReciverMsgImage";
import SenderMsgImage from "./messageTypes/SenderMsgImage";
import UploadMsgFile from "./messageFunctions/UploadMsgFile";
import axios from "axios";
import ModalImage from "components/componenetInput/ModalImage";
import { baseURL } from "config.js";
import { useRef } from "react";
import { MdDone } from "@react-icons/all-files/md/MdDone";
import { ChevronRightIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import useModal from "hooks/use-modal.hook";
import ModalCarOverview from "components/ModalCarOverview";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { find, findIndex } from "lodash";
import { NegotiationsContext } from "components/negotiations/contexts/NegotiationsContext";
import { toast } from "react-toastify";

const PinnedOfferingTradeIn = ({ value, onClick }) => {
  return (
    <span className="ms-auto">
      Dealer is offering ${value?.toLocaleString()} for your trade-in:
      <button onClick={onClick} className="pinned-button">
        <MdDone />
        Accept offer
      </button>
    </span>
  );
};

const ChatMessageRawIN = ({ setActiveTab }) => {
  const [pinned, setPinned] = useState(null);
  const userData = useContext(UserDataContext)[0];
  const {
    chatRooms: { rooms, selected, loading },
    setChatRooms,
  } = useContext(NegotiationsContext);
  const chatMessages = useMemo(
    () => find(rooms, { roomData: selected })?.chatMessages ?? [],
    [rooms, selected]
  );
  const messageInputRef = useRef(null);
  const bodyMessagesRef = useRef(null);
  const progressBarRef = useRef(null);
  const vehicleViewModal = useModal();
  const [modalImageShow, setModalImageShow] = useState({
    show: false,
    image: "",
  }); // onclick image fullscreen

  const sendMessage = useCallback(() => {
    if (!messageInputRef.current.value || messageInputRef.current.value === "")
      return;
    // set params
    let params = new URLSearchParams();
    params.append("room_id", selected?.room_id);
    params.append("message", messageInputRef.current.value);
    // send request
    axios({
      method: "POST",
      url: baseURL + "/seller/message",
      data: params,
      headers: {
        Authorization: userData.token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        const newMessage = {
          message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
          generated_local: true,
          message: messageInputRef.current.value,
          message_date: new Date().toISOString(),
          file_name: null,
          you: 1,
        };

        setChatRooms((prevChatRooms) => {
          const { rooms } = prevChatRooms;
          const newRooms = [...rooms];
          const roomIndex = findIndex(
            newRooms,
            (room) => room.roomData.room_id === parseInt(selected?.room_id)
          );

          if (roomIndex === -1) {
            return prevChatRooms;
          }
          const chatMessages = [...newRooms[roomIndex].chatMessages];
          chatMessages.push(newMessage);
          newRooms[roomIndex].chatMessages = chatMessages;
          return {
            ...prevChatRooms,
            rooms: newRooms,
          };
        });

        if (response?.data?.success !== 1) {
          toast.error("Error sending your last message!");
          return;
        }
      })
      .catch((error) => {
        console.log("response error:", error);
      })
      .finally(() => {
        messageInputRef.current.value = "";
      });
  }, [selected?.room_id, setChatRooms, userData.token]);

  const onEnterPress = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    },
    [sendMessage]
  );

  const acceptTradeInOffering = useCallback(() => {
    axios({
      method: "POST",
      url:
        baseURL + `/seller/negotiation/${selected?.code}/trade/counter/accept`,
      headers: {
        Authorization: userData.token,
      },
    })
      .then((response) => {
        console.log("response:", response);
        // reFetchDetailsAndMessages();
      })
      .catch((error) => {
        console.log("response error:", error);
      });
  }, [selected?.code, userData.token]);

  useEffect(() => {
    let dealer_trade_in = selected?.dealer_trade_in;
    let dealer_trade_in_accepted = selected?.dealer_trade_in_accepted;
    if (dealer_trade_in && !dealer_trade_in_accepted) {
      dealer_trade_in ??= 10_000;
      let pinned = (
        <PinnedOfferingTradeIn
          value={dealer_trade_in}
          onClick={acceptTradeInOffering}
        />
      );
      setPinned(pinned);
    } else {
      setPinned(null);
    }
  }, [
    acceptTradeInOffering,
    selected?.dealer_trade_in,
    selected?.dealer_trade_in_accepted,
  ]);

  useEffect(() => {
    try {
      bodyMessagesRef.current.scrollBy({ top: Number.MAX_SAFE_INTEGER });
    } catch (erro) {}
  }, [chatMessages]);

  useEffect(() => {
    messageInputRef?.current?.addEventListener("keydown", onEnterPress);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      messageInputRef?.current?.removeEventListener("keydown", onEnterPress);
    };
  }, [chatMessages, onEnterPress]);

  return (
    <>
      <div className="chat-header flex flex-col justify-end px-3 pt-4 pb-0 xl:max-h-32 xl:h-full xl:pb-3 xl:border-b border-[#EBEBEB] bg-[#0831510D] xl:bg-transparent">
        <div className="flex items-center border-b border-[#EBEBEB] xl:border-none w-full pb-3">
          <div className="ms-3 flex justify-between items-center w-full">
            <div>
              <div className="name-text-header text-2xl text-[#000]">
                {selected?.dealer_name ?? ""}
              </div>
              <div className="text-[#838383CC] text-base">
                {selected?.location_short ?? ""}
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-white xl:bg-neutral-light py-2 px-4 flex justify-between pointer-cursor items-center rounded-lg text-primary"
          role="button"
          onClick={() => vehicleViewModal.openModal()}
        >
          <p className="font-bold text-sm text-primary mb-0">
            {selected?.title ?? "-"}
          </p>
          <ArrowTopRightOnSquareIcon className="w-5 h-5" />
        </div>
        <div
          role="button"
          className="border-t border-[#EBEBEB] flex justify-between pointer-cursor items-center xl:hidden py-3"
          onClick={() => setActiveTab(2)}
        >
          <p className="font-normal text-sm text-primary mb-0">
            Add to trade-in
          </p>
          <ChevronRightIcon className="w-5 h-5" />
        </div>
      </div>
      {false && <div className="chat-header-pinned">{pinned}</div>}

      {loading ? (
        <div className="flex items-center h-full justify-center">
          <Loading />
        </div>
      ) : (
        <>
          <div
            ref={bodyMessagesRef}
            className="overflow-y-auto h-full custom-scrollbar space-y-2 pt-1 pb-3 px-2"
          >
            {chatMessages.map((item) => {
              if (item.file_name !== null && item.you === 0)
                return (
                  <ReciverMsgImage
                    modal={[modalImageShow, setModalImageShow]}
                    key={item.message_id}
                    item={item}
                  />
                );
              else if (item.file_name !== null && item.you === 1)
                return (
                  <SenderMsgImage
                    modal={[modalImageShow, setModalImageShow]}
                    key={item.message_id}
                    item={item}
                  />
                );

              if (item.you === 0)
                return <ReciverMsg key={item.message_id} item={item} />;
              else return <SenderMsg key={item.message_id} item={item} />;
            })}
          </div>
          <div className="emoji-list-r">
            <ModalImage
              setModalImageShow={setModalImageShow}
              image={modalImageShow.image}
              show={modalImageShow.show}
              onHide={() => {
                setModalImageShow((i) => {
                  return { ...i, show: false };
                });
              }}
            />
            <div
              className="progressUploadFile mb-1"
              style={{ display: "none" }}
            >
              {/* TODO: Replace with custom made progressbar. */}
              {/* <ProgressBar ref={progressBarRef} striped variant="warning" /> */}
            </div>
            <div className="px-3 mb-3">
              <div className="flex items-center chat-input-container rounded-[50px] bg-[#F9F9F9] p-2">
                <UploadMsgFile
                  isMessagesPage={false}
                  chatMessages={chatMessages}
                  roomData={{ room_id: selected?.room_id }}
                  progressBarRef={progressBarRef}
                />

                <textarea
                  ref={messageInputRef}
                  maxLength={200}
                  className="focus:outline-none h-10 py-2.5 max-w-full w-full bg-transparent resize-none ml-2 text-sm"
                  placeholder="Write your message..."
                ></textarea>

                <button
                  onClick={sendMessage}
                  className="bg-secondary rounded-full p-2"
                >
                  <PaperAirplaneIcon className="w-5 h-5 text-white -rotate-45" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {vehicleViewModal.isOpen && (
        <ModalCarOverview
          isOpen={vehicleViewModal.isOpen}
          onHide={() => vehicleViewModal.closeModal()}
          data={selected}
        />
      )}
    </>
  );
};

export const ChatMessageIN = ChatMessageRawIN;
