import { forwardRef } from 'react'
import cn from 'classnames';

export default forwardRef((props, ref) => {
    let { label, invalid, placeholder, containerClassName, selectClassName = '', options, ...otherProps } = props;
    options ??= [];
    

    return <>
        <div className={containerClassName}>
            {label && <div className='mb-1 text-base text-primary font-medium'>{label}</div>}
            <select {...otherProps} className={cn('w-full py-3 text-base px-2.5 text-md font-normal border-r-8 border-transparent', selectClassName)}>
                {placeholder && <option disabled value=''>{placeholder}</option>}
                {options.map((option, index) => {
                    return <option key={index} className="text-base" value={option.value}>{option.label}</option>
                })}
            </select>
            {invalid && <div>
                <div className='italic-info-error ps-1'>
                    {invalid}
                </div>
            </div>}
        </div>
    </>
});