import SkeletonCarPage from "./SkeletonCarPage";
import SkeletonMainCarousel from "./SkeletonMainCarousel";
import SkeletonCarCard from "./SkeletonCarCard";
import SkeletonBrowseCars from "./SkeletonBrowseCars";

export const skeletonMapper = {
    'default': <></>,
    'mainCarousel': <SkeletonMainCarousel />,
    'carPage': <SkeletonCarPage />,
    'carCard': <SkeletonCarCard />,
    'browseCars': <SkeletonBrowseCars />
}