import { baseURL } from "config";
import axios from "axios";


export function apiFetchCheckPlate(plate,state,cb){ 
    const params = new URLSearchParams();
    params.append("plate", plate);
    params.append("state", state);
    axios({
        method: "POST",
        url: baseURL + "/seller/register/plate-to-vin",
        data: params,
        headers:{
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }).then((response) => {
        cb(null,response.data);
    }).catch((error) => {
        cb(error,null);
    });
}