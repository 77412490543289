import { useCallback, useEffect, useRef } from "react";

const useKeyAndOutsideClickEvent = (
  ref,
  onKeyAndOutsideClickEvent = () => {},
  options = { key: null }
) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (ref.current && !ref.current.contains(e.target)) {
        onKeyAndOutsideClickEvent();
      }
    },
    [onKeyAndOutsideClickEvent, ref]
  );

  const handleKeypress = useCallback(
    (event) => {
      let keyCode = 27;
      if (options?.key) {
        keyCode = options.key;
      }

      if (event.keyCode === keyCode) {
        onKeyAndOutsideClickEvent();
      }
    },
    [onKeyAndOutsideClickEvent, options?.key]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("keydown", handleKeypress);
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick, handleKeypress]);
};

export default useKeyAndOutsideClickEvent;
