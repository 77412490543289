import React from "react";
import { useTransition } from "react";
import { useEffect, useState } from "react";
import cn from "classnames";

const SenderMsg = ({ item, showSenderName }) => {
  const [, startTransition] = useTransition();
  const [fadeIn, setFadeIn] = useState({
    transition: `all 0.1s ease-in-out`,
    transform: `translateX(-10px)`,
    opacity: 0,
  });

  useEffect(() => {
    startTransition(() => {
      setFadeIn((pState) => {
        return { ...pState, opacity: 1, transform: `translateX(0px)` };
      });
    });
  }, []);
  var msg = "";

  if (item.message?.includes("http")) {
    msg = item.message.replace(
      /(https?:\/\/[^\s]+)/g,
      '<a target="_blank" href="$1">$1</a>'
    );
  } else {
    msg = item.message;
  }
  const formatTimeAgo = (date) => {
    const now = new Date();
    const messageDate = new Date(date);
    const diff = now - messageDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (seconds < 29) {
      return "a few seconds ago";
    } else if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    }
    return messageDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <div style={fadeIn} className="flex flex-col items-end relative">
      {!showSenderName ? null : (
        <span className={cn("font-bold text-sm mb-2")}>You</span>
      )}
      <span
        dangerouslySetInnerHTML={{ __html: msg }}
        time={formatTimeAgo(item.message_date)}
        className={cn(
          "sender-msg-text text-right text-black text-sm rounded-xl rounded-br-none p-3 pb-10 min-w-[160px] max-w-[50%] break-words relative bg-[#0831510D]"
        )}
      ></span>
    </div>
  );
};

export default SenderMsg;
