import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SocketDataContext, UserDataContext } from "../../App";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { socketURL } from "../../config";
import io from "socket.io-client";
import LogoutConfirm from "components/LogoutConfirm";
import SearchInventory from "./SearchInventory";
import {
  Bars3CenterLeftIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  XMarkIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import UserDropdown from "./UserDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import Button from "components/shared/Button";
import { NAV_LINKS } from "./Layout.const";
import cn from "classnames";

const NavBar = () => {
  const [userData, setUserData] = useContext(UserDataContext);
  const { setSocketData } = useContext(SocketDataContext);
  let newMessage = userData.newMessage || 0;
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  let audio = useMemo(() => new Audio("./audio/sound-tiny.mp3"), []);
  audio.volume = 0.1;
  const navigate = useNavigate();
  const date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    if (newMessage) {
      if (newMessage > 0) {
        document.title = "AutosToday (" + newMessage + " new messages)";
      }
    }
    return () => {
      document.title = "AutosToday";
    };
  }, [newMessage]);
  const handleNotificationButton = (roomId, code) => {
    const destination =
      code && code !== "null"
        ? `/negotiations?code=${code}`
        : `/messages?roomId=${roomId}`;

    navigate(destination);
    toast.dismiss();
  };
  const onNewMessage = useCallback(
    (msg) => {
      if (
        window.location.pathname.includes("/messages") ||
        window.location.pathname.includes("/negotiations")
      ) {
        return;
      }

      try {
        audio.play();
      } catch (error) {
        console.log(error);
      }
      let spliter = msg.split("|");

      if (spliter[0])
        toast(
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-x-0 items-center justify-around">
            <div className=" pr-2 sm:pr-12">
              <ChatBubbleOvalLeftEllipsisIcon className="w-8 h-7 sm:w-6 sm:h-6 text-white" />
            </div>
            <span className="text-base flex flex-wrap sm:max-w-[290px] sm:text-lg text-white px-2 sm:px-12 sm:border-l break-words border-white">
              <b className="mr-1">{`${spliter[0]} `}</b> has messaged you!
            </span>
            <Button
              className="bg-white text-success px-10 py-2 sm:px-12 hover:text-white hover:bg-transparent border hover:border-white font-normal text-sm"
              variant="none"
              onClick={() => handleNotificationButton(spliter[2], spliter[4])}
              label="See Message"
            />
          </div>,
          {
            position: "top-center",
            closeButton: true,
            className:
              "!w-full !bg-[#38BE6FE5] !shadow md:!w-[700px] sm:!rounded-sm !border-b-[5px] !border-success !mb-1",
            autoClose: false,
            progress: false,
            bodyClassName: "px-2 sm:!pr-12 !pl-[62px]",
            toastId: parseInt(spliter[2]),
            closeOnClick: false,
            draggable: false,
          }
        );

      setUserData((p) => {
        let statusNotification = p?.statusNotification ?? [];
        if (spliter.length > 2)
          statusNotification.push({
            type: "message",
            name: spliter[0],
            message: spliter[1],
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            dateTime: new Date().toLocaleString(),
          });

        return {
          ...p,
          newMessage: p.newMessage ? p.newMessage + 1 : 1,
          newMessageFrom: p.newMessageFrom
            ? [...p.newMessageFrom, spliter[0]]
            : [spliter[0]],
          statusNotification: statusNotification,
        };
      });
    },
    [audio, setUserData]
  );

  const onNewBid = useCallback(
    (msg) => {
      if (
        window.location.pathname.includes("/messages") ||
        window.location.pathname.includes("/negotiations")
      ) {
        return;
      }

      try {
        audio.play();
      } catch (error) {
        console.log(error);
      }
      let spliter = msg.split("|"); // # id|bid|name
      if (spliter[1])
        toast(
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-x-0 items-center justify-around">
            <div className=" pr-2 sm:pr-12">
              <BanknotesIcon className="w-8 h-7 sm:w-6 sm:h-6 text-white" />
            </div>
            <span className="text-base flex flex-wrap sm:max-w-[290px] sm:text-lg text-white px-2 sm:px-12 sm:border-l break-words border-white">
              <b className="mr-1">{`${spliter[2]} `}</b> has bided!
            </span>
            <Button
              className="bg-white text-success px-10 py-2 sm:px-12 hover:text-white hover:bg-transparent border hover:border-white font-normal text-sm"
              variant="none"
              onClick={() => handleNotificationButton(spliter[3])}
              label="See offer"
            />
          </div>,
          {
            position: "top-center",
            closeButton: true,
            className:
              "!w-full !bg-[#38BE6FE5] md:!w-[700px]!shadow sm:!rounded-sm !border-b-[5px] !border-success !mb-1",
            autoClose: false,
            progress: false,
            bodyClassName: "px-2 sm:!pr-12 !pl-[62px]",
            // toastId: parseInt(spliter[2]),
            closeOnClick: false,
            draggable: false,
          }
        );

      setUserData((p) => {
        let statusNotification = p?.statusNotification ?? [];
        if (spliter.length > 2) {
          statusNotification.push({
            type: "bid",
            name: spliter[2],
            value: spliter[1],
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            dateTime: new Date().toLocaleString(),
          });
        }

        return {
          ...p,
          newBid: p.newBid ? p.newBid + 1 : 1,
          statusNotification: statusNotification,
        };
      });
    },
    [audio, setUserData]
  );

  useEffect(() => {
    // do not init socket if user is not logged in
    if (!userData.isOnline) {
      return;
    }

    // init socket
    const socket = io.connect(socketURL, {
      reconnectionAttempts: 10,
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      let UID = userData.uid;
      if (UID) {
        socket.emit("set-token", {
          uid: UID,
          socket: socket.id,
          type: "seller",
        });
        socket.emit("subscribe", { room: "sellers" });
      }

      setSocketData((pState) => {
        return { ...pState, socket: socket };
      });
    });

    if (socket.listeners("new-message").length > 0) {
      socket.off("new-message");
    }

    if (socket.listeners("new-bid").length > 0) {
      socket.off("new-bid");
    }

    socket.on("new-message", onNewMessage);
    socket.on("new-bid", onNewBid);

    return () => {
      if (socket.readyState === 1) {
        socket.disconnect();
        socket.destroy();
        socket.close();
      }
    };
  }, [onNewBid, onNewMessage, setSocketData, userData.isOnline, userData.uid]);

  return (
    <div className="flex flex-col">
      <div className="w-full px-4 flex sm:flex-row sm:items-center justify-between container m-auto py-4 lg:py-0 lg:pt-4 gap-4">
        <Link to="/" className="shrink-0 flex items-center">
          <img
            src="/images/logo-hq.png"
            className="w-[120px] lg:w-[150px] xl:w-[220px]"
            alt="logo"
          />
        </Link>
        <div className="hidden shrink-0 lg:flex lg:flex-row justify-center items-start text-center space-x-2 md:space-x-4 xl:space-x-8">
          {NAV_LINKS.filter((link) =>
            userData?.car?.id ? true : !["/messages"].includes(link.to)
          ).map(({ to, icon, text }, index) => (
            <NavLink
              key={to}
              to={to}
              className={({ isActive }) =>
                `pt-4 pb-3 flex flex-col items-center ${
                  isActive
                    ? "font-bold text-secondary border-b-[3px] border-secondary"
                    : "text-black font-medium"
                }`
              }
            >
              {/* NOTE: might need to use later for mobile */}
              {/* <img src={icon.src} alt={icon.alt} className="hidden w-6 h-full" /> */}
              <span
                className={cn("text-base relative flex", {
                  "text-primary-light": [
                    "/car-search",
                    "/negotiations",
                  ].includes(to),
                })}
              >
                {to === "/car-search" && (
                  <div className="absolute w-[1px] h-[18px] top-1 -left-2 xl:-left-4 cursor-default bg-black"></div>
                )}
                {text}
              </span>
            </NavLink>
          ))}
        </div>
        <div
          className={`relative z-50 ${showMenu ? "flex" : "hidden"} lg:hidden`}
        >
          <nav className="fixed top-0 right-0 bottom-0 flex flex-col w-4/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-8">
              <Button
                variant="none"
                className="!p-0 mr-12"
                onClick={() => setShowMenu(false)}
                label={<XMarkIcon className="w-5 h-5 text-secondary" />}
              />
            </div>
            <div className="flex flex-col justify-between h-full">
              <ul className="space-y-8 !px-12">
                {NAV_LINKS.filter((link) =>
                  userData?.car?.id ? true : !["/messages"].includes(link.to)
                ).map(({ to, icon, text }) => (
                  <NavLink
                    key={to}
                    to={to}
                    className={({ isActive }) =>
                      `flex flex-col items-center text-lg ${
                        isActive
                          ? "font-bold text-secondary border-b-[3px] border-secondary"
                          : "text-[#0E1D2E] font-medium"
                      }`
                    }
                    onClick={() => setShowMenu(false)}
                  >
                    {/* NOTE: might need to use later for mobile */}
                    {/* <img src={icon.src} alt={icon.alt} className="hidden w-6 h-full" /> */}
                    <span className="text-lg">{text}</span>
                  </NavLink>
                ))}
              </ul>
              <div className="flex flex-col gap-5">
                <Button
                  label="Log Out"
                  className="px-4 py-2 !bg-danger w-full text-center text-white text-lg"
                  onClick={() => {
                    setShowLogoutConfirm(true);
                  }}
                />
                <span className="text-[#006B9F] text-[9px] font-normal leading-4 text-center">
                  © {year} AutosToday, Inc. All rights reserved.
                </span>
              </div>
            </div>
          </nav>
        </div>
        <div className="flex shrink-0 gap-2 h-full lg:gap-x-4 items-center">
          <div className="hidden sm:flex lg:mr-4">
            <SearchInventory />
          </div>
          <Link
            variant="none"
            className="relative rounded-full text-primary border-primary border-2 p-2 ease-in duration-300 hover:bg-primary hover:text-white"
            to="/messages"
          >
            <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4 lg:w-5 lg:h-5" />
            {newMessage > 0 && (
              <span className="absolute bottom-2 left-[55%] w-2 h-2 bg-secondary rounded-full"></span>
            )}
          </Link>
          <NotificationsDropdown />
          <UserDropdown onLogout={() => setShowLogoutConfirm(true)} />
          <div className="lg:hidden">
            <Button
              variant="none"
              className="flex items-center"
              onClick={() => setShowMenu(true)}
            >
              <Bars3CenterLeftIcon className="w-5 h-5 mr-2" />
              <span className="text-xs">Menu</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="sm:hidden px-4 mb-2">
        <SearchInventory />
      </div>
      {showLogoutConfirm && (
        <LogoutConfirm
          setShowLogoutConfirm={setShowLogoutConfirm}
          showLogoutConfirm={showLogoutConfirm}
        />
      )}
    </div>
  );
};
export default NavBar;
