import { UserDataContext } from "App";
import { useCallback, useContext, useEffect, useState } from "react";
import { apiFetchCarDetails } from "./request/apiFetchCarDetails";
import { fetchProgressVehicle } from "./request/fetchProgressVehicle";
import { useSearchParams } from "react-router-dom";
import TimerCountDown from "./TimerCountDown";
import ProgressBar from "./section/ProgressBar";
import OutgoingOffersCard from "./section/OutgoingOffersCard";
import IncomingOffersCard from "./section/IncomingOffersCard";
import MainOverview from "components/MainOverview";
import BrowseCars from "components/BrowseCars";
import Loading from "components/Loading";
import { useFetchVehicleImages } from "components/MainOverview/MainOverview.hooks";
import LookingForCar from "./section/LookingForCar";
import useModal from "hooks/use-modal.hook";
import ModalReferral from "components/ModalReferral";
import NoCarSection from "./section/NoCarSection";
import FormContext from "components/NoCar/contexts/FormContext";

const Home = () => {
  const [userData] = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const referralModal = useModal();
  const {
    isLoading: imagesLoading,
    coverPhoto,
    listImages,
    fetchImages,
  } = useFetchVehicleImages({ userData });
  const [carDetails, setCarDetails] = useState({
    vin: null,
    title: "",
    mileage: -1,
    condition_id: -1,
    notes: null,
    vehicle_type: null,
    engine: null,
    msrp: null,
    zip_code: "",
    start_date: "",
    end_date: "",
    location: "",
    year: "",
    progress: {
      details: [],
      overall: null,
      success: null,
    },
  });
  const [searchParams] = useSearchParams();

  const handleDetailsRefetch = useCallback(async () => {
    try {
      if (!userData?.car?.id) return;
      const [carDetails, progressVehicle] = await Promise.all([
        apiFetchCarDetails(userData.token, userData.car.id),
        fetchProgressVehicle(userData.token),
      ]);

      setCarDetails((prevState) => ({
        ...prevState,
        ...carDetails?.data,
        ...progressVehicle?.data.auction,
        progress: {
          details: progressVehicle?.data?.details,
          success: progressVehicle?.data?.success,
          overall: progressVehicle?.data?.overall,
        },
      }));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [userData?.car?.id, userData.token]);

  useEffect(() => {
    handleDetailsRefetch();
  }, [handleDetailsRefetch]);

  useEffect(() => {
    const hasReferralSource = !!carDetails?.referral_source ?? true;
    if (searchParams.get("case")) return;
    if (
      !isLoading &&
      (!("skippedReferral" in userData) || !userData?.skippedReferral) &&
      !hasReferralSource &&
      !referralModal.isOpen
    ) {
      referralModal.openModal();
    }
  }, [
    carDetails?.referral_source,
    isLoading,
    referralModal,
    userData,
    userData?.skippedReferral,
  ]);

  if (!userData?.car?.id) {
    return (
      <div className="container py-4 xl:py-8">
        <FormContext>
          <NoCarSection />
        </FormContext>
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="container py-4 xl:py-8">
        <ProgressBar
          carProgress={carDetails.progress}
          carImages={{
            isLoading: imagesLoading,
            coverPhoto,
            listImages,
          }}
          onRefetchDetails={handleDetailsRefetch}
          onRefetchImages={fetchImages}
        />
        <div className="flex flex-col-reverse xl:flex-row xl:gap-4">
          <div className="mb-3 xl:mb-0 w-full xl:w-2/3 xl:pt-6 space-y-4">
            <MainOverview
              carDetails={carDetails}
              carImages={{
                isLoading: imagesLoading,
                coverPhoto,
                listImages,
              }}
              onRefetchDetails={handleDetailsRefetch}
              onRefetchImages={fetchImages}
            />
            <LookingForCar />
          </div>
          <div className="w-full xl:w-1/3 xl:pt-6 pb-0">
            <div className="flex xl:hidden justify-between items-start mb-3">
              <h1 className="text-lg xl:text-3xl font-bold text-primary">
                {carDetails?.title}
              </h1>
            </div>
            <TimerCountDown auction={carDetails} />
            <IncomingOffersCard />
            <OutgoingOffersCard />
          </div>
        </div>
      </div>
      <BrowseCars />

      {!referralModal.isOpen ? null : (
        <ModalReferral
          skipCheck
          isOpen={referralModal.isOpen}
          onComplete={handleDetailsRefetch}
          onClose={referralModal.closeModal}
        />
      )}
    </div>
  );
};

export default Home;
