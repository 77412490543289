import axios from "axios";
import { baseURL } from "config";
import { useCallback, useEffect, useState } from "react";

const useFetchVehicleImages = ({ userData }) => {
  const [isLoading, setIsLoading] = useState(true);
  var [listImages, setListImages] = useState([]);
  const [isError, setIsError] = useState("");

  const fetchImages = useCallback(() => {
    if (!userData?.car) return; // stop no car is set
    if (isError !== "") setIsError("");
    setIsLoading(true);
    axios({
      method: "GET",
      url: baseURL + "/car/photos/" + userData.car.id,
      headers: {
        Authorization: userData.token,
      },
    })
      .then((response) => {
        setListImages(response.data);
      })
      .catch((error) => {
        console.log(error);
        setIsError("Retrying fetching images...");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isError, userData.car, userData.token]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  let cover_photo = listImages.find((x) => x.cover_photo === 1);
  if (cover_photo) {
    listImages.splice(listImages.indexOf(cover_photo), 1);
    listImages.unshift(cover_photo);
  }

  return {
    isLoading,
    isError,
    listImages,
    coverPhoto: cover_photo,
    fetchImages,
  };
};

export { useFetchVehicleImages };
