import axios from 'axios';
import { baseURL } from 'config.js';


export async function fetchAllUsers(token,onLoad){
    axios({
        method: "GET",
        url: baseURL + "/seller/negotiations",
        headers: {
            'Authorization': token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => {
        if(onLoad){
            onLoad(null,response.data);
        }
    }).catch((error) => {
        if(onLoad){
            onLoad(error,null);
        }
    });
}