/* eslint-disable */
var idb = null;

export default function initIDB() {
    const indexedDB =
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB;

    if (!indexedDB) {
        console.log("IndexedDB could not be found in this browser.");
    }
    
    // const request = indexedDB.open("FileManagement",1);
    window.idb = indexedDB.open("fileManagement_reloaded",2);
   
   window.idb.onerror = function (event) {
        console.error("An error occurred with IndexedDB");
        console.error(event);
    };
   window.idb.onupgradeneeded = function ({ oldVersion, newVersion}) {
        //console.log("Database needs to be upgraded",{ oldVersion, newVersion});
        //clear old version
        if (oldVersion  < newVersion) {
            if (window.idb.result.objectStoreNames.contains("files")) {
               window.idb.result.deleteObjectStore("files");
            }
        }

        idb =window.idb.result;
        const store = idb.createObjectStore("files", { keyPath: "id", autoIncrement: true });
        store.createIndex("fileId", "fileId", { unique: false });
        store.createIndex("statusUpload", "statusUpload", { unique: false });
    };
   window.idb.onsuccess = async function () {
        //console.log("Database opened successfully");
        
        idb =window.idb.result;
    }
}


export function storeToIDB(objData, tries = 10) {
    if (typeof idb === "undefined" || idb === null) {
        if (tries === 0) {
            console.log("idb not ready");
            return;
        }
        console.log("idb not ready retrying tries left:", tries);
        setTimeout(() => storeToIDB(objData, tries - 1), 1000);
        return;
    }
    const transaction = idb.transaction("files", "readwrite");
    const store = transaction.objectStore("files");
    store.put({ ...objData, statusUpload: "pending" });
}

export function fetchFromIDB(objData,callback=()=>{}, tries = 10) {
    if (typeof idb === "undefined" || idb === null) {
        if (tries === 0) {
            console.log("idb not ready ran out of tries");
            return;
        }
        console.log("idb not ready retrying tries left:", tries);
        setTimeout(() => fetchFromIDB(objData,callback, tries - 1), 1000);
        return;
    }

    let {type,car_id} = objData;
    type ??= "all";
    car_id ??= null;
    // console.log("idb:", idb);
    // fetch all items that contains statusUpload:pending
    if (type === "pending") {

        const transaction = idb.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        // const index = store.index("statusUpload");
        const request = store.getAll();

        request.onsuccess = function () {
            const pendingItems = request.result.filter((item) =>{
                if(car_id !== null){
                    return  item?.statusUpload === "pending" && item?.car_id === car_id;
                }
                return  item?.statusUpload === "pending";
            });
            //console.log("request.onsuccess:", pendingItems);
            if(typeof callback === "function"){
                callback(pendingItems);
            }
            // console.log("request.onsuccess:", request.result);
        };
        return;
    }else if(type === "pendingByTargetId"){
        let {target_id} = objData;

        const transaction = idb.transaction("files", "readwrite");
        const store = transaction.objectStore("files");
        const request = store.getAll();

        request.onsuccess = function () {
            const pendingItems = request.result.filter((item) =>{
                return  item?.statusUpload === "pending" && item?.target_id === target_id;
            });
            console.log("request.onsuccess:", pendingItems);
            if(typeof callback === "function"){
                callback(pendingItems);
            }
        }
        return ;
    }


     console.log("unknown type:", type);

    // const transaction = idb.transaction("files", "readwrite");
    // const store = transaction.objectStore("files");
    // const index = store.index("filename");
    // const request = index.getAll("test");

    // request.onsuccess = function () {
    //     console.log("request.onsuccess:", request.result);
    // };
}

export function updateIDb(fileId, objData,tries = 10) {
    if (typeof idb === "undefined" || idb === null) {
        if (tries === 0) {
            console.log("idb not ready ran out of tries");
            return;
        }
        console.log("idb not ready retrying tries left:", tries);
        setTimeout(() => updateIDb(fileId,objData, tries - 1), 1000);
        return;
    }

    const transaction = idb.transaction("files", "readwrite");
    const store = transaction.objectStore("files");
    const index = store.index("fileId");
    const request = index.getAll(fileId);

    request.onsuccess = function () {
        //console.log("Request success update of:", request.result);
        request.result.forEach((item) => {
            // item.statusUpload = statusUpload;
            // item = { ...item, ...objData };
            for(let key in objData){
                item[key] = objData[key];
            }
            store.put(item);
        });
    };
}

export function removeFromIDb(fileId, tries = 10) {
    if (typeof idb === "undefined" || idb === null) {
        if (tries === 0) {
            console.log("idb not ready ran out of tries");
            return;
        }
        console.log("idb not ready retrying tries left:", tries);
        setTimeout(() => removeFromIDb(fileId, tries - 1), 1000);
        return;
    }

    const transaction = idb.transaction("files", "readwrite");
    const store = transaction.objectStore("files");
    const index = store.index("fileId");
    const request = index.getAll(fileId);

    request.onsuccess = function () {
        //console.log("Request success remove of:", request.result);
        request.result.forEach((item) => {
            store.delete(item.id);
        });
    };
}


