import { baseURL } from "config";
import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import Button from "components/shared/Button";
import { fetchProgressVehicle } from "components/home/request/fetchProgressVehicle";
import { useContext } from "react";
import { UserDataContext } from "App";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input";
import { toast } from "react-toastify";

const ModalReferral = ({
  skipCheck = false,
  onComplete = () => {},
  onClose = () => {},
  isOpen,
}) => {
  const [userData, setUserData] = useContext(UserDataContext);
  const otherOptionRef = createRef();
  const [otherOptionSelected, setOtherOptionSelected] = useState(false);
  const [referral, setReferral] = useState({
    title: "",
    options: [],
    loaded: false,
  });

  useEffect(() => {
    if (skipCheck && !referral.loaded) {
      fetchReferralOptions();
      return;
    }

    if (referral.loaded) {
      return;
    }

    void fetchProgressVehicle(userData.token)
      .then(({ data }) => {
        if (!userData?.car) {
          return;
        }
        const referral = data?.auction?.referral_source;
        if (referral) {
          onComplete();
          onClose();
          return;
        }

        fetchReferralOptions();
      })
      .catch((error) => {
        if (error) throw error;
      });
  }, [
    onClose,
    onComplete,
    referral.loaded,
    skipCheck,
    userData?.car,
    userData.token,
  ]);

  function fetchReferralOptions() {
    axios({
      method: "get",
      url: baseURL + "/seller/settings",
    })
      .then((response) => {
        if (response?.data?.referral) {
          // const otherOption = response.data.referral.options.find((option)=>option.value === 'Other');
          // const otherOptionIndex = response.data.referral.options.indexOf(otherOption);
          // response.data.referral.options.splice(otherOptionIndex,1);
          // response.data.referral.options.push(otherOption);

          const otherOptionIndex = response.data.referral.options.findIndex(
            (option) => option === "Other"
          );
          if (otherOptionIndex !== -1) {
            response.data.referral.options.splice(otherOptionIndex, 1);
            response.data.referral.options.push("Other");
          }

          setReferral({
            title: response.data.referral.title,
            options: response.data.referral.options,
            loaded: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSelectReferral(value) {
    const parmas = new URLSearchParams();
    parmas.append("source", value);
    axios({
      method: "post",
      url: baseURL + "/seller/source",
      data: parmas,
      headers: {
        Authorization: userData.token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response?.data) {
          setUserData({ ...userData, skippedReferral: true });
          onComplete();
          onClose();
        }
      })
      .catch((error) => {
        toast.error("Error while setting referral source");
      });
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        onClose();
        setUserData({ ...userData, skippedReferral: true });
      }}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>{referral.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {otherOptionSelected ? (
          <div className="flex flex-col items-end">
            <Input
              containerClassName="w-full"
              type="text"
              ref={otherOptionRef}
              label="Your referral source"
              placeholder="Enter your referral source"
            />
            <Button
              onClick={() => {
                if (otherOptionRef.current.value !== "") {
                  onSelectReferral(otherOptionRef?.current?.value ?? "Other");
                }
              }}
              className="py-2 mt-2 !bg-skylla"
              label="Submit"
            />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-1.5 sm:gap-2">
            {referral.options.map((option, index) => {
              if (option === "Other") {
                return (
                  <Button
                    key={index}
                    onClick={() => setOtherOptionSelected(true)}
                    className="py-2 !px-1 text-xs sm:!text-base !bg-skylla"
                    label={option}
                  />
                );
              }
              return (
                <Button
                  key={index}
                  onClick={() => {
                    onSelectReferral(option);
                    setOtherOptionSelected(false);
                  }}
                  className="py-2 !px-1 text-xs sm:!text-base !bg-skylla"
                  label={option}
                />
              );
            })}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalReferral;
