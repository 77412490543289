import { UserDataContext } from "App";
import React, { useContext, useRef, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "components/shared/Button";
import { OfflineOneImageBox } from "./OfflineOneImageBox";

const OfflineImageUploader = ({ images, onImagesListChange = () => {} }) => {
  const [imagesList, setImagesList] = useState(() => {
    if (!images || images.length === 0) {
      return [];
    }

    let imagesExtraStuff = images?.map((i) => {
      return {
        ...i,
        localId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      };
    });

    // switch cover_photo to first element
    let cover_photo = imagesExtraStuff.find((i) => i.cover_photo === 1);

    if (cover_photo) {
      imagesExtraStuff.splice(imagesExtraStuff.indexOf(cover_photo), 1);
      imagesExtraStuff.unshift(cover_photo);
    }

    return imagesExtraStuff;
  });

  const [userData] = useContext(UserDataContext);
  const refUploadInput = useRef(null);
  const refContainerImagesList = useRef(null);

  // upload image with axios and then update imagesList
  const uploadOneImageAtTime = useCallback(
    (file, isLast) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("car_id", userData?.car?.id);
      // generate object url from file uploaded
      let objectURL = URL.createObjectURL(file);
      let randomLocalId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      let obj = {
        localURL: objectURL,
        localId: randomLocalId,
        name: file?.name || "",
        id: randomLocalId,
        cover_photo: 0,
        progress: 0,
      };

      setImagesList((p) => {
        const newState = [{ ...obj, file }, ...p];
        if (isLast) {
          onImagesListChange(newState);
        }
        return newState;
      });
    },
    [onImagesListChange, userData?.car?.id]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadOneImageAtTime(acceptedFiles[i], acceptedFiles.length === i + 1);
      }
    },
    [uploadOneImageAtTime]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    useFsAccessApi: false,
  });

  return (
    <div className="file-uploader-container">
      <div>
        <div className="flex flex-col flex-wrap w-full items-center">
          <input hidden {...getInputProps()} />
          <input
            ref={refUploadInput}
            id="image-uploader"
            hidden
            type="file"
            className="file-uploader-input"
            multiple
          />
          <div
            {...getRootProps()}
            htmlFor="image-uploader"
            className="flex flex-col items-center justify-center w-full border-2 border-[#5A6696] border-dashed rounded-lg cursor-pointer hover:bg-gray-100"
          >
            {isDragActive ? (
              "Drop the files here ..."
            ) : (
              <div className="flex items-center justify-center w-full">
                <div className="flex flex-col items-center justify-center py-6">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15"
                      stroke="#083151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.5 8L12.5 3L7.5 8"
                      stroke="#083151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5 3V15"
                      stroke="#083151"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <p className="mt-2 text-lg text-primary">
                    <span className="font-semibold">Upload Photos</span>
                  </p>

                  <p className="mt-2 flex items-center text-md text-primary">
                    <svg
                      width="25"
                      height="24"
                      className="mr-2"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.36 2H16.64L22.5 7.86V16.14L16.64 22H8.36L2.5 16.14V7.86L8.36 2Z"
                        stroke="#FF4E6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5 8V12"
                        stroke="#FF4E6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.5 16H12.51"
                        stroke="#FF4E6D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Listings with photos get more bids and better offers
                  </p>

                  <Button
                    className="capitalize text-lg rounded flex w-fit px-4 py-3 mt-4 font-bold pointer-events-none"
                    variant="secondary"
                    label="Add Photos"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{ paddingBlock: "5px" }} className="w-full">
          <div
            ref={refContainerImagesList}
            className="flex flex-wrap !justify-start image-container-list scroll-as-height"
          >
            {imagesList?.map((item, index) => {
              return (
                <OfflineOneImageBox
                  key={index}
                  item={item}
                  imagesList={imagesList}
                  onImageDelete={(arr) => {
                    setImagesList(arr);
                    // NOTE: Need to update parent's list aswell
                    onImagesListChange(arr);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfflineImageUploader;
