import { useContext, useEffect, useState } from "react";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import Select from "components/shared/Select";
import {
	fetchMakerList,
	fetchModelMaker,
	fetchTrimsMakerList,
} from "components/searchCars/request/apiFetchManualValues";
import Button from "components/shared/Button";

export default function TradeManual() {
	const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
	const [form, setForm] = useState({
		year: "",
		make: "",
		model: "",
		trim: "",
	});
	const [hasError, setHasError] = useState("");
	const [listMake, setListMake] = useState([]);
	const [listModel, setListModel] = useState([]);
	const [listTrim, setListTrim] = useState([]);
	let contextTrade = modalCar?.trade || {};
	contextTrade = contextTrade?.tradeType == "manual" ? contextTrade : {};
	useEffect(() => {
		dispatchModalCar({
			type: "set-action",
			payload: {
				backButton: function () {
					dispatchModalCar({
						type: "update",
						payload: {
							currentModal: "secondaryModal",
							currentSubModal: "tradeByType",
						},
					});
				},
			},
		});
		return () => {
			dispatchModalCar({
				type: "set-action",
				payload: {
					backButton: null,
				},
			});
		};
	}, []);

	const onSelectYear = (e) => {
		setHasError("");
		fetchMakerList(e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, year: e.target.value });
			setListMake(data);
		});
	};
	const onSelectMake = (e) => {
		setHasError("");
		fetchModelMaker(form.year, e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, make: e.target.value });
			setListModel(data);
		});
	};
	const onSelectModel = (e) => {
		setHasError("");
		fetchTrimsMakerList(form.year, form.make, e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, model: e.target.value });
			setListTrim(data);
		});
	};

	function handleContinueButton() {
		if (form.year === "") {
			setHasError("Please select a year");
			return;
		}
		if (form.make === "") {
			setHasError("Please select a make");
			return;
		}
		if (form.model === "") {
			setHasError("Please select a model");
			return;
		}
		dispatchModalCar({
			type: "trade-set",
			payload: {
				tradeType: "manual",
				...form,
			},
		});
		dispatchModalCar({
			type: "update",
			payload: {
				currentSubModal: "tradeDetails",
			},
		});
	}
	function handleBackButton() {
		dispatchModalCar({
			type: "set",
			payload: {
				currentModal: null,
			},
		});
	}
	return (
		<>
			<div className="w-full border-t border-neutral-light mt-4 p-4">
				{hasError && (
					<div className="text-center text-danger mb-0">{hasError}</div>
				)}
				<div className="parents-select-modifier">
					<Select
						onChange={onSelectYear}
						defaultValue={form.year}
						label="Year"
						placeholder="Choose Year"
						labelOptions={arrayYearList}
						valueOptions={arrayYearList}
					/>

					<Select
						onChange={onSelectMake}
						defaultValue={form.make}
						label="Make"
						disabled={listMake.length === 0}
						placeholder="Choose Make"
						labelOptions={listMake}
						valueOptions={listMake}
					/>

					<Select
						onChange={onSelectModel}
						defaultValue={form.model}
						label="Model"
						disabled={listModel.length === 0}
						placeholder="Choose Model"
						labelOptions={listModel}
						valueOptions={listModel}
					/>

					<Select
						onChange={(e) => {
							setHasError("");
							setForm({ ...form, trim: e.target.value });
						}}
						label="Trim"
						defaultValue={form.trim}
						disabled={listTrim.length === 0}
						placeholder="Choose Trim"
						labelOptions={listTrim}
						valueOptions={listTrim}
					/>
				</div>

				<div style={{ minWidth: "35%", marginTop: "25px" }}>
					<Button onClick={handleContinueButton} className="w-full my-2">
						Continue
					</Button>
				</div>
				<div className="flex text-center justify-center">
					<div onClick={handleBackButton} className="text-danger mt-2">
						Cancel the offer
					</div>
				</div>
			</div>
		</>
	);
}

let arrayYearList = Array.from({
	length: new Date().getFullYear() + 1 - 1990,
}).map((item, index) => 1990 + index);
arrayYearList.reverse();
