import React, { useState } from "react";
import { useContext } from "react";
import { fetchMoreDataFromPlate } from "../request/PathPlateRequest";
import { ContextForm } from "../contexts/FormContext";
import Button from "../../shared/Button";
import Select from "../../shared/Select";
import Input from "components/shared/Input/Input";

const PathPlate = () => {
  const { formRegister, appendFormRegister, editFormRegister } =
    useContext(ContextForm);
  const [form, setForm] = useState({
    plate: formRegister.plate ?? "",
    state: formRegister.state ?? "",
  });
  const [formError, setFormError] = useState({});

  function validateForm() {
    let error = {};
    if (form.plate === "") error.plate = "Plate is required";
    if (form.state === "") error.state = "State is required";
    setFormError(error);
    if (Object.keys(error).length > 0) return false;
    return true;
  }

  function handleSubmit() {
    if (!validateForm()) return;
    editFormRegister("loading", true);
    fetchMoreDataFromPlate({ ...form }, (error, data) => {
      if (error) {
        setFormError({
          plate:
            "Unable to obtain data from the plate, please check your network and try again.",
        });
        throw error;
      }
      const { success, ...restOfData } = data;
      if (success === 0) {
        editFormRegister("errorMessage", data.error);
      } else {
        appendFormRegister({ ...form, ...restOfData });
        editFormRegister("stepType", "detail");
      }
      editFormRegister("loading", false);
    });
  }

  let countryCode = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
    "DC",
    "AS",
    "GU",
    "MP",
    "PR",
    "UM",
    "VI",
  ];
  let countryNames = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "District of Columbia",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
    "Puerto Rico",
    "United States Minor Outlying Islands",
    "U.S. Virgin Islands",
  ];
  return (
    <>
      {formRegister?.errorMessage !== "" && (
        <div className="warning-error">{formRegister.errorMessage}</div>
      )}
      <div className="my-3">
        <Input
          invalid={formError.plate}
          value={form.plate}
          onChange={(e) => {
            setForm((i) => {
              return { ...i, plate: e.target.value };
            });
          }}
          label="License plate"
          placeholder="Plate number"
        />
      </div>
      <div className="my-3">
        <Select
          invalid={formError.state}
          defaultValue={form.state}
          onChange={(e) => {
            setForm((i) => {
              return { ...i, state: e.target.value };
            });
          }}
          label="State"
          placeholder="Select State"
          labelOptions={countryNames}
          valueOptions={countryCode}
        />
      </div>
      <Button label="Next" onClick={handleSubmit} />
    </>
  );
};

export default PathPlate;
