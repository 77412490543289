// firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAOpfnNbdc-Uf7uzXaQXjSvHFTfh5aTeLU",
  authDomain: "caripa.firebaseapp.com",
  databaseURL: "https://caripa.firebaseio.com",
  projectId: "caripa",
  storageBucket: "caripa.appspot.com",
  messagingSenderId: "256106621893",
  appId: "1:256106621893:web:e12543f45d95fc4821b9ac",
  measurementId: "G-S28BRDHD2H",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function FirebaseEvent(event) {
  logEvent(analytics, event);
  //   logEvent(analytics, event, { debug_mode: true });
}
