import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ImagesCarousel from "components/ImagesCarousel";
import Skeleton from "components/Skeleton";
import axios from "axios";
import { baseURL } from "config";
import Button from "components/shared/Button";
import BrowseCars from "components/BrowseCars";
import VehicleInfoBox from "./components/VehicleInfoBox";
import { EngineIcon, WheelIcon, TransmissionIcon, FuelIcon } from "./icons";
import ModalCar from "components/searchCars/ModalSpeed/ModalCar";
import useModal from "hooks/use-modal.hook";
import cn from "classnames";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import {
  BanknotesIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  PhoneIcon,
  XMarkIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { Bars3Icon } from "@heroicons/react/20/solid";
import useKeyAndOutsideClickEvent from "hooks/use-key-and-outside-click.hook";
import { useRef } from "react";

const Car = () => {
  const { id } = useParams();
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const [carDetails, setCarDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const carModal = useModal();
  const navigate = useNavigate();
  const [inquirePopover, setInquirePopover] = useState(false);
  const dropdownRef = useRef(null);

  const primaryDetails = [
    {
      id: 1,
      title: "Mileage",
      value: carDetails?.mileage?.toLocaleString() ?? "-",
    },
    { id: 2, title: "Stock Number", value: carDetails?.stock_number ?? "-" },

    { id: 5, title: "Engine", value: carDetails?.engine ?? "-" },
    {
      id: 6,
      title: "Transmission",
      value: carDetails?.transmission_type ?? "-",
    },
    { id: 7, title: "Fuel Type", value: carDetails?.fuel_type ?? "-" },
    { id: 11, title: "Condition", value: carDetails?.condition_name ?? "-" },
    {
      id: 12,
      title: "Asking Price",
      value: "$" + carDetails?.price?.toLocaleString() ?? "-",
    },
    { id: 13, title: "Seller Notes", value: carDetails?.notes ?? "-" },
    { id: 14, title: "Vin", value: carDetails?.vin ?? "-" },
  ];

  useEffect(() => {
    setLoading(true);
    apiFetchCarDetails(id);
    window.scrollTo(0, 0); // scroll to top
  }, [id]);

  useKeyAndOutsideClickEvent(dropdownRef, () => {
    setInquirePopover(false);
    if (inquirePopover && typeof window != "undefined" && window.document) {
      document.body.style.overflow = "scroll";
    }
  });

  const handleInquireClick = (e) => {
    e.stopPropagation();
    setInquirePopover(!inquirePopover);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow =
        inquirePopover === false ? "hidden" : "scroll";
    }
  };

  async function apiFetchCarDetails(carId) {
    try {
      const response = await axios.get(`${baseURL}/seller/inventory/${carId}`);
      setCarDetails(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const vehiclePhotos = carDetails?.photos?.split(",").map((photo) => {
    return { src: photo };
  });

  if (!loading && !carDetails) {
    return <Navigate to="/car-search" replace />;
  }

  const handleMakeAnOffer = () => {
    dispatchModalCar({
      type: "set",
      payload: {
        ...carDetails,
        currentModal: "secondaryModal",
        currentSubModal: "yourAccount",
      },
    });
    carModal.openModal();
  };

  const handleSubTestDrive = () => {
    dispatchModalCar({
      type: "set",
      payload: {
        ...carDetails,
        currentModal: "secondaryModal",
        currentSubModal: "testDrive",
      },
    });
    carModal.openModal();
  };

  const handleSubMoreInfo = () => {
    dispatchModalCar({
      type: "set",
      payload: {
        ...carDetails,
        currentModal: "secondaryModal",
        currentSubModal: "moreInfo",
      },
    });
    carModal.openModal();
  };

  return (
    <div>
      <div className="container px-4 xl:py-8">
        <div
          className={cn("fixed inset-0 z-30", {
            "backdrop-blur-[2px] flex": inquirePopover,
            hidden: !inquirePopover,
          })}
        />
        <div
          className="fixed flex flex-col items-end bottom-4 right-4 z-40 sm:hidden"
          ref={dropdownRef}
        >
          <div
            className={cn("z-10 bg-transparent", {
              hidden: !inquirePopover,
              flex: inquirePopover,
            })}
          >
            <ul
              className="flex flex-col space-y-1"
              aria-labelledby="dropdownUserAvatarButton"
            >
              <li>
                <a
                  href={`tel:${carDetails?.dealer_phone}`}
                  className="flex items-center justify-between w-full bg-secondary py-3 px-4 rounded-lg text-white"
                >
                  <span className="text-xs text-left font-normal max-w-[90px] mr-10">
                    Call Dealer
                  </span>
                  <PhoneIcon className="w-5 h-5 absolute right-4" />
                </a>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleSubMoreInfo();
                  }}
                  className="flex items-center justify-between w-full bg-secondary py-3 px-4 rounded-lg text-white"
                >
                  <span className="text-xs text-left font-normal mr-10 max-w-[90px]">
                    Request More Information
                  </span>
                  <InformationCircleIcon className="w-5 h-5 absolute right-4" />
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleSubTestDrive();
                  }}
                  className="flex items-center justify-between w-full bg-secondary py-3 px-4 rounded-lg text-white"
                >
                  <span className="text-xs text-left font-normal mr-10 max-w-[90px]">
                    Schedule Test Drive
                  </span>
                  <CalendarDaysIcon className="w-5 h-5 absolute right-4" />
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    handleMakeAnOffer();
                  }}
                  className="flex items-center justify-between w-full bg-secondary py-3 px-4 rounded-lg text-white"
                >
                  <span className="text-xs text-left font-normal mr-10 max-w-[90px]">
                    Make An Offer
                  </span>
                  <BanknotesIcon className="w-5 h-5 absolute right-4" />
                </button>
              </li>
            </ul>
          </div>
          <button
            className="flex items-center justify-end mt-1 bg-secondary w-fit py-3 px-4 rounded-full text-white"
            type="button"
            onClick={handleInquireClick}
          >
            <span
              className={cn("text-base uppercase font-medium mr-4", {
                hidden: inquirePopover,
              })}
            >
              Inquire
            </span>
            {inquirePopover ? (
              <XMarkIcon className="w-5 h-5" />
            ) : (
              <Bars3Icon className="w-5 h-5" />
            )}
          </button>
        </div>
        <Button
          variant="none"
          className="!px-0 md:!pl-2 pb-5 pt-2 md:pb-3"
          onClick={() => navigate(-1)}
          label={
            <div className="flex items-center">
              <ChevronLeftIcon className="w-4 h-4 text-[#14181F]" />{" "}
              <span className="text-primary text-xs sm:text-base font-medium">
                Back to previous page
              </span>
            </div>
          }
        />
        {loading ? (
          <Skeleton type="carPage" />
        ) : (
          <div className="flex flex-col lg:flex-row flex-wrap">
            <span className="block sm:hidden text-sm text-secondary mb-2 font-medium leading-tight">
              {carDetails?.year ?? ""}
            </span>
            <div className="block sm:hidden text-lg font-bold leading-tight text-primary mb-2">
              {`${carDetails?.make_name} ${carDetails?.model_name}`}
            </div>
            <div className="block sm:hidden text-lg font-medium leading-tight text-primary mb-2">
              {carDetails?.trim_name ?? ""}
            </div>
            <div className="w-full lg:w-7/12 md:py-0 md:px-3">
              {carDetails?.photos && (
                <ImagesCarousel images={vehiclePhotos ?? []} />
              )}
              <div className="flex overflow-x-scroll sm:overflow-x-hidden p-2 gap-2 mb-2 mt-4">
                <VehicleInfoBox
                  icon={<EngineIcon className="shrink-0 w-5 h-5" />}
                  title="Engine"
                  value={carDetails?.engine ?? "-"}
                />
                <VehicleInfoBox
                  icon={<WheelIcon className="shrink-0 w-5 h-5" />}
                  title="Drive type"
                  value={carDetails?.driven_wheels ?? "-"}
                />
                <VehicleInfoBox
                  icon={<TransmissionIcon className="shrink-0 w-5 h-5" />}
                  title="Transmission type"
                  value={carDetails?.transmission_type ?? "-"}
                />
                <VehicleInfoBox
                  icon={<FuelIcon className="shrink-0 w-5 h-5" />}
                  title="Fuel type"
                  value={carDetails?.fuel ?? "-"}
                />
              </div>
            </div>
            <div className="w-full lg:w-5/12 md:py-0 md:px-3">
              <span className="hidden sm:block text-sm text-secondary mb-1.5 leading-6 font-medium">
                {carDetails?.year ?? ""}
              </span>
              <div className="hidden sm:block text-3xl font-bold text-primary mb-1.5">
                {`${carDetails?.make_name} ${carDetails?.model_name}`}
              </div>
              <div className="hidden sm:block text-lg font-medium text-primary mb-6">
                {carDetails?.trim_name ?? ""}
              </div>
              <span className="pt-4 sm:pt-0 text-base text-secondary font-medium">
                <span className="text-black text-base">Location: </span>
                {carDetails?.location ?? ""}
              </span>
              {!carDetails?.dealer_phone ? null : (
                <span className="hidden sm:flex items-center pt-6 text-base text-secondary font-medium">
                  <span className="text-black text-base mr-2">
                    Call Dealership:
                  </span>
                  <div className="group">
                    <a
                      href={`tel:${carDetails?.dealer_phone}`}
                      className="flex items-center rounded-full pl-1 p-1 pr-2 border border-secondary group-hover:text-white group-hover:bg-secondary transition ease-in-out duration-100"
                    >
                      <span className="bg-secondary group-hover:text-white group-hover:bg-white rounded-full p-2 mr-2">
                        <PhoneIcon className="text-white fill-white group-hover:text-secondary group-hover:fill-secondary w-3 h-3 transition ease-in-out duration-100" />{" "}
                      </span>
                      {carDetails?.dealer_phone}
                    </a>
                  </div>
                </span>
              )}
              <div
                className={cn("p-2 mt-2 sm:mt-9 ", {
                  "bg-deal-good/10":
                    carDetails?.price_rating_text === "Good Deal",
                  "bg-deal-great/10":
                    carDetails?.price_rating_text === "Great Deal",
                  "bg-deal-fair/10":
                    carDetails?.price_rating_text === "Fair Deal",
                })}
              >
                <span className="text-lg sm:text-[22px] text-primary font-medium pb-2">
                  Car Price
                </span>
                <div className="flex justify-between items-end">
                  <p className="text-3xl font-medium text-secondary">
                    {carDetails?.price === 0
                      ? "Contact Us"
                      : "$" + carDetails?.price?.toLocaleString()}
                  </p>
                  {carDetails?.price === 0 ? (
                    <span className="uppercase text-[#888] text-sm">
                      No Price
                      <br />
                      Analysis
                    </span>
                  ) : (
                    <p className="text-sm font-medium text-primary">
                      {carDetails?.price_rating_text}
                    </p>
                  )}
                </div>
              </div>
              <div className="h-2 relative bg-neutral">
                <div
                  className={cn(
                    "w-full absolute top-0 bottom-0 left-0 rounded-tr rounded-br z-[3]",
                    {
                      "bg-neutral": !carDetails?.price_rating,
                      "bg-deal-great": carDetails?.price_rating === 3,
                      "bg-deal-good": carDetails?.price_rating === 4,
                      "bg-deal-fair": carDetails?.price_rating === 5,
                    }
                  )}
                ></div>
              </div>
              <div className="hidden sm:flex flex-col mt-9">
                <div className="flex gap-5">
                  <Button
                    variant="dark"
                    label="Request more info"
                    className="w-1/2"
                    onClick={() => {
                      handleSubMoreInfo();
                    }}
                  />
                  <Button
                    variant="dark"
                    label="Request Test Drive"
                    className="w-1/2"
                    onClick={() => {
                      handleSubTestDrive();
                    }}
                  />
                </div>
                <Button
                  variant="secondary"
                  label="Make an Offer"
                  className="w-full mt-3"
                  onClick={() => {
                    handleMakeAnOffer();
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-7/12 mt-5 md:mt-0 md:py-4 md:px-3">
              <div className="pb-2 flex-1 flex justify-between items-center">
                <h3 className="text-primary text-lg xl:text-2xl font-bold">
                  Primary Details Section
                </h3>
              </div>
              <div className="grid grid-cols-1 grid-rows-auto">
                {primaryDetails.map((detail) => (
                  <div
                    key={detail.id}
                    className="flex justify-between border-t border-[#08315159] py-2"
                  >
                    <span className="text-black text-base sm:text-lg font-medium shrink-0 mr-10">
                      {detail.title}
                    </span>
                    <span className="flex items-center font-normal text-sm sm:text-base text-[#393939] text-justify">
                      {detail.value}
                    </span>
                  </div>
                ))}
              </div>
              {carDetails?.features?.length === 0 ||
              carDetails?.features?.length === undefined ? null : (
                <div className="flex flex-col my-5">
                  <span className="text-primary text-lg xl:text-2xl font-bold pb-2 border-b border-[#08315159] mb-2">
                    Vehicle Features
                  </span>
                  <ul className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-auto gap-3">
                    {carDetails?.features?.split("|").map((feature, index) => (
                      <li
                        key={index}
                        className="list-disc text-sm font-normal ml-4"
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <BrowseCars key={id} />

      {carModal.isOpen && (
        <ModalCar
          isOpen={carModal.isOpen}
          onClose={carModal.closeModal}
          carSelected={modalCar}
        />
      )}
    </div>
  );
};

export default Car;
