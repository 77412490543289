import { ToastContainer } from "react-toastify";
import Footer from "./Footer";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Layout = ({ children }) => {
  const CloseToastButton = ({ closeToast }) => (
    <XMarkIcon className="w-5 h-5 text-white" onClick={closeToast} />
  );
  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Header */}
      <div className="sticky top-0 left-0 right-0 z-[5] bg-[#f9fbfe]">
        <div className="border-b border-[#DFEBF7]">
          <Header />
        </div>
      </div>
      <div className="relative grow shrink-0 h-full">{children}</div>
      <Footer />

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        closeButton={CloseToastButton}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className="sm:!w-auto !w-full"
      />
    </div>
  );
};

export default Layout;
