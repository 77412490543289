import { UserDataContext } from "App";
import axios from "axios";
import Button from "components/shared/Button";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { baseURL } from "config";
import React, { useState, useRef, useEffect, useContext } from "react";
import { formatPhoneNumber } from "utils/general";
import { baseURLv2 } from "config";
import { validateEmail } from "utils/validate";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input/Input";

const ModalApproveOffer = ({ isOpen, onClose, onComplete, bid }) => {
  const [userData, setUserData] = useContext(UserDataContext);
  const isProfileCompleteBaseEmail = !(
    userData.first_name === "" ||
    userData.first_name === null ||
    typeof userData.first_name === "undefined" ||
    userData.first_name === "Anonymous"
  );
  const [profileCompleted, setIsProfileCompleted] = useState(
    isProfileCompleteBaseEmail
  );
  const [isError, setIsError] = useState("");
  const refFirst_name = useRef(null);
  const refLast_name = useRef(null);
  const refEmail = useRef(null);
  const refPhone = useRef(null);

  function submitComplete() {
    if (refFirst_name.current.value === "") {
      setIsError("First name is required");
      return;
    }
    if (refLast_name.current.value === "") {
      setIsError("Last name is required");
      return;
    }
    if (refEmail.current.value === "") {
      setIsError("Email is required");
      return;
    }
    if (refPhone.current.value === "") {
      setIsError("Phone is required");
      return;
    }
    if (validateEmail(refEmail.current.value) === false) {
      setIsError("Email is invalid");
      return;
    }

    let params = new URLSearchParams();
    params.append("first_name", refFirst_name.current.value);
    params.append("last_name", refLast_name.current.value);
    params.append("email", refEmail.current.value);
    params.append("phone", refPhone.current.value);
    params.append("bid_id", bid.id);

    axios({
      method: "POST",
      url: baseURLv2 + "/seller/bid/accept",
      data: params,
      headers: {
        Authorization: userData.token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success === 0) {
          toast.error(response.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsProfileCompleted(false);
        } else {
          setIsProfileCompleted(true);
          onComplete();
          onClose();
          setUserData({
            ...userData,
            first_name: refFirst_name.current.value,
            last_name: refLast_name.current.value,
            email: refEmail.current.value,
            phone: refPhone.current.value,
          });
        }
      })
      .catch((error) => {
        toast.error("Profile fail to update, check network!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  useEffect(() => {
    if (refPhone.current) {
      formatPhoneNumber(refPhone.current);
      fetchProfile();
    }
  }, [refPhone]);

  function fetchProfile() {
    if (refFirst_name.current && refLast_name.current && refEmail.current) {
      axios({
        method: "GET",
        url: baseURL + "/seller/profile",
        headers: {
          Authorization: userData.token,
        },
      })
        .then((response) => {
          refFirst_name.current.value = response?.data?.first_name ?? "";
          refLast_name.current.value = response?.data?.last_name ?? "";
          refEmail.current.value = response?.data?.email ?? "";
          refPhone.current.value = response?.data?.phone ?? "";
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} withCloseButton>
      <Modal.Header>
        <Modal.Title>Accept Offer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bid ? (
          <div>
            <div className="text-xs">
              You are accepting an offer in the amount of{" "}
              <b>${bid.bid_amount ? bid.bid_amount.toLocaleString() : -1}</b>{" "}
              from <b>{bid.dealer_name}</b>
              <br />
              <br />
              Please fill out your information below in order to receive
              personalized confirmation quote from the dealership delivered to
              your email. This will also release your contact information to
              this dealer
            </div>
            {isError !== "" && <div className="error-message">{isError}</div>}

            <Input
              placeholder="First Name"
              label="First Name"
              ref={refFirst_name}
            />
            <Input
              placeholder="Last Name"
              label="Last Name"
              ref={refLast_name}
            />
            <Input placeholder="Email" label="Email" ref={refEmail} />
            <Input
              data-pattern="***-***-****"
              placeholder="Phone"
              label="Mobile Phone"
              ref={refPhone}
            />
            <span className="text-center text-xs mt-2 text-neutral px-2">
              By accepting the offer , I agree that I was accurate on the
              condition and mileage of my vehicle. Failure to disclose any
              issues with your vehicle may result in a deduction of the accepted
              bid price and/or no deal at all.
            </span>
          </div>
        ) : (
          <Loading />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            submitComplete();
          }}
          variant="primary"
        >
          Update & Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalApproveOffer;
