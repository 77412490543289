import { baseURL } from "config";
import axios from "axios";

var controllerApiFetchCheckVin = null;
export function apiFetchVinData(vin,cb){
    if(controllerApiFetchCheckVin){
        controllerApiFetchCheckVin.abort();
    }
    controllerApiFetchCheckVin = new AbortController();

    const params = new URLSearchParams();
    params.append("vin", vin);
    axios({
        method: "POST",
        url: baseURL + "/seller/register/vin",
        data: params,
        headers:{
            "Content-Type": "application/x-www-form-urlencoded"
        },
        signal: controllerApiFetchCheckVin.signal
    }).then((response) => {
        cb(null,response.data);
    }).catch((error) => {
        if(error.name !== "AbortError"){
            return; // ignore abort error from user
        }
        cb(error,null);
    });
}