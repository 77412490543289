import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { UserDataContext } from "../../App";

import Button from "components/shared/Button";
import { useLocation } from "react-router-dom";
import Input from "components/shared/Input";
import {
  MagnifyingGlassIcon,
  CheckIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import useModal from "hooks/use-modal.hook";
import ModalApproveOffer from "components/ModalApproveOffer";
import ModalReferral from "components/ModalReferral";
import ModalCounterBid from "components/ModalCounterBid";
import ChatMessage from "./ChatMessage";
import { ChatContext } from "./contexts/ChatContext";
import { find, findIndex } from "lodash";
import { toast } from "react-toastify";

const MessageLayout = () => {
  const location = useLocation();
  const [, setUserData] = useContext(UserDataContext); // user token and data
  const {
    chatRooms: { selected, rooms },
    setChatRooms,
  } = useContext(ChatContext);
  const [activeTab, setActiveTab] = useState(1); // control view in mobile
  const bids = useMemo(
    () => find(rooms, { roomData: selected })?.bids ?? [],
    [rooms, selected]
  );

  const referralModal = useModal();
  const approveModal = useModal();
  const counterOfferModal = useModal();

  const lastOffer = useRef(null);
  const refSearchInput = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const codePreSelected = urlParams.get("roomId");

  useEffect(() => {
    if (refSearchInput.current) {
      refSearchInput.current.addEventListener("keyup", searchFromList);
    }

    return () => {
      if (refSearchInput.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refSearchInput.current.removeEventListener("keyup", searchFromList);
      }
    };
  }, []);
  useEffect(() => {
    if (!codePreSelected) return;
    if (codePreSelected) {
      let target = rooms.find((room) => {
        return room?.roomData.room_id === codePreSelected;
      });

      if (target) {
        setChatRooms((prevState) => ({
          ...prevState,
          selected: target,
        }));
        roomInfo(target);
      }
    }
  });
  useEffect(() => {
    if (selected && selected.room_id) {
      toast.dismiss(selected.room_id);
      window.history.replaceState(
        {},
        "",
        window.location.pathname + "?roomId=" + selected?.room_id
      );
    }
  }, [selected, selected?.room_id]);

  function searchFromList() {
    // query all row-container-op1 and hide them if not match with value of refSearchInput.current.value
    const list = [...document.querySelectorAll(".row-container-op1")];

    list.forEach((parent) => {
      let item = parent.querySelector(".title")?.firstChild;
      if (
        item.innerText
          .toLowerCase()
          .indexOf(refSearchInput.current.value.toLowerCase()) === -1
      ) {
        parent.style.display = "none";
      } else {
        parent.style.display = "";
      }
    });
  }

  useEffect(() => {
    setUserData((p) => {
      return {
        ...p,
        newMessage: 0,
        newMessageFrom: [],
        newBid: 0,
        statusNotification: [],
      };
    });
  }, [setUserData]);

  useEffect(() => {
    if (selected) return;

    if (location.state?.showTarget) {
      let target = rooms.find((room) => {
        return room?.roomData.dealer_name === location.state.showTarget;
      });

      if (target) {
        setChatRooms((prevState) => ({
          ...prevState,
          selected: target,
        }));
        roomInfo(target);
      }
      location.state = null;
      return;
    }
  }, [location, roomInfo, rooms, selected, setChatRooms]);

  useEffect(() => {
    if (!selected) return;

    if (lastOffer.current) {
      lastOffer.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selected]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function roomInfo(item, action) {
    if (action === "RM-LOCATION") {
      location.state = null;
    }

    setChatRooms((prevState) => ({
      ...prevState,
      selected: item,
    }));

    setActiveTab(1);
    setUserData((prevState) => {
      return {
        ...prevState,
        newMessage: 0,
        newMessageFrom: [],
        newBid: 0,
        statusNotification: [],
      };
    });
  }

  let bidsFilter = selected
    ? bids.filter((r) => r?.dealer_name === selected?.dealer_name)
    : [];

  let isAnotherWinner = false;
  // get winner from bids
  let winner = bids.find((r) => r.winner === 1);
  if (winner) {
    if (!bidsFilter.includes(winner)) {
      isAnotherWinner = true;
    }
  }

  return (
    <div className="container py-8">
      {/* For mobile view port  */}
      <div className="flex xl:hidden">
        {/*  when is chat active */}
        {activeTab === 1 && (
          <div
            className="c-pointer flex text-xs items-center font-medium pb-3"
            onClick={() => setActiveTab(0)}
          >
            <ChevronLeftIcon className="w-4 h-4" />
            Back
          </div>
        )}
        {activeTab === 2 && (
          <div
            className="c-pointer flex text-xs items-center font-medium pb-3"
            onClick={() => setActiveTab(1)}
          >
            <ChevronLeftIcon className="w-4 h-4" />
            Back
          </div>
        )}
      </div>
      <div className="h-[600px] relative flex bg-white shadow rounded-xl">
        <div
          className={
            "w-full xl:border-r xl:flex xl:flex-col px-0 xl:w-1/4 " +
            (activeTab === 0 ? "flex flex-col" : "hidden")
          }
        >
          <div className="w-full p-4">
            <Input
              ref={refSearchInput}
              placeholder="Search"
              type="text"
              icon={<MagnifyingGlassIcon className="w-6 h-6" />}
            />
          </div>
          <div className="overflow-y-auto h-full custom-scrollbar">
            {rooms?.map((room, index) => {
              const item = room?.roomData;
              const bidLengthForDealer = room.bids?.length;
              if (location.state && location.state?.dealer_name) {
                if (location.state?.dealer_name === item?.dealer_name) {
                  // execute roomInfo function with item as argument once on render
                  roomInfo(item, "RM-LOCATION");
                }
              }

              return (
                <div
                  onClick={() => {
                    roomInfo(item);
                  }}
                  key={index}
                  style={{ display: "" }}
                  className={cn(
                    "row-container-op1 flex cursor-pointer relative items-center justify-between p-2",
                    {
                      "bg-[#F9F9F9] after:content-[''] after:absolute after:inset-y-0 after:left-0 after:border-l-2 after:border-secondary":
                        selected?.room_id === item?.room_id,
                    }
                  )}
                >
                  <div className="title grow ml-3">
                    <div className="text-lg font-bold text-[#000]">
                      {item?.dealer_name}
                    </div>
                    <div className="text-sm font-thin">
                      {bidLengthForDealer > 0 ? (
                        <>
                          {bidLengthForDealer} Offer
                          {bidLengthForDealer > 1 && "s"}
                        </>
                      ) : (
                        "No Offers"
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={
            "w-full xl:w-2/4 xl:flex bg-white xl:flex-col h-full m-0 border-r border-[#EBEBEB] " +
            (activeTab === 1 ? "flex flex-col" : "hidden")
          }
        >
          <ChatMessage
            key={selected?.room_id ?? 0}
            onTabClick={(index) => setActiveTab(index)}
          />
        </div>
        <div
          className={
            "w-full xl:w-1/4 scroll-as-height xl:flex xl:flex-col h-full " +
            (activeTab === 2 ? "flex flex-col" : "hidden")
          }
        >
          <div className="hidden xl:flex xl:flex-col xl:justify-center p-3 border-b border-[#EBEBEB] max-h-28 h-full">
            <div className="text-[22px] text-primary font-medium">
              Dealer Offers
            </div>
          </div>
          <div className="chat-header flex flex-col xl:hidden px-3 pt-3 pb-0 xl:border-b border-[#EBEBEB] bg-[#0831510D] xl:bg-transparent">
            <div className="flex items-center border-b border-[#EBEBEB] w-full pb-3">
              <div className="ms-3 flex justify-between items-center w-full">
                <div>
                  <div className="name-text-header text-2xl text-[#000]">
                    {selected?.dealer_name ?? ""}
                  </div>
                  <div className="text-[#838383CC] text-base">
                    {selected?.location ?? ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center py-4">
              <p className="font-normal text-sm text-primary mb-0">
                See all bids from{" "}
                <span className="font-bold">{selected?.dealer_name}</span>
              </p>
              <ChevronDownIcon className="w-5 h-5" />
            </div>
          </div>
          {bidsFilter.length > 0 ? (
            <div className="relative flex flex-col justify-between h-full">
              <div className="flex flex-col space-y-4 max-h-96 overflow-y-auto h-full custom-scrollbar">
                {selected &&
                  bidsFilter?.map((bid, index) => {
                    const isLast = index === 0;
                    return (
                      <div
                        ref={isLast ? lastOffer : null}
                        key={index}
                        className="relative flex justify-between items-center p-3 after:content-[''] after:absolute after:h-6 after:w-0.5 after:-bottom-5 after:left-8 after:bg-neutral after:rounded-full last:after:hidden"
                      >
                        <div className="flex items-center space-x-2">
                          <div
                            className={cn("rounded-full p-2", {
                              "bg-[#2CB74B]": isLast,
                              "bg-[#838383CC]": !isLast,
                            })}
                          >
                            <BanknotesIcon className="w-6 h-6 text-white" />
                          </div>
                          <div
                            className={cn("text-lg font-bold", {
                              "text-primary": isLast,
                              "text-[#838383CC]": isLast,
                            })}
                          >
                            ${parseInt(bid.bid_amount).toLocaleString()}
                          </div>
                        </div>
                        <span className="text-xs text-[#838383CC] font-normal">
                          {new Date(bid.created_date).toLocaleDateString(
                            "en-US",
                            {
                              weekday: "long",
                              hour: "numeric",
                              minute: "numeric",
                            }
                          )}
                        </span>
                      </div>
                    );
                  })}
              </div>
              {!isAnotherWinner && (
                <div className="flex gap-2 pt-4 pb-4 px-3 border-t">
                  {bidsFilter[0].winner !== 1 && bidsFilter[0].winner !== 2 && (
                    <Button
                      label={
                        <>
                          <CheckIcon className="w-4 h-4 mr-1" /> Accept
                        </>
                      }
                      className="text-white text-sm font-normal bg-success py-2 px-6 flex w-1/2"
                      onClick={() => {
                        referralModal.openModal();
                      }}
                    />
                  )}
                  {bidsFilter[0].winner !== 1 && bidsFilter[0].winner !== 2 && (
                    <Button
                      onClick={() => {
                        if (bidsFilter.length > 0) {
                          counterOfferModal.openModal();
                        }
                      }}
                      label={
                        <>
                          <ArrowPathIcon className="w-4 h-4 mr-1" /> Counter
                        </>
                      }
                      className="text-white text-sm font-normal bg-secondary py-2 px-6 flex w-1/2"
                    />
                  )}
                  {bidsFilter.length > 0 &&
                    bidsFilter[0].winner === 1 &&
                    bidsFilter[0].winner !== 2 && (
                      <span className="w-full text-neutral text-center text-md">
                        <strong>{bidsFilter[0]?.dealer_name}</strong> is the
                        winner.
                      </span>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex h-full justify-center items-center">
              <p className="text-sm text-[#8B8B8B]">
                There is no offer to show
              </p>
            </div>
          )}
        </div>

        {!approveModal.isOpen ? null : (
          <ModalApproveOffer
            isOpen={approveModal.isOpen}
            bid={bidsFilter[0]}
            onComplete={() => {
              window.location.reload();
            }}
            onClose={approveModal.closeModal}
          />
        )}

        {!referralModal.isOpen ? null : (
          <ModalReferral
            isOpen={referralModal.isOpen}
            onClose={referralModal.closeModal}
            onComplete={() => {
              approveModal.openModal();
            }}
          />
        )}

        {!counterOfferModal.isOpen ? null : (
          <ModalCounterBid
            bid={bidsFilter[0]}
            isOpen={counterOfferModal.isOpen}
            onClose={counterOfferModal.closeModal}
            onComplete={(bid, valueCounter) => {
              setChatRooms((pChatData) => {
                const { rooms } = pChatData;
                const newRooms = [...rooms];
                const roomIndex = findIndex(newRooms, (room) => {
                  return room.roomData.dealer_name === bid.dealer_name;
                });

                const newMessage = {
                  file_name: null,
                  message:
                    "I would like to counter-offer you with $" +
                    parseInt(valueCounter).toLocaleString(),
                  message_date: new Date().toISOString(),
                  message_id: Math.floor(Math.random() * (2 ** 31 - 1)),
                  generated_local: true,
                  you: 1,
                };
                const chatMessages = [
                  ...newRooms[roomIndex].chatMessages,
                  newMessage,
                ];

                newRooms[roomIndex] = {
                  ...newRooms[roomIndex],
                  chatMessages,
                };

                return {
                  ...pChatData,
                  rooms: newRooms,
                };
              });
              counterOfferModal.closeModal();
            }}
          />
        )}
      </div>
    </div>
  );
};

export { MessageLayout };
