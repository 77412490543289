import axios from "axios";
import { baseURL, imageUrlThumb } from "config.js";
import { useEffect, useState, useReducer } from "react";

const RecentSold = () => {
  const [recentlySoldList, setRecentlySoldList] = useState([]);
  const [offset, dispatchOffset] = useReducer((state, action) => {
    switch (action.type) {
      case "next":
        return state + 1 < recentlySoldList.length - 3 ? state + 1 : 0;
      case "prev":
        return state - 1 >= 0 ? state - 1 : recentlySoldList.length - 3;
      case "viewAll":
        return -1;
      case "showLess":
        return 0;
      default:
        return state;
    }
  }, 0);

  useEffect(() => {
    // fetchRecentlySoldList
    fetchRecentlySoldList((error, data) => {
      if (!error) setRecentlySoldList(data);
    });
  }, []);

  // Recent Sold
  return (
    <div className="block py-20">
      <div className="recent-sold-speed ">
          <h2 className="mb-3 text-xl font-bold text-primary">Recently Sold</h2>

        <div className="flex items-center">
          {offset === -1 ? (
            <div
              onClick={() => dispatchOffset({ type: "showLess" })}
              className="subheader-speed-negotiations red c-pointer"
            >
              Show less
            </div>
          ) : (
            <>
              <div
                onClick={() => dispatchOffset({ type: "viewAll" })}
                className="show-more subheader-speed-negotiations red c-pointer ms-2"
              >
                Show more
              </div>
            </>
          )}
        </div>
      </div>

      <div className="columns-1 md:columns-3">
        {offset !== -1 &&
          recentlySoldList.slice(offset, offset + 3).map((item, index) => {
            return <ColRecentSold key={index} {...item} index={index} />;
          })}
        {offset === -1 &&
          recentlySoldList.map((item, index) => {
            return <ColRecentSold key={index} {...item} index={index} />;
          })}
      </div>
    </div>
  );
};

export default RecentSold;

function ColRecentSold({ cover_photo, title, location, index }) {
  return (
      <div className="flex flex-col">
        <div>
          <img
            alt=""
            className="rounded mb-3 w-full h-[160px] object-cover"
            src={imageUrlThumb + cover_photo}
          />
        </div>
        <div className="ms-2">
          <div className="truncate">{title}</div>
          <div className="text-sm text-[#006b9f] font-normal">{location}</div>
        </div>
      </div>
  );
}

/// apis
function fetchRecentlySoldList(callback) {
  axios({
    method: "GET",
    url: baseURL + "/seller/recently-sold",
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      callback(error, null);
    });
}
