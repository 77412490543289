export const SWIPER_CONFIG = {
  breakpoints: {
    0: {
      slidesPerView: "auto",
      spaceBetween: 20,
    },
    640: {
      slidesPerView: "auto",
      spaceBetween: 20,
    },
    768: {
      slidesPerView: "auto",
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: "auto",
      spaceBetween: 30,
    },
  },
};
