/* eslint-disable no-fallthrough */
import { UserDataContext } from "App";
import { useContext, useEffect, useReducer, useState } from "react";
import { updateOfferAPI } from "../request/updateOfferAPI";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import CarInformation from "./CarInformation";
import TradeInModal from "./TradeInModal";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import useModal from "hooks/use-modal.hook";
import Button from "components/shared/Button";
import { NegotiationsContext } from "../contexts/NegotiationsContext";
import { find, findIndex } from "lodash";

import cn from "classnames";

function monthly_paymentCalc(loanAmount, apr, loanTerm) {
  apr = apr / 100;
  if (apr === 0) {
    return loanAmount / loanTerm;
  }
  var monthlyPayment =
    (loanAmount * (apr / 12)) / (1 - Math.pow(1 + apr / 12, -loanTerm));
  return monthlyPayment;
}

const CalculatorReducer = (state = {}, action) => {
  var targetUpdate = {}; // used to update target action
  switch (action.type) {
    case "update_trade_photos": {
      let trade_photo = action.payload;
      let list_trade_photos = state?.trade_photos?.split(",") || [];
      list_trade_photos.push(trade_photo);
      let trade_photos = list_trade_photos.join(",");
      return { ...state, trade_photos: trade_photos };
    }
    case "update_state":
      // eslint-disable-next-line no-redeclare
      var state = action.payload;
      targetUpdate = { showButtonUpdate: false };
    case "update_price":
      Object.keys(targetUpdate).length === 0 &&
        (targetUpdate = { price: action.payload });
    case "update_down_payment":
      Object.keys(targetUpdate).length === 0 &&
        (targetUpdate = { down_payment: action.payload });
    case "update_trade_loan_balance":
      Object.keys(targetUpdate).length === 0 &&
        (targetUpdate = { trade_loan_balance: action.payload });
    case "update_trade_in":
      Object.keys(targetUpdate).length === 0 &&
        (targetUpdate = { trade_in: action.payload });
      {
        let showButtonUpdate = targetUpdate["showButtonUpdate"] ?? true;

        let price = targetUpdate["price"] ?? state?.price ?? 0;
        let down_payment =
          targetUpdate["down_payment"] ?? state?.down_payment ?? 0;
        let trade_in = targetUpdate["trade_in"] ?? state?.trade_in ?? 0;
        let trade_loan_balance =
          targetUpdate["trade_loan_balance"] ?? state?.trade_loan_balance ?? 0;
        let tax_rate = state?.tax_rate ?? 0;
        let apr = state?.apr ?? 0;
        let loan_term = state?.loan_term ?? 0;
        let gap = state?.gap ?? 0;
        let warranty = state?.warranty ?? 0;
        let accessories = state?.accessories ?? 0;
        let fees = state?.fees ?? 0;

        var priceWithExtras = warranty + gap + accessories + fees;
        var tax = (price + priceWithExtras - trade_in) * (tax_rate / 100);
        tax = tax < 0 ? 0 : tax;
        var loan_amount =
          price +
          priceWithExtras +
          tax -
          down_payment -
          (trade_in - trade_loan_balance);
        loan_amount = loan_amount < 0 ? 0 : loan_amount;

        let monthly_payment = monthly_paymentCalc(loan_amount, apr, loan_term);
        return {
          ...state,
          fees,
          warranty,
          accessories,
          gap,
          price,
          loan_term,
          original_price: state.price,
          down_payment,
          trade_in,
          trade_loan_balance,
          tax_rate,
          apr,
          loan_amount,
          monthly_payment,
          showButtonUpdate: showButtonUpdate,
          taxValue: tax,
        };
      }
    case "update_btn":
      return { ...state, showButtonUpdate: false };
    default:
      return state;
  }
};

const INITIAL_STATE = {
  price: 0,
  down_payment: 0,
  trade_in: 0,
  trade_loan_balance: 0,
  tax_rate: 0,
  apr: 0,
  loan_term: 0,
  gap: 0,
  warranty: 0,
  accessories: 0,
  fees: 0,
  loan_amount: 0,
  monthly_payment: 0,
  showButtonUpdate: false,
  taxValue: 0,
};

const APRcalculatorStar = () => {
  const userData = useContext(UserDataContext)[0];
  const {
    chatRooms: { rooms, selected: selectedRoom, loading: loadingChat },
    setChatRooms,
  } = useContext(NegotiationsContext);
  const [selected, setSelected] = useState(null);

  const tradeInModal = useModal();

  const [state, dispatch] = useReducer(CalculatorReducer, INITIAL_STATE);

  useEffect(() => {
    const selectedNegotiation =
      find(rooms, { roomData: selectedRoom })?.negotiation || {};

    setSelected({
      ...selectedRoom,
      ...selectedNegotiation,
    });

    dispatch({
      type: "update_state",
      payload: {
        ...selectedRoom,
        ...selectedNegotiation,
      },
    });
  }, [rooms, selectedRoom]);

  function handlePriceChange(e) {
    var valuePrice = e.target.value;
    valuePrice = valuePrice.replace(/[^0-9]/g, "");
    valuePrice = valuePrice === "" ? 0 : parseInt(valuePrice);
    if (valuePrice > 500000) {
      valuePrice = 500000;
    }
    e.target.value = `$` + valuePrice?.toLocaleString();
    dispatch({ type: "update_price", payload: valuePrice });
  }

  function handleDownPaymentChange(e) {
    var valueDownPayment = e.target.value;
    valueDownPayment = valueDownPayment.replace(/[^0-9]/g, "");
    valueDownPayment = valueDownPayment === "" ? 0 : parseInt(valueDownPayment);
    if (valueDownPayment > 500000) {
      valueDownPayment = 500000;
    }
    e.target.value = `$` + valueDownPayment?.toLocaleString();
    dispatch({ type: "update_down_payment", payload: valueDownPayment });
  }

  function handleTradeInChange(e) {
    var valueTradeIn = e.target.value;
    valueTradeIn = valueTradeIn.replace(/[^0-9]/g, "");
    valueTradeIn = valueTradeIn === "" ? 0 : parseInt(valueTradeIn);
    if (valueTradeIn > 500000) {
      valueTradeIn = 500000;
    }
    e.target.value = `$` + valueTradeIn?.toLocaleString();
    dispatch({ type: "update_trade_in", payload: valueTradeIn });
  }

  function handleTradeLoanBalanceChange(e) {
    var valueTradeLoanBalance = e.target.value;
    valueTradeLoanBalance = valueTradeLoanBalance.replace(/[^0-9]/g, "");
    valueTradeLoanBalance =
      valueTradeLoanBalance === "" ? 0 : parseInt(valueTradeLoanBalance);
    if (valueTradeLoanBalance > 500000) {
      valueTradeLoanBalance = 500000;
    }
    e.target.value = `$` + valueTradeLoanBalance?.toLocaleString();
    dispatch({
      type: "update_trade_loan_balance",
      payload: valueTradeLoanBalance,
    });
  }

  function handleUpdateOffer() {
    updateOfferAPI(
      {
        code: selected?.code,
        price: state.price,
        down_payment: state.down_payment,
        trade_in: state.trade_in,
        trade_loan_balance: state.trade_loan_balance,
      },
      userData.token,
      (err, data) => {
        if (err || data?.success === 0) {
          toast.error(err.message);
          return;
        }
        toast.success("Update offer success");

        setChatRooms((prevChatRooms) => {
          const { rooms } = prevChatRooms;
          const newRooms = [...rooms];
          const roomIndex = findIndex(
            newRooms,
            (room) => room.roomData.room_id === parseInt(selected?.room_id)
          );

          if (roomIndex === -1) {
            return prevChatRooms;
          }

          const chatMessages = [...newRooms[roomIndex].chatMessages];
          const newMessage = {
            message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
            generated_local: true,
            message: `I updated my numbers - Offer: $${state.price?.toLocaleString()}, Down Payment: $${state.down_payment?.toLocaleString()}, Trade-In: $${state.trade_in?.toLocaleString()}`,
            message_date: new Date().toISOString(),
            file_name: null,
            you: 1,
          };

          newRooms[roomIndex] = {
            ...newRooms[roomIndex],
            negotiation: {
              ...newRooms[roomIndex].negotiation,
              price: state.price,
              down_payment: state.down_payment,
              trade_in: state.trade_in,
              trade_loan_balance: state.trade_loan_balance,
            },
            chatMessages: [...chatMessages, newMessage],
          };

          return {
            ...prevChatRooms,
            rooms: newRooms,
          };
        });

        dispatch({ type: "update_btn" });
      }
    );
  }

  return (
    <>
      <div className="hidden xl:flex xl:flex-col xl:justify-center p-3 border-b border-[#EBEBEB] max-h-32 h-full shrink-0 sticky top-0 left-0 right-0 bg-white z-[2]">
        <div className="text-[22px] text-primary font-medium">Your Offer</div>
      </div>
      <div className="chat-header flex flex-col xl:hidden px-3 pt-3 pb-0 xl:border-b border-[#EBEBEB] bg-[#0831510D] xl:bg-transparent">
        <div className="flex items-center border-b border-[#EBEBEB] w-full pb-3">
          <div className="ms-3 flex justify-between items-center w-full">
            <div>
              <div className="name-text-header text-2xl text-[#000]">
                {selected?.dealer_name ?? ""}
              </div>
              <div className="text-secondary text-base">
                {selected?.location ?? ""}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center py-4">
          <p className="font-normal text-sm text-primary mb-0">
            Car bidding for <span className="font-bold">{selected?.title}</span>
          </p>
          <ChevronDownIcon className="w-5 h-5" />
        </div>
      </div>
      <div className="scroll-as-height flex flex-col justify-between h-full">
        {loadingChat || !selected ? (
          <div className="flex items-center h-full justify-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 grid-rows-auto px-2">
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  {state.financing === 1 ? "Your Offer" : "Cash Offer"}
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  <input
                    onChange={handlePriceChange}
                    onFocus={(e) => e.target.select()}
                    value={"$" + state.price?.toLocaleString()}
                    type="text"
                    className="press-input-transit"
                  />
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Dealer Offer
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  ${Math.round(selected?.dealer_price ?? 0)?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Down Payment
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  <input
                    onChange={handleDownPaymentChange}
                    onFocus={(e) => e.target.select()}
                    value={"$" + state.down_payment?.toLocaleString()}
                    type="text"
                    className="press-input-transit"
                  />
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Warranty
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  {selected?.warranty === 0 || selected?.warranty == null
                    ? "N/A"
                    : "$" + selected?.warranty?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Gap Insurance
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  {selected?.gap === 0 || selected?.gap == null
                    ? "N/A"
                    : "$" + selected?.gap?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Accessories
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  {selected?.accessories === 0 || selected?.accessories === null
                    ? "N/A"
                    : "$" + selected?.accessories?.toLocaleString()}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">Fees</span>
                <span className="flex items-center text-xs text-neutral-dark">
                  +${selected?.fee ?? selected?.fees ?? 0}
                </span>
              </div>
              <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                <span className="text-primary text-sm font-medium">
                  Sales tax ({state.tax_rate}%)
                </span>
                <span className="flex items-center text-xs text-neutral-dark">
                  ${Math.round(state?.taxValue || 0).toLocaleString()}
                </span>
              </div>
              {state.financing === 1 ? (
                <>
                  <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                    <span className="text-primary text-sm font-medium">
                      Loan Amount
                    </span>
                    <span className="flex items-center text-xs text-neutral-dark">
                      ${Math.round(state.loan_amount ?? 0).toLocaleString()}
                    </span>
                  </div>
                  <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                    <span className="text-primary text-sm font-medium">
                      Loan Term
                    </span>
                    <span className="flex items-center text-xs text-neutral-dark">
                      {state?.loan_term} Months
                    </span>
                  </div>
                  <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                    <span className="text-primary text-sm font-medium">
                      APR
                    </span>
                    <span className="flex items-center text-xs text-neutral-dark">
                      {selected?.apr}%
                    </span>
                  </div>
                  <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                    <span className="text-primary text-sm font-medium">
                      Monthly Payment
                    </span>
                    <span className="flex items-center text-xs text-neutral-dark">
                      ${Math.round(state.monthly_payment).toLocaleString()}
                    </span>
                  </div>
                </>
              ) : (
                <div className="flex justify-between items-center border-b border-[#08315159] py-1">
                  <span className="text-primary text-sm font-medium">
                    Total Due
                  </span>
                  <span className="flex items-center text-xs text-neutral-dark">
                    ${Math.round(state.loan_amount).toLocaleString()}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 pt-4 pb-4 px-3 border-t">
              <CarInformation
                onTradeInClick={() => {
                  tradeInModal.openModal();
                }}
                offer={state}
                handleTradeLoanBalanceChange={handleTradeLoanBalanceChange}
                handleTradeInChange={handleTradeInChange}
              />

              <div
                className={cn(
                  "absolute inset-x-0 bottom-0 flex flex-col gap-2 bg-white",
                  {
                    "border-t border-neutral-light py-4 px-2":
                      state.showButtonUpdate || !state?.trade_condition,
                  }
                )}
              >
                {state.showButtonUpdate && (
                  <Button
                    className="w-full text-white text-sm font-normal bg-[#2CB74B] py-2 px-6 flex"
                    onClick={handleUpdateOffer}
                    label="Send to dealer"
                  />
                )}
                {!state?.trade_condition && (
                  <Button
                    className="w-full"
                    label="ADD TRADE-IN"
                    onClick={() => tradeInModal.openModal()}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!tradeInModal.isOpen ? null : (
        <TradeInModal
          state={state}
          isOpen={tradeInModal.isOpen}
          onComplete={() => {
            window.location.reload();
          }}
          onClose={tradeInModal.closeModal}
        />
      )}
    </>
  );
};

export default APRcalculatorStar;
