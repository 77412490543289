import Modal from "components/shared/Modal";
import { imageURL } from "config";
import React, { useState } from "react";

import {
  EffectFade,
  Thumbs,
  Navigation,
  Keyboard,
  Pagination,
  Zoom,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import "swiper/css/zoom";

const ModalCarousel = ({ isOpen, onClose, images = [], activeSlideIndex }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeScale, setActiveScale] = useState(0);

  return (
    <Modal show={isOpen} onHide={onClose} withCloseButton size="fullscreen">
      <Modal.Body isFullScreen>
        <div className="h-full flex flex-col px-2">
          <div className="flex-1 flex flex-col md:pt-10 items-center justify-center relative">
            <div className="w-full md:w-9/12 mx-auto">
              <Swiper
                className="w-full h-max"
                initialSlide={activeSlideIndex}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[
                  Thumbs,
                  EffectFade,
                  Zoom,
                  Keyboard,
                  Navigation,
                  Pagination,
                ]}
                keyboard={{ enabled: true }}
                navigation={{
                  nextEl: ".swiper-button-next-slide",
                  prevEl: ".swiper-button-prev-slide",
                  disabledClass: "swiper-button-disabled",
                }}
                pagination={{
                  el: ".pagination-thumb-swiper",
                  type: "custom",
                  renderCustom: function (swiper, current, total) {
                    return current + "/" + total;
                  },
                }}
                slidesPerView={1}
                onZoomChange={(swiper) => {
                  setActiveScale(swiper.zoom.scale);
                }}
                zoom={{ enabled: true }}
              >
                {images?.map((image) => {
                  const url = imageURL + image.name;
                  return (
                    <SwiperSlide key={image?.src ?? url} className="w-">
                      <div className="swiper-zoom-container">
                        <img
                          className={cn(
                            "w-full h-[340px] sm:h-[480px] lg:h-[700px] mb-8 object-contain mx-auto rounded-lg cursor-pointer",
                            activeScale === 3
                              ? "cursor-zoom-in"
                              : "cursor-zoom-out"
                          )}
                          src={image?.src ?? url}
                          alt={image?.name}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>

            <div className="mt-2 w-full flex justify-between">
              <div className="swiper-button-prev-slide z-40 inline-block md:absolute bottom-0 md:button-auto md:top-1/2 left-0 cursor-pointer">
                <ChevronLeftIcon className="text-white w-5 h-5" />
              </div>
              <div className="pagination-thumb-swiper md:flex md:absolute !h-5 md:!top-4 !text-white md:justify-start !bottom-1 md:!left-0"></div>
              <div className="swiper-button-next-slide z-40 inline-block md:absolute bottom-0 md:button-auto md:top-1/2 right-0 cursor-pointer">
                <ChevronRightIcon className="text-white w-5 h-5" />
              </div>
            </div>
          </div>

          <div className="mt-2 mb-1">
            <Swiper
              modules={[Thumbs, Pagination]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              className="mt-2"
              breakpoints={{
                0: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 8,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 12,
                  spaceBetween: 20,
                },
              }}
            >
              {images?.map((image) => {
                const url = imageURL + image.name;
                return (
                  <SwiperSlide
                    className="cursor-pointer"
                    key={image?.src ?? url}
                  >
                    <img
                      className="block w-full h-16 object-cover rounded-lg"
                      src={image?.src ?? url}
                      alt={image?.name}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCarousel;
