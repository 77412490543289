import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import axios from "axios";
import { baseURL } from "config";
import { SocketDataContext, UserDataContext } from "App";
import { findIndex, groupBy, maxBy, reduce, find } from "lodash";

export const ChatContext = createContext({});

const DEFAULT_CHAT_ROOMS = { rooms: [], loading: true, selected: null };
const ChatContextProvider = ({ children }) => {
	const [userData] = useContext(UserDataContext);
	const { socketData } = useContext(SocketDataContext);
	const [chatRooms, setChatRooms] = useState(DEFAULT_CHAT_ROOMS);

	const fetchChatData = useCallback(
		(item) => {
			if (!item) return;
			setChatRooms((prevState) => ({
				...prevState,
				loading: true,
				selected: item,
			}));

			///
			axios({
				method: "GET",
				url: baseURL + "/seller/messages/" + item.room_id + ",0",
				headers: {
					Authorization: userData.token,
				},
			}).then((response) => {
				let chatMessagesArray = response.data;
				chatMessagesArray.reverse();
				setChatRooms((prevState) => {
					const { rooms } = prevState;
					const newRooms = [...rooms];
					const roomIndex = newRooms.findIndex(
						(room) => room.roomData.room_id === item.room_id
					);

					if (roomIndex === -1) return prevState;
					newRooms[roomIndex].chatMessages = chatMessagesArray;

					return {
						...prevState,
						rooms: newRooms,
						loading: false,
					};
				});
			});
			///
		},
		[userData.token]
	);

	const fetchBidsAndRooms = useCallback(() => {
		if (!userData?.car) return; // stop no car is set

		const fetchBidsPromise = axios({
			method: "GET",
			url: baseURL + "/seller/bids",
			headers: {
				Authorization: userData.token,
			},
		});

		const fetchRoomsPromise = axios({
			method: "GET",
			url: baseURL + "/seller/rooms",
			headers: {
				Authorization: userData.token,
			},
		});

		Promise.all([fetchBidsPromise, fetchRoomsPromise])
			.then(([bidsResponse, roomsResponse]) => {
				const bids = bidsResponse.data;
				const rooms = roomsResponse.data;
				const urlParams = new URLSearchParams(window.location.search);
				const codePreSelected = urlParams.get("roomId");
				let preselectedRoom = null;
				if (codePreSelected) {
					preselectedRoom = find(rooms, (i) => i.room_id === parseInt(codePreSelected));
				} else {
					preselectedRoom = rooms[0];
				}

				const groupedBids = groupBy(bids, "dealer_name");
				const mergedData = rooms.reduce((acc, room) => {
					const dealerName = room.dealer_name;
					const bidsForDealer = groupedBids[dealerName] || [];
					acc.push({
						roomData: room,
						chatMessages: [],
						bids: bidsForDealer,
					});
					return acc;
				}, []);

				setChatRooms(() => ({
					rooms: mergedData,
					loading: false,
					selected: preselectedRoom || null,
				}));
			})
			.catch(() => {
				console.log("Error while fetching bids and rooms!");
			});
	}, [userData?.car, userData.token]);

	useEffect(() => {
		fetchBidsAndRooms();
	}, [fetchBidsAndRooms]);

	useEffect(() => {
		if (chatRooms.selected) {
			fetchChatData(chatRooms.selected);
		}
	}, [chatRooms.selected, fetchChatData]);

	const onNewMessage = useCallback((msg) => {
		if (!msg) return;
		if (msg.split("|").length < 2) return;
		const [, message, room_id] = msg.split("|");

		setChatRooms((prevChatRooms) => {
			const { rooms } = prevChatRooms;
			const newRooms = [...rooms];
			const roomIndex = findIndex(
				newRooms,
				(room) => room.roomData.room_id === parseInt(room_id)
			);

			if (roomIndex === -1) {
				return prevChatRooms;
			}

			const chatMessages = [...newRooms[roomIndex].chatMessages];
			const newMessage = {
				message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
				generated_local: true,
				message: message,
				message_date: new Date().toISOString(),
				file_name: null,
				you: 0,
			};

			newRooms[roomIndex] = {
				...newRooms[roomIndex],
				chatMessages: [...chatMessages, newMessage],
			};

			return {
				...prevChatRooms,
				rooms: newRooms,
			};
		});
	}, []);

	const onNewBid = useCallback((bid) => {
		if (bid.split("|").length < 2) return;
		const [bidId, value, dealerName] = bid.split("|");
		const newBid = {
			id: parseInt(bidId),
			created_date: new Date().toISOString(),
			dealer_name: dealerName,
			bid_amount: parseInt(value),
			generated_local: true,
		};

		const newMessage = {
			message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
			generated_local: true,
			message: `My bid is $${parseInt(value).toLocaleString() ?? "-"}`,
			message_date: new Date().toISOString(),
			file_name: null,
			you: 0,
		};

		setChatRooms((prevChatRooms) => {
			const { rooms } = prevChatRooms;
			const newRooms = [...rooms];
			const roomIndex = findIndex(newRooms, (room) => {
				return room.roomData.dealer_name === dealerName;
			});

			if (roomIndex === -1) return prevChatRooms;
			newRooms[roomIndex].bids.unshift(newBid);
			newRooms[roomIndex].chatMessages.push(newMessage);
			return {
				...prevChatRooms,
				rooms: newRooms,
			};
		});
	}, []);

	useEffect(() => {
		if (!socketData?.socket) return;

		socketData.socket.on("new-message", onNewMessage);
		socketData.socket.on("new-bid", onNewBid);

		return () => {
			socketData.socket.off("new-message", onNewMessage);
			socketData.socket.off("new-bid", onNewBid);
		};
	}, [onNewBid, onNewMessage, socketData.socket]);

	return (
		<ChatContext.Provider value={{ chatRooms, setChatRooms }}>
			{children}
		</ChatContext.Provider>
	);
};

export default ChatContextProvider;
