import React, { useContext } from "react";
import ChooseTypeAdd from "./ChooseTypeAdd";
import PathVin from "./PathVin";
import PathPlate from "./PathPlate";
import PathManual from "./PathManual";
import { ContextForm } from "../contexts/FormContext";
import ExtendCarDeatils from "./ExtendCarDeatils";
import Loading from "../../Loading";
import Modal from "components/shared/Modal";

const ModalAddCar = ({ handleClose, show }) => {
  const { formRegister, resetFormRegister } = useContext(ContextForm);

  function beforeCloseModal() {
    handleClose();
    resetFormRegister(); // reset state formRegister
  }

  return (
    <Modal show={show} onHide={beforeCloseModal} size="md">
      <Modal.Header closeButton>
        <Modal.Title>List your car</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!formRegister.loading ? (
          <>
            {formRegister.stepType === "choose" && <ChooseTypeAdd />}

            {formRegister.stepType === "vin" && <PathVin />}
            {formRegister.stepType === "plate" && <PathPlate />}
            {formRegister.stepType === "manual" && <PathManual />}

            {formRegister.stepType === "detail" && <ExtendCarDeatils />}
          </>
        ) : (
          <Loading>Loading....</Loading>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddCar;
