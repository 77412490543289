/* eslint-disable */
export function validateEmail(emailAdress)
{
  let regexEmail = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailAdress.match(regexEmail)) {
    return true; 
  } else {
    return false; 
  }
}


/*
USA Phone Number Validation

(123) 456-7890
(123)456-7890
123-456-7890
1234567890
*/


export function validateUSAPhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(input_str);
}