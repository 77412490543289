import React, { useContext, useState } from "react";
import { UserDataContext } from "App";
import axios from "axios";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import Modal from "components/shared/Modal";
import Select from "components/shared/Select";
import Textarea from "components/shared/Textarea";
import { baseURL } from "config";
import { toast } from "react-toastify";
import { CAR_CONDITIONS } from "constants";

const ModalVehicleDetails = ({
  defaultDetails,
  isOpen,
  onSave = () => {},
  onClose,
}) => {
  const [userData] = useContext(UserDataContext);
  const [isErrorVin, setIsErrorVin] = useState(false);
  const [form, setForm] = useState({ ...defaultDetails });

  const updateDetails = () => {
    const detailsParams = new URLSearchParams();
    detailsParams.append("mileage", form.mileage);
    detailsParams.append("condition_id", form.condition_id);
    detailsParams.append("notes", form.notes ?? "");
    detailsParams.append("asking_price", form.asking_price);

    const vinParams = new URLSearchParams();
    vinParams.append("vin", form.vin);
    vinParams.append("mileage", form.mileage);
    vinParams.append("zip_code", userData.zip_code);

    setIsErrorVin(false);

    Promise.all([
      axios({
        method: "POST",
        url: baseURL + "/seller/car/details",
        headers: {
          Authorization: userData.token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: detailsParams,
      }),
      axios({
        method: "POST",
        url: baseURL + "/seller/vin-kbb",
        headers: {
          Authorization: userData.token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: vinParams,
      }),
    ])
      .then((responses) => {
        const detailsResponse = responses[0];
        const vinResponse = responses[1];

        if (
          detailsResponse.data.success === 1 &&
          vinResponse.data.success === 1
        ) {
          onSave();
          toast.success("Successfully updated details and VIN");
        } else if (detailsResponse.data.success === 0) {
          toast.error("Failed to update details");
        } else {
          setIsErrorVin(true);
          toast.error("VIN not found!");
        }
      })
      .catch((error) => {
        toast.error("Failed to update details");
      });
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="sm" withCloseButton>
      <Modal.Header>
        <Modal.Title>Vehicle Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="file-uploader-container mt-4">
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <Input
                  value={form.mileage}
                  onChange={(e) => {
                    setForm((p) => {
                      return { ...p, mileage: parseInt(e.target.value) || 0 };
                    });
                  }}
                  label="Current Mileage"
                  placeholder="Current Mileage"
                />
              </div>

              <div className="w-1/2">
                <Input
                  value={form.vin ?? ''}
                  onChange={(e) => {
                    setForm((p) => {
                      return { ...p, vin: e.target.value };
                    });
                  }}
                  isInvalid={isErrorVin}
                  errorText="VIN not found!"
                  label={<b className="text-primary">VIN Number</b>}
                  placeholder="VIN Number"
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <Select
                  id="condition_id"
                  name="condition_id"
                  label="Car condition"
                  placeholder="Car condition"
                  labelOptions={CAR_CONDITIONS}
                  value={form.condition_id ?? ''}
                  valueOptions={[1, 2, 3]}
                  onChange={(e) => {
                    setForm((p) => {
                      return {
                        ...p,
                        condition_id: parseInt(e.target.value) || 0,
                      };
                    });
                  }}
                />
              </div>
              <div className="w-1/2">
                <Input
                  value={
                    form.asking_price === 0
                      ? ""
                      : form.asking_price.toLocaleString("en-US") ?? ''
                  }
                  onChange={(e) => {
                    setForm((p) => {
                      return {
                        ...p,
                        asking_price: e.target.value || 0,
                      };
                    });
                  }}
                  label="Asking Price"
                  placeholder="What do you think your car is worth? (optional)"
                  icon={<span className="text-primary">$</span>}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full">
                <Textarea
                  rows={2}
                  value={form.notes ?? ''}
                  onChange={(e) => {
                    setForm((p) => {
                      return { ...p, notes: e.target.value };
                    });
                  }}
                  label="Seller Notes"
                  placeholder="Seller Notes"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="mt-3 bg-primary text-white capitalize hover:text-white hover:bg-[#007cb9] text-lg rounded flex w-fit px-4 py-3 font-bold"
          onClick={() => {
            updateDetails();
          }}
        >
          Save Updates
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVehicleDetails;
