import { createContext, useState } from "react";

export const RegisterContext = createContext();

const RegisterContextProvider = ({ children }) => {
  const stepKeys = ["step-vin-v3", "step-manual-v2", "plate-step-v2"];
  const stepValues = stepKeys.map((key) =>
    JSON.parse(window.localStorage?.getItem(key))
  );

  const [isInRegisterProcess, setIsInRegisterProcess] = useState(() => {
    return stepValues.some((step) => step?.step === 1);
  });

  return (
    <RegisterContext.Provider
      value={[isInRegisterProcess, setIsInRegisterProcess]}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContextProvider;
