import React from "react";
import { useEffect, useContext, useState } from "react";
import { UserDataContext } from "App";
import { fetchAllUsers } from "components/negotiations/request/fetchallUsers";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Button from "components/shared/Button";
import Loading from "components/Loading";

const RowNegotiation = (props) => {
  const navigate = useNavigate();
  const { rowIndex, ...item } = props;
  let { photo, code, price, title } = item;
  return (
    <div
      onClick={() => navigate("/negotiations?code=" + code)}
      className="flex-col cursor-pointer justify-between p-2 xl:px-4 xl:py-2 mb-3"
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <img alt="car_icon" src={photo} className="h-8 w-8 rounded-full" />
          <span className={cn("text-primary text-base font-normal")}>
            {title}
          </span>
        </div>
        <span className={cn("text-primary text-base font-normal")}>
          ${price?.toLocaleString()}
        </span>
      </div>
    </div>
  );
};

const OutgoingOffersCard = () => {
  const navigate = useNavigate();
  const [userData] = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [negotiationsList, setNegotiationsList] = useState([]);
  const [toggleNegotationChat, setToggleNegotationChat] = useState(true);

  useEffect(() => {
    fetchAllUsers(userData.token, responseFetchAllUsers);
  }, [userData.token]);

  function responseFetchAllUsers(error, data) {
    if (error) {
      throw error;
    }
    setNegotiationsList(data);
    setIsLoading(false);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="my-3 rounded shadow bg-white">
      <div className="flex justify-between px-4 py-6 xl:px-4 xl:py-6 gap-3 items-center">
        <p className="font-bold mb-0 text-base text-primary ">
          Outgoing Offers
        </p>
        <div className="flex">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            className="text-primary disabled:cursor-not-allowed xl:hidden"
            onClick={() => setToggleNegotationChat(!toggleNegotationChat)}
            type="button"
          >
            {toggleNegotationChat ? (
              <ChevronUpIcon className="w-6 h-6 text-primary" />
            ) : (
              <ChevronDownIcon className="w-6 h-6 text-primary" />
            )}
          </button>
        </div>
      </div>
      <div
        id="dropdown"
        className={cn("z-10 w-full xl:pb-6", {
          hidden: toggleNegotationChat === false,
        })}
      >
        {negotiationsList.length > 0 ? (
          <div className="flex-col justify-between bg-white">
            {negotiationsList.slice(0, 7).map((item, index) => {
              return <RowNegotiation {...item} rowIndex={index} key={index} />;
            })}
            <div className="mx-auto flex justify-center text-center mb-3 w-full xl:w-[90%]">
              <Button
                variant="primary"
                label="See All"
                className="w-full"
                onClick={() => navigate("/negotiations")}
              />
            </div>
          </div>
        ) : (
          <div className="flex-col justify-between bg-white">
            <div className="h-12 xl:h-64 flex justify-center items-center text-neutral">
              No Outgoing Offers Found!
            </div>
            <div className="mx-auto flex justify-center text-center mb-3 w-full xl:w-[90%]">
              <Button
                variant="primary"
                label="Buy a Car"
                className="w-full"
                onClick={() => navigate("/car-search")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutgoingOffersCard;
