import { useContext, useEffect } from "react";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import Button from "components/shared/Button";
import { UserDataContext } from "App";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";

const TradeByType = () => {
	const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const vehiclePhotos = modalCar?.photos?.split(",")[0];

	useEffect(() => {
		dispatchModalCar({
			type: "set-action",
			payload: {
				backButton: function () {
					dispatchModalCar({
						type: "update",
						payload: {
							currentModal: "secondaryModal",
							currentSubModal: "calculator",
						},
					});
				},
			},
		});
		return () => {
			dispatchModalCar({
				type: "set-action",
				payload: {
					backButton: null,
				},
			});
		};
	}, []);

	function handleVinNumberSelected() {
		dispatchModalCar({
			type: "update",
			payload: {
				currentSubModal: "tradeByVin",
			},
		});
	}

	function handleLicensePlateSelected() {
		dispatchModalCar({
			type: "update",
			payload: {
				currentSubModal: "tradeByLicensePlate",
			},
		});
	}
	function handleManualSelected() {
		dispatchModalCar({
			type: "update",
			payload: {
				currentSubModal: "tradeByManual",
			},
		});
	}

	// return <SubModalVinNumberSetter/>
	return (
		<div className="flex w-full">
			<div className="relative w-1/2 before:absolute before:w-[1px] before:h-1/2 before:right-0 before:top-1/4 before:bg-neutral-light">
				<div className="flex flex-row gap-2">
					<div className="w-5/12">
						<img
							className="rounded-lg h-[180px] object-cover"
							src={vehiclePhotos}
              alt={modalCar.title}
						/>
					</div>
					<div className="w-7/12 flex flex-col space-y-4">
						<span className="text-xs w-fit px-2 py-1 bg-neutral">
							{modalCar?.year}
						</span>
						<span className="text-[22px] text-primary-light">
							{modalCar?.title}
						</span>
						<span className="flex gap-2 text-danger text-sm items-center">
							<MapPinIcon className="w-4 h-4" />
							{modalCar?.location}
						</span>
						<span className="flex gap-2 text-danger text-sm items-center">
							<PhoneIcon className="w-4 h-4" />
							{modalCar?.phone}
						</span>
						<span className="text-danger text-3xl font-bold">
							${modalCar?.price?.toLocaleString()}
						</span>
					</div>
				</div>
			</div>
			<div className="w-1/2 rounded-lg mt-4 p-4">
				<div className="flex flex-col">
					<Button
						onClick={handleVinNumberSelected}
						variant="primary"
						className="my-2"
						label="VIN Number"
					/>
					<Button
						onClick={handleLicensePlateSelected}
						variant="primary"
						className="my-2"
						label="License Plate"
					/>
					<Button
						onClick={handleManualSelected}
						variant="primary"
						className="my-2"
						label="Manual"
					/>
					<div className="flex justify-center">
						<div
							onClick={modalCar?.actions?.backButton}
							className="back-btn-tri"
						>
							Go Back
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeByType;
