import { useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { UserDataContext } from "App";
import { BellIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const NotificationsDropdown = () => {
  const navigate = useNavigate();
  const [userData] = useContext(UserDataContext);
  const dropdownRef = useRef(null);
  const [accountPopover, setAccountPopover] = useState(false);
  const notifications =
    userData?.statusNotification
      ?.filter((notification) => notification.type === "bid")
      .reduce((uniqueNotifications, notification) => {
        if (
          !uniqueNotifications.some(
            (uniqueNotification) =>
              uniqueNotification.name === notification.name
          )
        ) {
          uniqueNotifications.push(notification);
        }
        return uniqueNotifications;
      }, []) ?? [];

  useEffect(() => {
    if (!dropdownRef.current) return;

    const handleOutsideClick = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setAccountPopover(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="notificationsDropdownButton"
        data-dropdown-toggle="dropdownAvatar"
        className={cn(
          "flex text-sm rounded-full md:mr-0 p-2 border-2 border-primary ease-in duration-300 hover:bg-primary hover:text-white",
          accountPopover ? "bg-primary text-white" : "text-primary"
        )}
        type="button"
        onClick={() => setAccountPopover(!accountPopover)}
      >
        <span className="sr-only">Open user menu</span>
        <BellIcon className="h-4 w-4 lg:w-5 lg:h-5" />
        {notifications.length > 0 && (
          <span className="absolute bottom-3 left-[55%] w-3 h-3 text-[6px] bg-secondary rounded-full flex items-center justify-center text-white">
            {notifications.length}
          </span>
        )}
      </button>
      <div
        id="dropdownAvatar"
        className={`${
          accountPopover === false ? "hidden" : "absolute"
        } z-10 bg-white rounded shadow w-56 left-[-100%] mt-1 md:right-0 md:left-auto border-t-2 border-primary-light`}
      >
        <ul
          className="py-2 text-xl"
          aria-labelledby="notificationsDropdownButton"
        >
          {notifications.length === 0 && (
            <li className="px-4 py-6 text-neutral text-center text-sm">
              No notifications.
            </li>
          )}

          {notifications.map((item, i) => (
            <li key={i} className="px-4 py-2 last-of-type:rounded-b">
              <div
                role="button"
                className="cursor-pointer hover:text-primary text-sm"
                onClick={() => {
                  navigate("/messages");
                  setAccountPopover(false);
                }}
              >
                <strong>{item.name}</strong> has bidded{" "}
                <strong className="text-success">${item.value}</strong>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotificationsDropdown;
