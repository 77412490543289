import React from "react";

export const SkeletonSideFilters = () => {
  return (
    <div className="animate-pulse">
      <div className="flex justify-end">
        <div className="show-on-mobile cursor-pointer"></div>
      </div>
      <div className="overflow-y-auto h-full md:overflow-y-hidden flex flex-col space-y-3 pb-24">
        <div className="flex gap-1 border-b h-[52px] border-neutral-light">
          <span className="w-1/3 my-3 text-base bg-neutral dark:bg-neutral-light text-[#ADA7A7] font-medium text-center py-3"></span>
          <span className="w-1/3 my-3 text-base bg-neutral dark:bg-neutral-light text-[#ADA7A7] font-medium text-center py-3"></span>
          <span className="w-1/3 my-3 text-base bg-neutral dark:bg-neutral-light text-[#ADA7A7] font-medium text-center py-3"></span>
        </div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="h-12 rounded-[10px] w-full bg-neutral dark:bg-neutral-light"></div>
        <div className="my-3">
          <div className="mb-2 h-[18px] w-1/3 bg-neutral dark:bg-neutral-light"></div>
          <div>
            <div className="bg-neutral dark:bg-neutral-light h-[22px]" />
          </div>
          <div className="flex justify-between mt-2 text-slider-output-filter">
            <div className="bg-neutral dark:bg-neutral-light w-5 h-[22px]"></div>
            <div className="bg-neutral dark:bg-neutral-light w-[75px] h-[22px]"></div>
          </div>
        </div>
        <div className="my-3">
          <div className="mb-2 h-[18px] w-1/3 bg-neutral dark:bg-neutral-light"></div>
          <div>
            <div className="bg-neutral dark:bg-neutral-light h-[22px]" />
          </div>
          <div className="flex justify-between mt-2 text-slider-output-filter">
            <div className="bg-neutral dark:bg-neutral-light w-5 h-[22px]"></div>
            <div className="bg-neutral dark:bg-neutral-light w-[75px] h-[22px]"></div>
          </div>
        </div>
        <div className="my-3">
          <div className="mb-2 h-[18px] w-1/3 bg-neutral dark:bg-neutral-light"></div>
          <div>
            <div className="bg-neutral dark:bg-neutral-light h-[22px]" />
          </div>
          <div className="flex justify-between mt-2 text-slider-output-filter">
            <div className="bg-neutral dark:bg-neutral-light w-5 h-[22px]"></div>
            <div className="bg-neutral dark:bg-neutral-light w-[75px] h-[22px]"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center md:justify-center fixed bottom-0 right-0 left-0 md:relative z-40 bg-white md:bg-none shadow md:shadow-none py-4 px-4 md:p-0 mt-2"></div>
    </div>
  );
};
