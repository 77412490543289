import React from "react";
import Modal from "./shared/Modal";

const VinLocation = ({ setShow, show }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div>
          A VIN, or vehicle identification number, is a unique identifying code
          given to a vehicle when it's manufactured. Often found on the driver's
          side dashboard or door jamb.
        </div>
        <img alt="" className="find-vin-graphic" src="/images/vin.png" />
      </Modal.Body>
    </Modal>
  );
};

export default VinLocation;
