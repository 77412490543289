/* eslint-disable */
export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = String(d).padStart(2, "0") + (d === 1 ? " day " : " days ");
  var hDisplay = String(h).padStart(2, "0") + (h === 1 ? " hour " : " hours ");
  var mDisplay =
    String(m).padStart(2, "0") + (m === 1 ? " minute " : " minutes ");
  var sDisplay =
    String(s).padStart(2, "0") + (s === 1 ? " second" : " seconds");
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function secondsToDhmsArray(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);
  return [d, h, m, s, seconds]; // day hours minutes seconds and raw seconds
}
