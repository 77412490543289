import Button from "components/shared/Button";
import Input from "components/shared/Input";
import Modal from "components/shared/Modal";
import React, { useState } from "react";

const ModalVin = ({ isOpen, defaultValue, onSave, onClose }) => {
  const [value, setValue] = useState(defaultValue ?? "");

  return (
    <Modal show={isOpen} onHide={onClose} size="sm" withCloseButton>
      <Modal.Header>
        <Modal.Title>Add VIN number</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          invalid={"Invalid VIN number"}
          label="Vin Number"
          placeholder="Enter Vin Number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSave(value)}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVin;
