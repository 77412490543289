export const formatMapper = {
  phoneNumber: (event) => {
    let val = event.target.value.replace(/\D/g, "");
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;

    let formattedVal = "";
    if (val.length > 0) {
      formattedVal = `(${val.substring(0, 3)}`;
    }
    if (val.length > 3) {
      formattedVal += `) ${val.substring(3, 6)}`;
    }
    if (val.length > 6) {
      formattedVal += `-${val.substring(6, 10)}`;
    }
    event.target.setSelectionRange(start, end);
    return formattedVal;
  },
  Number: (event) => {
    let val = event.target.value.replace(/\D/g, "");
    return val;
  },
};
