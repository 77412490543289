import { forwardRef } from "react";
import cn from "classnames";

const Textarea = forwardRef(
  (
    {
      label,
      placeholder,
      children,
      className,
      isInvalid,
      errorText,
      ...otherAttributes
    },
    ref
  ) => {
    const id = Math.random().toString(36).substring(2, 15);

    return (
      <div className="relative">
        {label && (
          <label
            htmlFor={id}
            className="flex text-md font-normal text-primary mb-1"
          >
            {label}
          </label>
        )}
        <textarea
          {...otherAttributes}
          ref={ref}
          id={id}
          placeholder={placeholder}
          className={cn(
            `block pt-3 pb-2.5 pl-4 pr-4 w-full text-md text-primary font-normal bg-neutral-light appearance-none focus:outline-none focus:ring-1 focus:border-primary peer`,
            {
              "border border-danger": isInvalid,
              "border-0": !isInvalid,
            },
            className
          )}
        />
        {isInvalid && (
          <div className="absolute -bottom-5 pt-1 text-danger">
            <span className="text-sm">{errorText}</span>
          </div>
        )}
      </div>
    );
  }
);

export default Textarea;
