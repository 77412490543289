import Card from "components/shared/Card";
import React from "react";
import Button from "components/shared/Button";
import { CheckCircleIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import ImagesHandler from "components/ImagesHandler";
import cn from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import useModal from "hooks/use-modal.hook";
import ModalVehicleDetails from "components/ModalVehicleDetails";
import ModalPhotosUploader from "components/ModalPhotosUploader";

const MainOverview = ({
  carDetails,
  carImages,
  onRefetchDetails = () => {},
  onRefetchImages = () => {},
}) => {
  const vinModal = useModal();
  const vehicleImagesModal = useModal();

  const primaryDetails = [
    {
      id: 1,
      title: "Mileage",
      value: carDetails?.mileage?.toLocaleString() ?? "-",
    },
    // NOTE: Need to ask Wojo about these fields ?
    // { id: 2, title: "Stock#", value: carDetails?.stock_number ?? "-" },
    // {
    //   id: 3,
    //   title: "Exterior Color",
    //   value: carDetails?.exterior_color ?? "-",
    // },
    // {
    //   id: 4,
    //   title: "Interior Color",
    //   value: carDetails?.interior_color ?? "-",
    // },
    // { id: 5, title: "MPG City", value: carDetails?.mpg_city ?? "-" },
    // { id: 6, title: "MPG Highway", value: carDetails?.mpg_highway ?? "-" },
    // { id: 7, title: "Fuel", value: carDetails?.fuel ?? "-" },
    // { id: 8, title: "Make", value: carDetails?.make_name ?? "-" },
    // { id: 9, title: "Model", value: carDetails?.model_name ?? "-" },
    // { id: 10, title: "Trim", value: carDetails?.trim_name ?? "-" },
    { id: 11, title: "Condition", value: carDetails?.condition_name ?? "-" },
    {
      id: 12,
      title: "Asking Price",
      value: "$" + carDetails?.asking_price?.toLocaleString() ?? "-",
    },
    { id: 13, title: "Seller Notes", value: carDetails?.notes ?? "-" },
  ];

  return (
    <Card className="flex flex-col">
      <div className="flex justify-end xl:justify-between items-start">
        <h1 className="hidden xl:block text-md xl:text-3xl font-bold text-primary">
          {carDetails?.title}
        </h1>
        <Button variant="none" onClick={() => vehicleImagesModal.openModal()}>
          <PencilSquareIcon className="h-6 w-6" />
        </Button>
      </div>
      <div>
        {carImages?.listImages.length === 0 ? (
          <div className="flex items-center flex-col justify-center w-full h-72 bg-[#D3D3D3CC] sm:h-80 md:h-[24rem] xl:h-[27.75rem] rounded-lg mb-3 md:mb-7">
            <img
              className="w-32 md:w-auto"
              src="/images/image_placeholder.png"
              alt="placeholder"
            />
            <Button
              variant="primary"
              label="Upload Images"
              className="!text-base"
              onClick={() => vehicleImagesModal.openModal()}
            />
          </div>
        ) : (
          <ImagesHandler
            isLoading={carImages?.isLoading}
            cover={carImages?.coverPhoto}
            images={carImages?.listImages}
          />
        )}
      </div>

      <div className="flex flex-col">
        <div className="pb-2 flex-1 flex justify-between items-center border-b border-primary">
          <h3 className="text-primary text-lg xl:text-2xl font-bold">
            Vehicle Reports
          </h3>
          {!carDetails?.vin ? null : (
            <span
              className={cn(
                "flex items-center text-xs xl:text-lg font-bold",
                carDetails?.vin ? "text-success" : "text-danger"
              )}
            >
              <CheckCircleIcon className="w-5 h-5 xl:h-6 xl:w-6 mr-2" />
              VIN: {carDetails?.vin}
            </span>
          )}
        </div>

        <div className="relative">
          {carDetails?.vin ? null : (
            <div className="absolute inset-0 z-[3] flex flex-col xl:flex-row items-center xl:justify-between py-4 px-4">
              <span className="xl:w-2/4 flex flex-col xl:flex-row text-center items-center text-lg xl:text-2xl font-normal text-danger my-4 xl:my-auto">
                <ExclamationCircleIcon className="h-12 w-12 xl:h-24 xl:w-24 xl:mr-4" />
                Please add VIN Number to see vehicle reports
              </span>

              <Button
                variant="secondary"
                className="text-base"
                onClick={() => vinModal.openModal()}
              >
                Add VIN
              </Button>
            </div>
          )}
          {carDetails?.autocheck_accidents === null ? null : (
            <div
              className={cn(
                "flex flex-col xl:flex-row xl:gap-4 items-center text-center p-3 xl:p-6 my-2 xl:my-4",
                {
                  blur: !carDetails?.vin,
                }
              )}
            >
              <div className="w-[100px] mr-3">
                <img
                  className="max-w-full object-contain mr-2 object-top"
                  src="/images/autocheck.png"
                  alt="autocheck_logo"
                />
              </div>
              <div className="text-primary mt-1 xl:mt-4">
                <h4 className="text-sm xl:text-lg font-bold tracking-[0.3px]">
                  AutoCheck Vehicle History Reports
                </h4>
                <div className="flex xl:flex-col gap-8 xl:gap-0 justify-center">
                  <div className="flex flex-col xl:flex-row items-center">
                    <span className="text-sm xl:text-md font-normal xl:mr-2">
                      Accidents:
                    </span>
                    <span
                      className={cn(
                        "text-sm xl:text-md font-bold",
                        carDetails?.autocheck_accidents &&
                          carDetails?.autocheck_accidents > 0
                          ? "text-danger"
                          : "text-success"
                      )}
                    >
                      {carDetails?.autocheck_accidents ?? "-"}
                    </span>
                  </div>
                  <div className="flex flex-col xl:flex-row items-center">
                    <span className="text-sm xl:text-md font-normal xl:mr-2">
                      Owners:
                    </span>
                    <span className="text-sm xl:text-md font-bold">
                      {carDetails?.autocheck_owners}
                    </span>
                  </div>
                  <div className="flex flex-col xl:flex-row items-center">
                    <span className="text-sm xl:text-md font-normal xl:mr-2">
                      Title:
                    </span>
                    <span
                      className={cn("text-sm xl:text-md font-bold", {
                        "text-success": carDetails?.autocheck_assured === "Y",
                        "text-danger": carDetails?.autocheck_assured !== "Y",
                      })}
                    >
                      {carDetails?.autocheck_assured === "Y"
                        ? "No Issues"
                        : "Issues"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={cn(
              "flex flex-col xl:flex-row xl:gap-4 items-center text-cente rp-3 xl:p-6 my-2 xl:my-4",
              {
                blur: !carDetails?.vin,
              }
            )}
          >
            <div className="w-[100px] mr-3">
              <img
                className="max-w-full object-contain mr-2 object-top"
                src="/images/register/jd-power.svg"
                alt="jdpower_logo"
              />
            </div>
            <div className="text-primary mt-1 xl:mt-4">
              <h4 className="text-sm xl:text-lg font-bold tracking-[0.3px]">
                JD Power Trade In Value
              </h4>
              <div className="flex flex-col xl:flex-row items-center">
                <span className="text-sm xl:text-md font-normal xl:mr-2">
                  Value:
                </span>
                <span className="text-sm xl:text-md font-bold">
                  {carDetails?.jdpower_value
                    ? `$${carDetails?.jdpower_value?.toLocaleString()}`
                    : "Not Available"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="pb-2 flex-1 flex justify-between items-center">
          <h3 className="text-primary text-lg xl:text-2xl font-bold">
            Primary Details
          </h3>
          <Button
            variant="none"
            onClick={() => vinModal.openModal()}
            className="p-0"
          >
            <PencilSquareIcon className="h-6 w-6" />
          </Button>
        </div>
        <div className="grid grid-cols-1 grid-rows-auto">
          {primaryDetails.map((detail) => (
            <div
              key={detail.id}
              className="flex justify-between border-t border-[#08315159] py-2"
            >
              <span className="text-primary text-sm font-medium shrink-0 mr-10">
                {detail.title}
              </span>
              <span className="flex items-center text-xs text-neutral-dark text-justify">
                {detail.value}
              </span>
            </div>
          ))}
        </div>
      </div>

      {vinModal.isOpen && (
        <ModalVehicleDetails
          isOpen={vinModal.isOpen}
          defaultValue={carDetails?.vin}
          defaultDetails={carDetails}
          onClose={() => {
            vinModal.closeModal();
          }}
          onSave={() => {
            vinModal.closeModal();
            onRefetchDetails();
          }}
        />
      )}

      {vehicleImagesModal.isOpen && (
        <ModalPhotosUploader
          isOpen={vehicleImagesModal.isOpen}
          images={carImages?.listImages}
          onClose={() => {
            vehicleImagesModal.closeModal();
            onRefetchImages();
          }}
        />
      )}
    </Card>
  );
};

export default MainOverview;
