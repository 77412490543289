import {useState,useEffect,memo} from 'react'
import rangeSlider from "range-slider-input";
import "range-slider-input/dist/style.css";
import { useRef } from 'react';


const DualRangeSliderInput = ({label,min,max,step,values,format,initChangeTrig,onValueChange}) => {
    label ??= "Label";
    min ??= 0;
    max ??= 100;
    step ??= 1;
    initChangeTrig ??= true;
    values ??= {min:min,max:max};
    //console.log("values:",values);
    onValueChange ??= ()=>{};
    format ??= (value)=>value;
    const sliderRef = useRef(null);
    const  refSliderState = useRef(null);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    useEffect(() => {
     const rangeSliderElement =  rangeSlider(sliderRef.current, {
        step: step,
        min:min,
        max:max,
        value:[values.min,values.max],
        onInput: onInputChange
      });
      //rangeSliderElement.value([values.min,values.max]);
      refSliderState.current = rangeSliderElement;
      if(initChangeTrig){
        onInputChange([values.min,values.max]);
      }
    }, []);

    useEffect(() => {
        if(refSliderState.current){
            refSliderState.current.value([values.min,values.max]);
        }
    }, [values.min,values.max]);


    function onInputChange([minValueNEW, maxValueNEW]){
        setMinValue(minValueNEW);
        setMaxValue(maxValueNEW);
        onValueChange(minValueNEW,maxValueNEW);
    }
    return (
      <div className='my-3'>
           <div style={{color:"#94A7CB",letterSpacing:"1px",fontSize:"12px"}} className='mb-2 fw-bold'>{label}</div>
        <div ref={sliderRef} className="range-slider smrange">
          <input type="range" />
          <input type="range" />
          <div className="range-slider__thumb" data-lower></div>
          <div className="range-slider__thumb" data-upper></div>
          <div className="range-slider__range"></div>
        </div>
        <div className="result-of-range-slider flex justify-between mt-2 text-slider-output-filter">
            <div>{format(minValue)}</div><div>{format(maxValue)}</div>
        </div>
      </div>
    )
}

export default memo(DualRangeSliderInput)  