export const SWIPER_CONFIG = {
  style: {
    "--swiper-navigation-color": "#fff",
    "--swiper-navigation-size": "24px",
  },
  breakpoints: {
    0: {
      slidesPerView: 6,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
  },
};
