import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import axios from "axios";
import { baseURL } from "config";
import { SocketDataContext, UserDataContext } from "App";
import { find, findIndex, reduce } from "lodash";

export const NegotiationsContext = createContext({});

const DEFAULT_CHAT_ROOMS = { rooms: [], loading: true, selected: null };
const NegotiationsContextProvider = ({ children }) => {
	const [userData] = useContext(UserDataContext);
	const { socketData } = useContext(SocketDataContext);
	const [chatRooms, setChatRooms] = useState(DEFAULT_CHAT_ROOMS);

	const fetchNegotiationPerRoom = useCallback(
		async (room) => {
			if (!room) return;
			const response = await axios({
				method: "GET",
				url: baseURL + "/seller/negotiation/" + room.code,
				headers: {
					Authorization: userData.token,
				},
			});
			const negotiation = response.data;
			return negotiation;
		},
		[userData.token]
	);

	const fetchChatData = useCallback(
		(item) => {
			if (!item) return;
			setChatRooms((prevState) => ({
				...prevState,
				loading: true,
				selected: item,
			}));

			axios({
				method: "GET",
				url: baseURL + "/seller/messages/" + item.room_id + ",0",
				headers: {
					Authorization: userData.token,
				},
			}).then(async (response) => {
				let chatMessagesArray = response.data;
				chatMessagesArray.reverse();
				const negotiation = await fetchNegotiationPerRoom(item);

				setChatRooms((prevState) => {
					const { rooms } = prevState;
					const newRooms = [...rooms];
					const roomIndex = newRooms.findIndex(
						(room) => room.roomData.room_id === item.room_id
					);

					if (roomIndex === -1) return prevState;
					newRooms[roomIndex].chatMessages = chatMessagesArray;

					if (negotiation) {
						newRooms[roomIndex].negotiation = negotiation;
					}

					return {
						...prevState,
						rooms: newRooms,
						loading: false,
					};
				});
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userData.token]
	);

	const fetchRooms = useCallback(() => {
		const fetchRoomsPromise = axios({
			method: "GET",
			url: baseURL + "/seller/negotiations",
			headers: {
				Authorization: userData.token,
			},
		});

		Promise.all([fetchRoomsPromise])
			.then(async ([roomsResponse]) => {
				const rooms = roomsResponse.data?.map((room) => ({
					roomData: room,
					chatMessages: [],
					negotiation: null,
				}));

				const urlParams = new URLSearchParams(window.location.search);
				const codePreSelected = urlParams.get("code");
				let preselectedRoom = null;
				if (codePreSelected) {
					preselectedRoom = find(
						rooms,
						(i) => i.roomData.code === codePreSelected
					)?.roomData;
				} else {
					preselectedRoom = reduce(
						rooms,
						(a, b) =>
							new Date(a?.roomData?.created_date) >
							new Date(b?.roomData?.created_date)
								? a
								: b,
						null
					)?.roomData;
				}

				let negotiation = null;
				if (preselectedRoom) {
					negotiation = await fetchNegotiationPerRoom(preselectedRoom);
				}

				setChatRooms(() => ({
					rooms: negotiation
						? rooms?.map((room) => {
								if (room.roomData.code === negotiation.code) {
									return {
										...room,
										negotiation,
									};
								}
								return room;
						  })
						: [...rooms],
					loading: false,
					selected: preselectedRoom || null,
				}));
			})
			.catch(() => {
				console.log("Error while fetching negotiations!");
			});
	}, [fetchNegotiationPerRoom, userData.token]);

	useEffect(() => {
		fetchRooms();
	}, [fetchRooms]);

	useEffect(() => {
		if (chatRooms.selected) {
			fetchChatData(chatRooms.selected);
		}
	}, [chatRooms.selected, fetchChatData]);

	const onNewTradeCounterOffer = useCallback(() => {
		fetchRooms();
	}, [fetchRooms]);

	const onNewMessage = useCallback((msg) => {
		if (!msg) return;
		if (msg.split("|").length < 2) return;
		const [, message, room_id] = msg.split("|");

		setChatRooms((prevChatRooms) => {
			const { rooms } = prevChatRooms;
			const newRooms = [...rooms];
			const roomIndex = findIndex(
				newRooms,
				(room) => room.roomData.room_id === parseInt(room_id)
			);

			if (roomIndex === -1) {
				return prevChatRooms;
			}

			const chatMessages = [...newRooms[roomIndex].chatMessages];
			const newMessage = {
				message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
				generated_local: true,
				message: message,
				message_date: new Date().toISOString(),
				file_name: null,
				you: 0,
			};

			newRooms[roomIndex] = {
				...newRooms[roomIndex],
				chatMessages: [...chatMessages, newMessage],
			};

			return {
				...prevChatRooms,
				rooms: newRooms,
			};
		});
	}, []);

	const onNewNumber = useCallback(
		(msg) => {
			const split = msg?.split("|") ?? []; // code|dealer_name
			if (split.length < 2) return; // if not valid
			const code = split[0];
			if (chatRooms.selected?.code === code) {
				fetchRooms();
			}
		},
		[chatRooms.selected?.code, fetchRooms]
	);

	useEffect(() => {
		if (!socketData?.socket) return;

		socketData.socket.on("new-message", onNewMessage);
		socketData.socket.on("new-numbers", onNewNumber);
		socketData.socket.on("trade-counter-offer", onNewTradeCounterOffer);

		return () => {
			socketData.socket.off("new-message", onNewMessage);
			socketData.socket.off("new-numbers", onNewNumber);
			socketData.socket.off("trade-counter-offer", onNewTradeCounterOffer);
		};
	}, [onNewMessage, onNewNumber, onNewTradeCounterOffer, socketData.socket]);

	return (
		<NegotiationsContext.Provider value={{ chatRooms, setChatRooms }}>
			{children}
		</NegotiationsContext.Provider>
	);
};

export default NegotiationsContextProvider;
