import React, { useContext, useState, useRef, useCallback } from "react";
import axios from "axios";
import { UserDataContext } from "../App";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../config";
import Card from "./shared/Card";
import Button from "./shared/Button";
import Input from "./shared/Input/Input";

export default function Login() {
  const setUserData = useContext(UserDataContext)[1];
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const refEmail = useRef(null);
  const refPassword = useRef(null);

  const handleLogin = useCallback((e) => {
    e.preventDefault();

    const params = new URLSearchParams();
    params.append("email", refEmail.current.value);
    params.append("password", refPassword.current.value);

    axios({
      method: "post",
      url: baseURL + "/seller/login",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((request) => {
        if (request.data.success === 0) {
          throw new Error("error");
        }
        setError("");
        setUserData({
          ...request.data,
          isOnline: true,
        });
      })
      .catch((error) => {
        setError("Incorrect email or password!");
        refPassword.current.value = "";
      });
  }, []);

  function keyDownHandler(e) {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  }

  return (
    <div className="relative container h-full min-h-screen mx-auto py-12 px-4">
      <div className="flex justify-center">
        <img
          alt="autos today logo"
          className="max-w-full h-12 my-2"
          src="/images/logo-hq.png"
        />
      </div>
      <Card className="mt-4 mx-auto w-full sm:w-3/5 md:6/12 lg:w-4/12">
        <form className="flex flex-col space-y-4" onKeyDown={keyDownHandler}>
          <div className="text-center">
            Receive offers in under a minute
            <br />
            100% free and anonymous
          </div>

          <Input ref={refEmail} label="Email Address" />
          <Input type="password" ref={refPassword} label="Password" />

          <div className="text-center">
            <Link className="text-primary" to="/forgot-pasword">
              Forgot password?{" "}
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center lg:px-8">
            <Button
              className="w-full"
              variant="secondary"
              onClick={handleLogin}
            >
              SIGN IN
            </Button>

            <span className="py-2">or</span>

            <Button
              className="w-full"
              variant="neutral"
              onClick={() => navigate("/register")}
            >
              Sell My Car
            </Button>
          </div>
        </form>
      </Card>
      {error !== "" && (
        <div className="absolute bottom-12 left-[50%] translate-x-[-50%] px-4 py-2 rounded bg-secondary text-white text-center">
          {error}
        </div>
      )}
    </div>
  );
}
