import Input from "components/shared/Input/Input";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { useContext, useState } from "react";
import { validateEmail } from "utils/validate";
import axios from "axios";
import { baseURL } from "config";
import Textarea from "components/shared/Textarea";
import { UserDataContext } from "App";
import Loading from "components/Loading";
import Button from "components/shared/Button";
import { toast } from "react-toastify";

const MoreInfo = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const userData = useContext(UserDataContext)[0];
  // { name: String, email: String, phone: String, message: String }
  const [form, setForm] = useState({
    name: modalCar?.user_name || "",
    email: modalCar?.user_email || "",
    phone: modalCar?.user_phone || "",
    message: "",
  });
  const [invalidForm, setInvalidForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [response, setResponse] = useState({
    status: null,
    message: "",
  });
  function handleFormChange(e) {
    setInvalidForm({
      ...invalidForm,
      [e.target.name]: "",
    });
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }
  function handleSubmitRequest() {
    if (
      form.visit_date === "" ||
      form.name === "" ||
      form.message === "" ||
      !validateEmail(form.email)
    ) {
      let newInvalid = {};
      form.visit_date === "" &&
        (newInvalid["visit_date"] = "Visit date is required!");
      form.name === "" && (newInvalid["name"] = "Name is required!");
      !validateEmail(form.email) &&
        (newInvalid["email"] = "Invalid email format!");
      form.message === "" && (newInvalid["message"] = "Message is required!");
      setInvalidForm({
        ...invalidForm,
        ...newInvalid,
      });
      return;
    }
    setResponse({ status: "loading" });
    apiRequestMoreInfo(
      {
        code: modalCar?.code,
        token: userData?.token,
        ...form,
      },
      (error, data) => {
        setResponse({ status: "done" });
        if (error || data?.success === 0) {
          dispatchModalCar({
            type: "update",
            payload: {
              msg: {
                type: "error",
                message:
                  data?.error ??
                  "Something went wrong! Please try again later.",
              },
            },
          });
          return;
        }
        dispatchModalCar({
          type: "update",
          payload: {
            user_name: form.name,
            user_email: form.email,
            user_phone: form.phone,
            user_message: form.message,
            currentModal: null,
          },
        });
        toast.success(
          "Your request for more info has been submitted successfully! Our team will be in touch with you shortly."
        );
      }
    );
  }
  function handleBackButton() {
    dispatchModalCar({
      type: "set",
      payload: {
        currentModal: null,
      },
    });
  }
  return (
    <div className="w-full border-t border-neutral-light mt-4 p-4">
      <div className="flex flex-col gap-2">
        <Input
          value={form.name}
          isInvalid={invalidForm.name}
          errorText={invalidForm.name}
          onChange={handleFormChange}
          name="name"
          rounded="xs"
          label={<div className="text-[#111827] text-base">Name</div>}
        />
        <Input
          value={form.email}
          isInvalid={invalidForm.email}
          errorText={invalidForm.email}
          onChange={handleFormChange}
          name="email"
          rounded="xs"
          label={<div className="text-[#111827] text-base">Email Address</div>}
        />
        <Input
          value={form.phone}
          isInvalid={invalidForm.phone}
          errorText={invalidForm.phone}
          format="phoneNumber"
          onChange={handleFormChange}
          rounded="xs"
          name="phone"
          label={
            <div className="text-[#111827] text-base">
              Phone Number{" "}
              <small className="text-muted text-xs">(Optional)</small>
            </div>
          }
          placeholder="Enter Phone here..."
        />
        <Textarea
          style={{ background: "#F7F7F8", borderRadius: "2px" }}
          isInvalid={invalidForm.message}
          errorText={invalidForm.message}
          label={<div className="text-[#111827] text-base">Message</div>}
          onChange={handleFormChange}
          name="message"
        />
      </div>
      {response.status === "loading" ? (
        <Loading />
      ) : (
        <Button
          // disabled={Object.values(invalidForm).some((i) => i !== "")}
          onClick={handleSubmitRequest}
          className="w-full mt-6 font-medium text-base"
        >
          Request More Info
        </Button>
      )}
      <div className="flex justify-center">
        <div
          onClick={handleBackButton}
          className="text-danger mt-2 cursor-pointer"
        >
          Return to Car Page
        </div>
      </div>
    </div>
  );
};
export default MoreInfo;
///  { name: String, email: String, phone: String, message: String }
export function apiRequestMoreInfo(
  { code, token, email, name, phone, message },
  cb
) {
  /// /v1/seller/inventory/:code/request-info
  let params = new URLSearchParams();
  params.append("name", name);
  params.append("email", email);
  phone && params.append("phone", phone);
  params.append("message", message);
  axios({
    method: "POST",
    url: `${baseURL}/seller/inventory/${code}/request-info`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
    data: params,
  })
    .then((res) => {
      cb(null, res.data);
    })
    .catch((err) => {
      cb(err);
    });
}
