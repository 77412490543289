import Input from "components/shared/Input/Input";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { useEffect, useState, useContext } from "react";
import Button from "components/shared/Button";
import Radio from "components/shared/Radio";

const TradeDetails = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const [form, setForm] = useState({
    carCondition: modalCar?.trade?.carCondition || "",
    currentMileage: modalCar?.trade?.currentMileage || "",
    tradeInValue: modalCar?.trade?.tradeInValue || "",
    tradeInLoanBalance: modalCar?.trade?.tradeInLoanBalance || "",
  });
  const [formError, setFormError] = useState({
    carCondition: "",
    currentMileage: "",
    tradeInValue: "",
    tradeInLoanBalance: "",
  });
  useEffect(() => {
    dispatchModalCar({
      type: "set-action",
      payload: {
        backButton: function () {
          dispatchModalCar({
            type: "update",
            payload: {
              currentSubModal: "tradeByType",
            },
          });
        },
      },
    });

    return () => {
      dispatchModalCar({
        type: "set-action",
        payload: {
          backButton: null,
        },
      });
    };
  }, []);

  function handleChangeForm(e) {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  }

  function handleContinue() {
    let error = {};
    if (form.carCondition === "") {
      error.carCondition = "Please select car condition";
    }
    if (form.currentMileage === "") {
      error.currentMileage = "Please enter current mileage";
    }
    if (form.tradeInValue === "") {
      error.tradeInValue = "Please enter car worth";
    }
    if (form.tradeInLoanBalance === "") {
      error.tradeInLoanBalance = "Please enter car pay loan balance";
    }
    setFormError(error);
    if (Object.keys(error).length > 0) {
      return;
    }
    const { tradeInValue, tradeInLoanBalance, ...restTradeInDetails } = form;
    dispatchModalCar({
      type: "trade-update",
      payload: {
        ...restTradeInDetails,
      },
    });
    dispatchModalCar({
      type: "update",
      payload: {
        currentSubModal: "tradeImages",
      },
    });
    dispatchModalCar({
      type: "calc-update",
      payload: {
        tradeInValue: tradeInValue,
        tradeInLoanBalance: tradeInLoanBalance,
      },
    });
  }

  return (
    <div className="w-full border-t border-neutral-light mt-4 p-4">
      <div className="flex flex-col gap-4">
        <Input
          value={form.currentMileage}
          onChange={handleChangeForm}
          format="Number"
          isInvalid={formError.currentMileage}
          errorText={formError.currentMileage}
          label="Current Mileage"
          name="currentMileage"
          placeholder="Enter current mileage"
        />
        <div>
          <div className="input-calc-speed-label my-1">Car condition</div>

          <Radio
            name="carCondition"
            onChange={handleChangeForm}
            checked={form.carCondition === "1"}
            value="1"
            label="Excellent"
            description="Like new condition, no issues"
          />
          <Radio
            name="carCondition"
            onChange={handleChangeForm}
            checked={form.carCondition === "2"}
            value="2"
            label="Good"
            description="Few minor defects,no major issues"
          />
          <Radio
            name="carCondition"
            onChange={handleChangeForm}
            checked={form.carCondition === "3"}
            value="3"
            label="Fair"
            description="Possible major issues"
          />
          {formError.carCondition && (
            <div>
              <div className="text-danger text-xs mt-1">
                {formError.carCondition}
              </div>
            </div>
          )}
        </div>

        <Input
          value={form.tradeInValue}
          onChange={handleChangeForm}
          format="Number"
          isInvalid={formError.tradeInValue}
          errorText={formError.tradeInValue}
          label="Car Worth"
          name="tradeInValue"
          rounded="xs"
          placeholder="What do you think your car is worth?"
        />

        <Input
          value={form.tradeInLoanBalance}
          onChange={handleChangeForm}
          format="Number"
          isInvalid={formError.tradeInLoanBalance}
          errorText={formError.tradeInLoanBalance}
          label="Approximate Loan Balance"
          name="tradeInLoanBalance"
          rounded="xs"
          placeholder="Enter car loan balance"
        />
      </div>

      <Button className="w-full mt-6" onClick={handleContinue}>
        Continue
      </Button>
      <div className="flex justify-center">
        <div onClick={modalCar?.actions?.backButton} className="back-btn-tri">
          Go Back
        </div>
      </div>
    </div>
  );
};

export default TradeDetails;
