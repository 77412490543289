import React, { useContext, useEffect, useState } from "react";
import { secondsToDhmsArray } from "../../utils/time";
import axios from "axios";
import { baseURL } from "../../config";
import { UserDataContext } from "App";
import Button from "components/shared/Button";
import { ClockIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

var interval = null;
const TimerCountDown = ({ auction }) => {
  const [timeLeftDHMS, setTimeLeftDHMS] = useState([0, 0, 0, 0, 0]); // days hours minutes seconds and raw-seconds  over 60s
  const [userData, setUserData] = useContext(UserDataContext);
  const { end_date } = auction;
  const unixTime_end_date = Math.floor(new Date(end_date).getTime() / 1000);

  useEffect(() => {
    if (isNaN(unixTime_end_date)) return;
    interval = setInterval(() => {
      var unixTime_now_date = Math.floor(new Date().getTime() / 1000);
      setTimeLeftDHMS(
        secondsToDhmsArray(unixTime_end_date - unixTime_now_date)
      );
    });
    return () => {
      clearInterval(interval);
    };
  }, [end_date, unixTime_end_date]);

  function reList() {
    axios({
      method: "POST",
      url: baseURL + "/seller/relist",
      headers: {
        Authorization: userData.token,
      },
    }).then((response) => {
      setUserData((prevState) => {
        return {
          ...prevState,
          newMessage: 0,
          newMessageFrom: [],
          newBid: 0,
        };
      });
      setTimeout(() => {
        window.location.reload();
      }, 200);
    });
  }

  return (
    <>
      <div
        className={cn(
          "rounded-lg flex-col items-center justify-center border-t-4 py-4 bg-white shadow p-6 mb-3",
          {
            "border-success": timeLeftDHMS[0] > 0 || timeLeftDHMS[1] >= 5,
            "border-warning":
              timeLeftDHMS[0] === 0 &&
              timeLeftDHMS[1] < 5 &&
              timeLeftDHMS[1] > 1,
            "border-danger": timeLeftDHMS[0] === 0 && timeLeftDHMS[1] <= 1,
          }
        )}
      >
        <div className="flex justify-between items-center border-b-2 border-primary py-2">
          <h3 className="text-lg xl:text-[22px] font-bold text-primary mb-0">
            Listing Status
          </h3>
          <ClockIcon className="w-6 h-6 text-primary" />
        </div>
        <div className="flex justify-between items-center">
          {timeLeftDHMS[4] > 0 && (
            <>
              <div className="text-primary font-normal text-base mr-2">
                Time Remaining:
              </div>
              <div className="grid grid-flow-col gap-1 text-center auto-cols-max">
                <div className="flex flex-col p-1 text-xs xl:text-base  font-normal text-primary">
                  <span>
                    <span className="text-base xl:text-[22px] font-bold">
                      {timeLeftDHMS[0].toString().padStart(2, "0")}
                    </span>
                  </span>
                  days
                </div>
                <div className="flex flex-col p-1 font-normal text-xs xl:text-base  text-primary">
                  <span>
                    <span className="text-base xl:text-[22px] font-bold">
                      {timeLeftDHMS[1].toString().padStart(2, "0")}
                    </span>
                  </span>
                  hours
                </div>
                <div className="flex flex-col p-1 text-xs xl:text-base  font-normal text-primary">
                  <span>
                    <span className="text-base xl:text-[22px] font-bold">
                      {timeLeftDHMS[2].toString().padStart(2, "0")}
                    </span>
                  </span>
                  mins
                </div>
                <div className="hidden sm:flex flex-col p-1 text-xs xl:text-base font-normal text-primary">
                  <span>
                    <span className="text-base xl:text-[22px] font-bold">
                      {timeLeftDHMS[3].toString().padStart(2, "0")}
                    </span>
                  </span>
                  sec
                </div>
              </div>
            </>
          )}
          {timeLeftDHMS[4] < 0 && (
            <Button
              variant="primary"
              onClick={reList}
              className="my-3 flex-1"
              label="Relist Your Car"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TimerCountDown;
