import { useState, useContext } from "react";
import ModalAddCar from "components/NoCar/parts/ModalAddCar";
import { ContextForm } from "components/NoCar/contexts/FormContext";
import Button from "components/shared/Button";

const NoCarSection = () => {
  const { editFormRegister } = useContext(ContextForm);
  const [show, setShow] = useState(null);
  const handleClose = () => setShow(false);

  function handlePlateSelected() {
    editFormRegister("stepType", "plate");
    setShow(true);
  }

  function handleVinSelected() {
    editFormRegister("stepType", "vin");
    setShow(true);
  }

  function handleManualSelected() {
    editFormRegister("stepType", "manual");
    setShow(true);
  }

  return (
    <>
      <div className="flex flex-col justify-start">
        <h3 className="header-speed-negotiations">List a Car</h3>
        <p className="text-center">
          The easiest way to list your car is with your license plate or a VIN
          number.
        </p>
        <div className="flex w-full">
          <Button
            onClick={handlePlateSelected}
            className="grow mx-2"
            colors={["#B62949", "#B6294926"]}
          >
            PLATE
          </Button>
          <Button
            onClick={handleVinSelected}
            className="grow mx-2"
            colors={["#B62949", "#B6294926"]}
          >
            VIN
          </Button>
        </div>
        <p className="text-center my-3">Don't have a license plate or a VIN?</p>
        <div className="flex justify-center items-center">
          <Button
            onClick={handleManualSelected}
            className="mx-2"
            style={{ width: "256px", transform: "translateY(50%)" }}
            colors={["#FFFFFF", "#B62949"]}
          >
            List car Manually
          </Button>
        </div>
      </div>
      <ModalAddCar show={show} handleClose={handleClose} />
    </>
  );
};

export default NoCarSection;
