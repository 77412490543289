import Button from "components/shared/Button";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "config.js";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { useContext } from "react";
import { UserDataContext } from "App.js";
import Select from "components/shared/Select";
import {
	fetchMakerList,
	fetchModelMaker,
	fetchTrimsMakerList,
} from "components/searchCars/request/apiFetchManualValues";
import Modal from "components/shared/Modal";
import Input from "components/shared/Input/Input";
import Radio from "components/shared/Radio";

const TradeInModal = ({ isOpen, state, onComplete = () => {}, onClose }) => {
	const [title, setTitle] = useState("");
	const userData = useContext(UserDataContext)[0];
	const [modalConfig, setModalConfig] = useState({
		step: "chooseTrade",
	});
	const [form, setForm] = useState({
		tradeType: null,
		carDetails: {},
		carStatus: {},
	});

	function chosenTrade(option) {
		setForm((prevState) => ({
			...prevState,
			tradeType: option,
		}));
	}

	function setCarDetails(data) {
		setForm((prevState) => ({
			...prevState,
			carDetails: data,
		}));
	}

	function setCarStatus(data) {
		setForm((prevState) => ({
			...prevState,
			carStatus: data,
		}));
	}

	function changeStep(step) {
		setModalConfig((prevState) => ({
			...prevState,
			step: step,
		}));
	}

	function finalSubmit(cb = () => {}) {
		let finalePayload = {
			trade_method: form?.tradeType,
			trade_mileage: form?.carStatus.currentMileage,
			trade_condition_id: form?.carStatus.carCondition,
			trade_loan_balance: form?.carStatus.carPayLoanBalance,
			trade_asking_price: form?.carStatus.carWorth,
			trade_vin: form?.carDetails?.vin,
			trade_vin_id: form?.carDetails?.vin_id,
			trade_year: form?.carDetails?.year,
			trade_make: form?.carDetails?.make,
			trade_model: form?.carDetails?.model,
			trade_trim: form?.carDetails?.trim,
			trade_type: form?.carDetails?.type,
			trade_engine: form?.carDetails?.engine,
			trade_plate: form?.carDetails?.plate,
			trade_state: form?.carDetails?.state,
		};

		let params = new URLSearchParams();
		for (let key in finalePayload) {
			if (finalePayload[key]) {
				params.append(key, finalePayload[key]);
			}
		}

		axios({
			method: "POST",
			url: `${baseURL}/seller/negotiation/${state.code}/trade`,
			data: params,
			headers: {
				Authorization: userData.token,
				"Content-Type": "application/x-www-form-urlencoded",
			},
		})
			.then((res) => {
				cb(null, res.data);
				setModalConfig((prevState) => ({
					...prevState,
					step: "chooseTrade",
				}));
				onClose();
				onComplete();
			})
			.catch((err) => {
				cb(err, null);
			});
	}

	return (
		<Modal show={isOpen} onHide={onClose} size="sm">
			<Modal.Header>
				<Modal.Title>{title ? title : "ADD Trade-in"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalConfig.step === "chooseTrade" && (
					<ChooseTypeTrade
						chosenTrade={chosenTrade}
						changeStep={changeStep}
						setTitle={setTitle}
					/>
				)}

				{modalConfig.step === "enterVin" && (
					<EnterVinNumber
						setCarDetails={setCarDetails}
						changeStep={changeStep}
						setTitle={setTitle}
					/>
				)}
				{modalConfig.step === "enterLicensePlate" && (
					<EnterLicensePlate
						setCarDetails={setCarDetails}
						changeStep={changeStep}
						setTitle={setTitle}
					/>
				)}
				{modalConfig.step === "enterManual" && (
					<EnterManual
						setCarDetails={setCarDetails}
						changeStep={changeStep}
						setTitle={setTitle}
					/>
				)}

				{modalConfig.step === "carStatus-vin" && (
					<ChooseCarStatus
						setCarStatus={setCarStatus}
						changeStep={changeStep}
						setTitle={setTitle}
						type="vin"
					/>
				)}
				{modalConfig.step === "carStatus-plate" && (
					<ChooseCarStatus
						setCarStatus={setCarStatus}
						changeStep={changeStep}
						setTitle={setTitle}
						type="plate"
					/>
				)}
				{modalConfig.step === "carStatus-manual" && (
					<ChooseCarStatus
						setCarStatus={setCarStatus}
						changeStep={changeStep}
						setTitle={setTitle}
						type="manual"
					/>
				)}

				{modalConfig.step === "finalWords" && (
					<FinalWords finalSubmit={finalSubmit} setTitle={setTitle} />
				)}
			</Modal.Body>
		</Modal>
	);
};

function FinalWords({ finalSubmit, setTitle }) {
	useEffect(() => {
		finalSubmit();
		setTitle("Submitting Trade-in");
	}, []);

	return (
		<div className="flex justify-center flex-col items-center py-3">
			<div>Loading...</div>
			<div>
				<FaSpinner className="spinner" color="#B72949" size={40} />
			</div>
		</div>
	);
}

function ChooseTypeTrade({ chosenTrade, changeStep, setTitle }) {
	useEffect(() => {
		setTitle("Choose Trade Type");
	}, []);

	return (
		<>
			<div className="flex flex-col justify-center align-content-center gap-4 px-10">
				<Button
					variant="primary"
					onClick={() => {
						chosenTrade("vin");
						changeStep("enterVin");
					}}
				>
					Vin Number
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						chosenTrade("plate");
						changeStep("enterLicensePlate");
					}}
				>
					License Plate
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						chosenTrade("manual");
						changeStep("enterManual");
					}}
				>
					Manual
				</Button>
			</div>
		</>
	);
}

function EnterVinNumber({ setCarDetails, changeStep, setTitle }) {
	const [vinError, setVinError] = useState("");
	const [responseData, setResponseData] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setTitle("Vin Number Information");
	}, []);

	function handleChangeVin(e) {
		let vin = e.target.value;
		setVinError("");
		setIsLoading(true);
		setResponseData({});
		apiFetchVin(vin, (err, data) => {
			setIsLoading(false);
			if (err || data?.success === 0) {
				setVinError(data?.error || "Network Error, Please try again later");
				return;
			}
			setResponseData(data);
		});
	}

	function handleContinue() {
		if (!responseData?.vin) {
			setVinError("Please enter your VIN number");
			return;
		}
		if (isLoading) {
			setVinError("Please wait for the response");
			return;
		}
		setCarDetails(responseData);
		changeStep("carStatus-vin");
	}

	return (
		<>
			<div className="flex justify-center items-center flex-col px-10">
				<div className="w-full my-2 relative">
					<Input
						containerClassName="w-full"
						className="mb-6"
						isInvalid={vinError}
						errorText={vinError}
						onChange={handleChangeVin}
						label="VIN NUMBER"
					/>
					{isLoading && (
						<div className="flex justify-center text-center mt-2 mb-2">
							<FaSpinner color="#BF405C" className="spinner" />
						</div>
					)}
				</div>
				<table className="w-full my-4">
					<tbody>
						{Object.entries(responseData || {})
							.slice(3)
							.map(
								([key, value]) =>
									value && (
										<tr className="border-b py-2" key={key}>
											<td>
												<b>{key.charAt(0).toUpperCase() + key.slice(1)}</b>
											</td>
											<td>{value}</td>
										</tr>
									)
							)}
					</tbody>
				</table>
				<Button className="w-full" label="Continue" onClick={handleContinue} />
				<BackButton onClick={() => changeStep("chooseTrade")} />
			</div>
		</>
	);
}

var countryCode = [
	"AL",
	"AK",
	"AZ",
	"AR",
	"CA",
	"CO",
	"CT",
	"DE",
	"FL",
	"GA",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"OH",
	"OK",
	"OR",
	"PA",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
	"DC",
	"AS",
	"GU",
	"MP",
	"PR",
	"UM",
	"VI",
];
var countryNames = [
	"Alabama",
	"Alaska",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
	"District of Columbia",
	"American Samoa",
	"Guam",
	"Northern Mariana Islands",
	"Puerto Rico",
	"United States Minor Outlying Islands",
	"U.S. Virgin Islands",
];

function EnterLicensePlate({ setCarDetails, changeStep, setTitle }) {
	const [plateData, setPlateData] = useState({ plate: "", state: "" });

	const [isLoading, setIsLoading] = useState(false);
	const [verboseError, setVerboseError] = useState({
		type: "hidden",
		error: "",
	});

	useEffect(() => {
		setTitle("License Plate Information");
	}, []);

	function handlePlateChange(e) {
		handleChanges({
			plate: e.target.value,
		});
	}
	function handleSelect(e) {
		handleChanges({
			state: e.target.value,
		});
	}

	function handleChanges({ plate, state }) {
		plate = plate ?? plateData.plate;
		state = state ?? plateData.state;

		setPlateData({ plate, state });
		setVerboseError({ type: "hidden", error: "" });
		setIsLoading(true);
		apiFetchPlateVin(
			{
				plate: plate,
				state: state,
			},
			(err, data) => {
				setIsLoading(false);
				if (err || data?.success === 0) {
					setVerboseError({
						type: "visible",
						error: data?.error || "Network Error, Please try again later",
					});
					return;
				}
				setPlateData((prev) => ({ ...prev, ...data }));
			}
		);
	}

	function handleContinue() {
		if (!plateData?.plate) {
			setVerboseError({
				type: "visible",
				error: "Please enter your License Plate",
			});
			return;
		}
		if (!plateData?.state) {
			setVerboseError({ type: "visible", error: "Please select your state" });
			return;
		}
		if (verboseError.type === "visible") {
			return;
		}
		setCarDetails(plateData);
		changeStep("carStatus-plate");
	}

	return (
		<div className="flex justify-center items-center flex-col">
			<div>
				<Input
					value={plateData.plate}
					onChange={handlePlateChange}
					label="License Plate"
					placeholder="Enter License Plate"
				/>
				{isLoading && (
					<FaSpinner className="spinner flex justify-center my-4 w-full" />
				)}{" "}
				{verboseError.type === "visible" && (
					<div className="text-danger">{verboseError.error}</div>
				)}
				<Select
					defaultValue={plateData.state}
					onChange={handleSelect}
					label="State"
					placeholder="Select State"
					labelOptions={countryNames}
					valueOptions={countryCode}
				/>
			</div>
			{/* Table */}
			<div>
				<table className="w-full my-4">
					<tbody>
						{Object.entries(plateData || {})
							.slice(3)
							.map(
								([key, value]) =>
									value && (
										<tr className="border-b py-2" key={key}>
											<td>
												<b>{key.charAt(0).toUpperCase() + key.slice(1)}</b>
											</td>
											<td>{value}</td>
										</tr>
									)
							)}
					</tbody>
				</table>
			</div>
			<div style={{ minWidth: "50%", marginTop: "25px" }}>
				<Button
					onClick={handleContinue}
					label="Continue"
					className="py-2 w-full"
				/>
			</div>
			<BackButton onClick={() => changeStep("chooseTrade")} />
		</div>
	);
}

function ChooseCarStatus({ setCarStatus, type, changeStep, setTitle }) {
	const [hasError, setHasError] = useState("");
	const [form, setForm] = useState({
		currentMileage: "",
		carCondition: "",
		carPayLoanBalance: "",
		carWorth: "",
	});

	useEffect(() => {
		setTitle("Trade Car");
	}, []);

	function onChangeForm(e) {
		setHasError("");
		setForm((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	}

	function handleContinue() {
		if (!form.currentMileage) {
			setHasError("Please enter current mileage");
			return;
		}
		if (!form.carCondition) {
			setHasError("Please select car condition");
			return;
		}
		if (!form.carWorth) {
			setHasError("Please enter car worth");
			return;
		}
		setCarStatus(form);
		changeStep("finalWords");
	}

	return (
		<>
			<div className="pt-2 flex flex-col gap-4">
				{hasError && <div className="sydnic-error">{hasError}</div>}
				<Input
					name="currentMileage"
					value={form.currentMileage}
					onChange={onChangeForm}
					type="number"
					label="Current Mileage"
					placeholder="Enter Current Mileage"
				/>
				<div className="flex flex-col">
					<small className="input-calc-speed-label my-1">Car condition</small>

					<Radio
						name="carCondition"
						onChange={onChangeForm}
						checked={form.carCondition === "1"}
						value="1"
						label="Excellent"
						description="Like new condition, no issues"
					/>
					<Radio
						name="carCondition"
						onChange={onChangeForm}
						checked={form.carCondition === "2"}
						value="2"
						label="Good"
						description="Few minor defects,no major issues"
					/>
					<Radio
						name="carCondition"
						onChange={onChangeForm}
						checked={form.carCondition === "3"}
						value="3"
						label="Fair"
						description="Possible major issues"
					/>
				</div>

				<Input
					className="bot-boder-dollar"
					name="carWorth"
					onChange={onChangeForm}
					value={form.carWorth}
					type="number"
					label="Car Worth"
					placeholder="What do you think your car is worth?"
				/>

				<small className="text-muted mb-2">Approximate Loan Balance</small>
				<Input
					name="carPayLoanBalance"
					value={form?.carPayLoanBalance}
					onChange={onChangeForm}
					className="bot-boder-dollar"
					type="number"
					placeholder="Enter Loan Balance"
				/>
			</div>
			<Button
				className="w-full mt-6"
				label="Continue"
				onClick={handleContinue}
			/>
			<BackButton
				onClick={() => {
					if (type === "vin") {
						changeStep("enterVin");
					}
					if (type === "plate") {
						changeStep("enterLicensePlate");
					}
					if (type === "manual") {
						changeStep("enterManual");
					}
				}}
			/>
		</>
	);
}

function EnterManual({ changeStep, setCarDetails, setTitle }) {
	const [listMake, setListMake] = useState([]);
	const [listModel, setListModel] = useState([]);
	const [listTrim, setListTrim] = useState([]);
	const [hasError, setHasError] = useState("");

	const [form, setForm] = useState({
		year: "",
		make: "",
		model: "",
		trim: "",
	});

	useEffect(() => {
		setTitle("Vehicle Specifications");
	}, []);

	const onSelectYear = (e) => {
		fetchMakerList(e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, year: e.target.value });
			setListMake(data);
		});
	};
	const onSelectMake = (e) => {
		fetchModelMaker(form.year, e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, make: e.target.value });
			setListModel(data);
		});
	};
	const onSelectModel = (e) => {
		fetchTrimsMakerList(form.year, form.make, e.target.value, (err, data) => {
			if (err) return;
			setForm({ ...form, model: e.target.value });
			setListTrim(data);
		});
	};

	function handleContinue() {
		if (!form.year) {
			setHasError("Please select year");
			return;
		}
		if (!form.make) {
			setHasError("Please select make");
			return;
		}
		if (!form.model) {
			setHasError("Please select model");
			return;
		}
		setCarDetails(form);
		changeStep("carStatus-manual");
	}

	let arrayYearList = Array.from({
		length: new Date().getFullYear() + 1 - 1990,
	}).map((item, index) => 1990 + index);
	arrayYearList.reverse();

	return (
		<>
			<div style={{ minWidth: "350px" }}>
				{hasError && (
					<div className="w-full text-center text-danger font-semibold text-sm px-4 py-1 bg-[#e3cece3d] rounded-lg border-lg">
						{hasError}
					</div>
				)}

				{/* <div className="go-header-custom-tx1 text-center">Vehicle Specifications</div> */}
				<div className="my-1">
					<Select
						onChange={onSelectYear}
						defaultValue={form.year}
						label="Year"
						placeholder="Choose Year"
						labelOptions={arrayYearList}
						valueOptions={arrayYearList}
					/>
				</div>
				<div className="my-1">
					<Select
						onChange={onSelectMake}
						defaultValue={form.make}
						label="Make"
						disabled={listMake.length === 0}
						placeholder="Choose Make"
						labelOptions={listMake}
						valueOptions={listMake}
					/>
				</div>
				<div className="my-1">
					<Select
						onChange={onSelectModel}
						defaultValue={form.model}
						label="Model"
						disabled={listModel.length === 0}
						placeholder="Choose Model"
						labelOptions={listModel}
						valueOptions={listModel}
					/>
				</div>
				<div className="my-1">
					<Select
						onChange={(e) => setForm({ ...form, trim: e.target.value })}
						label="Trim"
						defaultValue={form.trim}
						disabled={listTrim.length === 0}
						placeholder="Choose Trim"
						labelOptions={listTrim}
						valueOptions={listTrim}
					/>
				</div>
			</div>
			<div style={{ minWidth: "35%", marginTop: "25px" }}>
				<Button
					onClick={handleContinue}
					label="Continue"
					className="py-2 w-full"
				/>
			</div>
			<BackButton onClick={() => changeStep("chooseTrade")} />
		</>
	);
}

function BackButton({ onClick }) {
	onClick ??= () => {};
	return (
		<div className="flex justify-center">
			<div onClick={onClick} className="back-btn-tri">
				Go Back
			</div>
		</div>
	);
}

// startline requests
let controllerApiFetch = null;
function apiFetchVin(vin, cb) {
	if (controllerApiFetch) {
		controllerApiFetch.abort();
	}
	controllerApiFetch = new AbortController();
	axios({
		method: "post",
		url: `${baseURL}/seller/register/vin`,
		data: {
			vin: vin,
		},
		signal: controllerApiFetch.signal,
	})
		.then((res) => {
			cb(null, res.data);
		})
		.catch((err) => {
			if (err.name === "CanceledError") {
				return;
			}
			cb(err);
		});
}

function apiFetchPlateVin({ plate, state }, cb) {
	if (controllerApiFetch) {
		controllerApiFetch.abort();
	}
	controllerApiFetch = new AbortController();
	axios({
		method: "POST",
		url: `${baseURL}/seller/register/plate-to-vin`,
		data: {
			plate: plate,
			state: state,
		},
		signal: controllerApiFetch.signal,
	})
		.then((res) => {
			cb(null, res.data);
		})
		.catch((err) => {
			if (err.name === "CanceledError") {
				return;
			}
			cb(err);
		});
}
// endline requests

export default TradeInModal;
