import { UserDataContext } from "App";
import Loading from "components/Loading";
import { apiSetLookingForCar } from "components/home/request/apiSetLookingForCar";
import Button from "components/shared/Button";
import Checkbox from "components/shared/Checkbox";
import Modal from "components/shared/Modal";
import Textarea from "components/shared/Textarea";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useLookingForCar } from "./ModalLookingForCar.hooks";

const ModalLookingForCar = ({ isOpen, onClose, onSave = () => {} }) => {
  const [userData] = useContext(UserDataContext);
  const { isLoading, lookingForCar, setLookingForCar } = useLookingForCar();

  const submitLookingForCar = () => {
    apiSetLookingForCar(
      userData.token,
      lookingForCar.isLooking,
      lookingForCar.description,
      (error, data) => {
        if (error) throw error;

        if (data?.success === 0) {
          toast.error("Failed to update looking for car");
          onClose();
        } else {
          toast.success("Looking for car updated successfully");
          onSave();
        }
      }
    );
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="sm" withCloseButton>
      <Modal.Header>
        <Modal.Title>What are you looking for?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="border-b border-[#DFEBF7] mb-2 pb-8 ">
              <Checkbox
                label="Are you looking for a car?"
                id="areYouLooking"
                checked={lookingForCar.isLooking}
                onChange={(e) => {
                  setLookingForCar({
                    ...lookingForCar,
                    isLooking: e.target.checked,
                  });
                }}
              />
            </div>
            <div className="w-full mb-4">
              <Textarea
                id="exampleFormControlTextarea1"
                rows="5"
                className="resize-none"
                label="Message"
                placeholder="Write your message here..."
                value={lookingForCar.description}
                onChange={(e) => {
                  setLookingForCar({
                    ...lookingForCar,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-col w-full items-center">
          <Button
            onClick={submitLookingForCar}
            label="Update"
            className="w-full py-2"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalLookingForCar;
