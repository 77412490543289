import axios from "axios";
import { baseURL } from "config";

export const AddCarRequest = (form, token, callback = () => {}) => {
  const params = new URLSearchParams();
  for (let key in form) {
    if (form[key] !== null && form[key] !== undefined) {
      params.append(key, form[key]);
    }
  }
  axios({
    method: "POST",
    url: baseURL + "/seller/car/list",
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export function FetchAuctionProgress(token, callback = () => {}) {
  axios({
    method: "GET",
    url: baseURL + "/seller/progress",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      console.log("Error Auction:", error);
      callback(error, null);
    });
}

export function FetchDataCarMoreAuction(car_id, token, callback = () => {}) {
  //https://dev-api.autostoday.com/v1/car/details/3601
  axios({
    method: "GET",
    url: baseURL + "/car/details/" + car_id,
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      callback(error, null);
    });
}
