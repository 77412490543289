import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { apiFetchCheckPlate } from "components/searchCars/request/apiFetchCheckPlate";
import { useEffect, useState, useContext } from "react";
import Select from "components/shared/Select";
import { BsArrowRight } from "react-icons/bs";
import Input from "components/shared/Input/Input";
import Button from "components/shared/Button";

var countryCode = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "DC",
  "AS",
  "GU",
  "MP",
  "PR",
  "UM",
  "VI",
];
var countryNames = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "District of Columbia",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Puerto Rico",
  "United States Minor Outlying Islands",
  "U.S. Virgin Islands",
];

const TradeLicensePlate = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  let contextTrade = modalCar?.trade || {};
  contextTrade = contextTrade?.tradeType == "plate" ? contextTrade : {};
  const [plateData, setPlateData] = useState({ ...contextTrade });
  const [responseStatus, setResponseStatus] = useState({
    status: null,
    message: null,
  });

  useEffect(() => {
    dispatchModalCar({
      type: "set-action",
      payload: {
        backButton: function () {
          dispatchModalCar({
            type: "update",
            payload: {
              currentModal: "secondaryModal",
              currentSubModal: "tradeByType",
            },
          });
        },
      },
    });
    return () => {
      dispatchModalCar({
        type: "set-action",
        payload: {
          backButton: null,
        },
      });
    };
  }, []);

  function handleChangeForm(value, name) {
    let plate = plateData?.plate || "";
    let state = plateData?.state || "";
    if (name == "plate") {
      plate = value;
    } else if (name == "state") {
      state = value;
    }

    setPlateData({
      plate: plate,
      state: state,
    });
    setResponseStatus({
      status: "loading",
      message: null,
    });
    apiFetchCheckPlate(plate, state, (error, data) => {
      if (error || data?.success === 0) {
        setResponseStatus({
          status: "error",
          message: data?.message || data?.error || "Error",
          visible: false,
        });
        return;
      }
      setResponseStatus({
        status: "success",
        message: null,
      });
      let { success, ...otherData } = data;
      setPlateData({
        ...otherData,
        plate: plate,
        state: state,
      });

      dispatchModalCar({
        type: "trade-set",
        payload: {
          tradeType: "plate",
          ...otherData,
          plate: plate,
          state: state,
        },
      });
    });
  }

  function handleContinueButton() {
    if (!plateData?.plate || !plateData?.state) {
      setResponseStatus({
        status: "error",
        message: "Please enter a valid plate and state",
        visible: true,
      });
      return;
    }
    if (responseStatus.status === "loading") {
      setResponseStatus({
        status: "error",
        message: "Please wait for the data to load",
        visible: true,
      });
      return;
    }
    if (responseStatus.status === "error") {
      setResponseStatus((prevState) => {
        return { ...prevState, visible: true };
      });
      return;
    }

    if (responseStatus.status !== "success") return;

    dispatchModalCar({
      type: "trade-set",
      payload: {
        ...plateData,
      },
    });
    dispatchModalCar({
      type: "update",
      payload: {
        currentSubModal: "tradeDetails",
      },
    });
  }

  return (
    <>
      <div className="w-full border-t border-neutral-light mt-4 p-4">
        <div className="flex flex-col gap-4">
          <Input
            onChange={(e) => handleChangeForm(e.target.value, "plate")}
            name="Plate"
            label="Plate"
            placeholder="Enter Plate"
            rounded="xs"
          />
          {responseStatus?.visible && (
            <div className="text-center text-danger mb-0">
              {responseStatus.message}
            </div>
          )}
          <Select
            onChange={(e) => handleChangeForm(e.target.value, "state")}
            defaultValue={plateData.state}
            label="State"
            placeholder="Select State"
            labelOptions={countryNames}
            valueOptions={countryCode}
          />
        </div>
        <div>
          <table className="w-full my-4">
            <tbody>
              {Object.entries(plateData || {})
                .slice(3)
                .map(
                  ([key, value]) =>
                    value && (
                      <tr className="border-b py-2" key={key}>
                        <td>
                          <b>{key.charAt(0).toUpperCase() + key.slice(1)}</b>
                        </td>
                        <td>{value}</td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
        <Button
          className="relative w-full flex items-center mt-6"
          onClick={handleContinueButton}
        >
          Continue
          <BsArrowRight className="ml-1" />
          {responseStatus?.status === "loading" && (
            <>
              {/* TODO: replace with custom made spinner */}
              <svg
                className="absolute right-4 ml-6 animate-spin h-5 w-5"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20.735A8 8 0 0017.938 12H14v-2h8v8h-2v-3.938A7.963 7.963 0 0012 20.735z"
                ></path>
              </svg>
            </>
          )}
        </Button>
      </div>
    </>
  );
};

export default TradeLicensePlate;
