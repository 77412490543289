import { skeletonMapper } from "./Skeleton.helpers";

const Skeleton = ({ type = "default" }) => {
  if (!skeletonMapper[type]) {
    throw new Error("Skeleton type not found");
  }

  return skeletonMapper[type];
};

export default Skeleton;
