import { UserDataContext } from "App";
import axios from "axios";
import Modal from "components/shared/Modal";
import Button from "components/shared/Button";
import { baseURL } from "config";
import React, { useContext, useRef } from "react";
import Input from "components/shared/Input";

const ModalCounterBid = ({ isOpen, onComplete, onClose, bid }) => {
  const [userData] = useContext(UserDataContext);
  let counterRef = useRef(null);

  function handleCounterSubmit() {
    let params = new URLSearchParams();
    let valueCounter = counterRef.current.value; // save value of input
    if (isNaN(parseInt(valueCounter))) {
      // in case the parsing fails  do not submit the counter!
      return;
    }
    params.append("bid_id", bid.id);
    params.append("counter", valueCounter);
    axios({
      method: "POST",
      url: baseURL + "/seller/bid/counter",
      data: params,
      headers: {
        Authorization: userData.token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(() => {
      onComplete(bid, valueCounter);
    });
  }

  return (
    <Modal show={isOpen} size="sm" onHide={onClose} withCloseButton>
      <Modal.Header>
        <Modal.Title>Counter Offer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3" controlId="exampleForm.ControlInput1">
            <Input
              ref={counterRef}
              type="number"
              placeholder="Enter your counter offer"
              autoFocus
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCounterSubmit} label="Counter" />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCounterBid;
