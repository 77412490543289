import { forwardRef } from "react";
import cn from "classnames";
import { variantMapper } from "./Button.helpers";

const Button = forwardRef(
  (
    {
      variant = "primary",
      className,
      type = "button",
      label,
      children,
      ...args
    },
    ref
  ) => {
    const content = label || children;
    return (
      <button
        ref={ref}
        {...args}
        className={cn(
          "py-2 px-4 rounded text-lg font-bold text-center items-center justify-center",
          variantMapper[variant],
          className
        )}
        type={type}
      >
        {content}
      </button>
    );
  }
);

export default Button;
