import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import axios from "axios";
import { baseURL } from "../config";
import { UserDataContext } from "../App";
import useQueryParams from "hooks/use-query-params.hook";

const AutoLogin = (props) => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const [, setUserData] = useContext(UserDataContext);

  let { token = "" } = useParams();

  useEffect(() => {
    verifyToken();
  }, []);

  function verifyToken() {
    axios({
      method: "post",
      url: baseURL + "/seller/login/token",
      data: { token: token },
    })
      .then((request) => {
        if (request.data.success === 1) {
          setUserData({
            ...request.data,
            isOnline: true,
          });
          if (props.case) {
            navigate("/" + props.redirect + "?case=" + props.case);
          } else {
            navigate("/" + props.redirect + "?code=" + query.get("code"));
          }
        } else {
          navigate("/");
        }
      })
      .catch((error) => {});
  }
};

export default AutoLogin;
