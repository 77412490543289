import React from 'react';

const SkeletonCarCard = () => {
  return (
    <div className="bg-white rounded-lg shadow-soft w-[256px]">
      <div className="animate-pulse">
        <div className="h-[190px] bg-gray-300 rounded-t-lg"></div>
        <div className="flex flex-col p-4 h-1/2">
          <div className="flex items-center gap-3 break-words text-sm font-semibold">
            <span className="px-2 py-1 rounded bg-[#eee] text-xs font-normal w-1/4"></span>
            <span className="bg-gray-300 h-4 w-3/4"></span>
          </div>
          <div className="text-xs font-bold bg-gray-300 h-4 w-2/3 mt-1"></div>
          <div className="flex border-b border-[#ccc] mt-2 pb-2">
            <div className="flex flex-col w-7/12">
              <span className="text-sm font-bold uppercase bg-gray-300 h-4 w-2/3"></span>
              <span className="text-[10px] bg-gray-300 h-3 w-1/2"></span>
            </div>
            <div className="w-1/3 border-l text-base font-bold border-[#ccc] uppercase pl-2 flex items-center">
              <span className="bg-gray-300 h-4 w-2/3"></span>
            </div>
          </div>
          <div className="flex flex-col mt-2 space-y-1 text-[#393939]">
            <span className="flex gap-2 text-xs">
              <span className="bg-gray-300 h-3 w-2/3"></span>
            </span>
            <span className="flex gap-2 text-xs">
              <span className="bg-gray-300 h-3 w-2/3"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCarCard;
