export function findColorMatch(colorQuery, colorsList) {
  const COLORS_LIST = colorsList.map(col => col.toLowerCase());

  // Split the color query into individual words.
  const queryWords = colorQuery.toLowerCase().split(" ");

  // Function to check if all words of a color are in the query words
  function allWordsMatch(colorWords, queryWords) {
    return colorWords.every(word => queryWords.includes(word));
  }

  let bestMatch = null;
  let maxWordCount = 0;

  for (const color of COLORS_LIST) {
    const colorWords = color.split(" ");
    if (allWordsMatch(colorWords, queryWords) && colorWords.length > maxWordCount) {
      maxWordCount = colorWords.length;
      bestMatch = color;
    }
  }

  return bestMatch;
}

export const allAvailableItems = (vehiclesData, property) => {
  const uniqueSet = new Set();
  vehiclesData.forEach((vehicle) => {
    if (vehicle[property]) {
      uniqueSet.add(vehicle[property]);
    }
  });

  return Array.from(uniqueSet);
};

export function capitalizeFirstLetterOfEachWord(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}
