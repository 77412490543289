import cn from "classnames";
import { Children, useEffect, useRef } from "react";
import ModalCloseButton from "./ModalCloseButton";
import { modalSizeMapper } from "./Modal.helpers";
import ModalHeader from "./ModalHeader";
import ModalTitle from "./ModalTitle";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalPortal from "./ModalPortal";
import { MODAL_SIZE } from "./Modal.const";
import useKeyAndOutsideClickEvent from "hooks/use-key-and-outside-click.hook";

const Modal = ({ show, size, children, onHide, withCloseButton }) => {
  const modal = useRef(null);
  const childrenArray = Children.toArray(children);
  const header = childrenArray.find((child) => child.type === Modal.Header);
  const body = childrenArray.find((child) => child.type === Modal.Body);
  const footer = childrenArray.find((child) => child.type === Modal.Footer);

  const sizeClass = MODAL_SIZE.includes(size)
    ? modalSizeMapper[size]
    : modalSizeMapper["md"];

  useKeyAndOutsideClickEvent(
    modal,
    () => {
      onHide();
    },
    { key: 27 }
  );

  useEffect(() => {
    if (!show) return;
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, [show]);

  if (!show) return null;

  return (
    <ModalPortal>
      <div className="relative z-[40]" role="dialog">
        <div
          className={cn("fixed inset-0 transition-opacity", {
            "bg-black": size === "fullscreen",
            "bg-gray-500 bg-opacity-75": size !== "fullscreen",
          })}
        ></div>

        <div
          className={cn("fixed z-10 inset-0 w-screen", {
            " overflow-y-auto": !size || size !== "fullscreen",
            " overflow-hidden": size && size === "fullscreen",
          })}
        >
          <div
            className={cn(
              "h-full flex items-center justify-center text-center sm:items-center sm:p-0",
              {
                "py-2 px-0": !size || size !== "fullscreen",
              }
            )}
          >
            <div
              ref={modal}
              className={cn(
                "relative transform overflow-hidden rounded-xl w-full",
                {
                  "bg-white text-left shadow transition-all sm:my-4 sm:w-full":
                    size && size !== "fullscreen",
                },
                sizeClass
              )}
              tabIndex="0"
            >
              {!footer || withCloseButton ? (
                <ModalCloseButton
                  onHide={onHide}
                  isFullScreen={size && size === "fullscreen"}
                />
              ) : null}
              {header}
              {body}
              {footer}
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

Modal.Header = ModalHeader;

Modal.Title = ModalTitle;

Modal.Body = ModalBody;

Modal.Footer = ModalFooter;

export default Modal;
