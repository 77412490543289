import ImageUploader from "components/configDetails/componentDetails/ImageUploader";
import Modal from "components/shared/Modal";
import React from "react";

const ModalPhotosUploader = ({ images, isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} size="lg" withCloseButton>
      <Modal.Header>
        <Modal.Title>Vehicle Photos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUploader images={images} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalPhotosUploader;
