import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { apiFetchInventorySearch } from "components/home/request/apiFetchInventorySearch";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Input from "components/shared/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

const SearchInventory = () => {
  const [inputValue, setInputValue] = useState("");
  const filterRef = useRef(null);
  const searchDropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [listInventory, setlistInventory] = useState([]);
  const [inventorySearched, setInventorySearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxLimit, setmaxLimit] = useState(10);
  const navigate = useNavigate();

  const apiFetch = useCallback(
    () =>
      apiFetchInventorySearch((_, data) => {
        if (_) {
          return;
        }
        setlistInventory(data);
        setInventorySearched(true);
        setIsLoading(false);
      }),
    []
  );
  useEffect(() => {
    if (inputValue !== "" && !inventorySearched && !isLoading) {
      setIsLoading(true);
      apiFetch(responseInventorySearch);
    }
  }, [inputValue, apiFetch, inventorySearched, isLoading]);

  useEffect(() => {
    document.addEventListener("click", eventClickOutsideSearchDropdown);
    return () => {
      document.removeEventListener("click", eventClickOutsideSearchDropdown);
    };
  }, []);

  function responseInventorySearch(error, data) {
    if (error) throw error;
    setlistInventory(data ?? []);
    setIsLoading(false);
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  function eventClickOutsideSearchDropdown(e) {
    if (document.activeElement === filterRef?.current) {
      setShowDropdown(true);
      return;
    }
    if (searchDropdownRef?.current?.contains(e?.target)) {
      return;
    }
    if (filterRef?.current?.contains(e?.target)) {
      return;
    }

    setShowDropdown(false);
    setmaxLimit(10);
  }

  function navigateToCar(code) {
    setInputValue("");
    setShowDropdown(false);

    navigate("/car-search/" + code);
  }

  const filterList = listInventory.filter((item) => {
    return item.title.toLowerCase().includes(inputValue.toLowerCase());
  });

  const filterListWithLimits = filterList.slice(0, maxLimit);

  return (
    <div className="relative max-w-full sm:max-w-xs md:max-w-sm w-full">
      <Input
        type="search"
        ref={filterRef}
        id="default-search"
        rounded="full"
        variant="primary"
        containerClassName="w-full"
        icon={<MagnifyingGlassIcon className="w-6 h-6 text-black" />}
        autoComplete="off"
        value={inputValue}
        onChange={handleInputChange}
        onInput={handleInputChange}
      />
      <div
        ref={searchDropdownRef}
        className={cn("navbar-search-dropdown-container !z-30", {
          show: showDropdown,
        })}
      >
        {inputValue !== "" &&
          filterListWithLimits &&
          filterListWithLimits.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigateToCar(item.code);
                }}
                className="row-found-search-nv flex py-1 px-2 justify-between items-center cursor-pointer gap-1"
              >
                <span className="flex items-center py-2 w-3/4 overflow-hidden text-md text-gray-700">
                  <img
                    alt=""
                    className="mr-2"
                    height="30px"
                    width="40px"
                    src={item.photo}
                  />{" "}
                  {item.title}
                </span>
                <span style={{ color: "#29B74A", fontWeight: "bold" }}>
                  {item.price === 0
                    ? "Contact Us"
                    : "$" + item.price.toLocaleString()}
                </span>
              </div>
            );
          })}
        {isLoading && (
          <div
            className="row-found-search-nv hover:!bg-white !cursor-default text-neutral"
            style={{ display: "flex", padding: "5px 10px" }}
          >
            Searching...
          </div>
        )}
        {inputValue === "" && (
          <div
            className="row-found-search-nv hover:!bg-white !cursor-default text-neutral"
            style={{ display: "flex", padding: "5px 10px" }}
          >
            Type something to start searching
          </div>
        )}
        {filterListWithLimits.length === 0 &&
        inventorySearched &&
        !isLoading ? (
          <div
            className="row-found-search-nv hover:!bg-white !cursor-default"
            style={{ display: "flex", padding: "5px 10px" }}
          >
            No results found
          </div>
        ) : null}
        {inputValue !== "" &&
          filterListWithLimits.length < filterList.length && (
            <div
              onClick={() => setmaxLimit(maxLimit + 10)}
              className="row-found-search-nv"
              style={{
                padding: "5px 10px",
                color: "rgb(183 41 73)",
                textAlign: "center",
              }}
            >
              Show more results
            </div>
          )}
      </div>
    </div>
  );
};

export default SearchInventory;