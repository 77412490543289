export const ROUNDED_TYPE = ["full", "default"];

export const roundedMapper = {
	full: "rounded-full",
	default: "rounded-none",
	small: "rounded-[10px]",
	xs: "rounded-[2px]",
};

export const VARIANT_TYPE = ["primary", "neutral"];

export const variantMapper = {
	primary: "bg-[#DFEBF7] text-[#ADA7A7]",
	neutral: "bg-neutral-light text-primary",
};
