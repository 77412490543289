import { useContext, useEffect, useState } from "react";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import Input from "components/shared/Input/Input";
import { apiFetchVinData } from "components/searchCars/request/apiFetchCheckVin";
import Button from "components/shared/Button";

export default function TradeVinNumber() {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);

  let contextTrade = modalCar?.trade || {};
  contextTrade = contextTrade?.tradeType == "vin" ? contextTrade : {};
  const [vinData, setVinData] = useState({ ...contextTrade });
  const [error, setError] = useState({
    visible: false,
    message: "",
  });
  useEffect(() => {
    dispatchModalCar({
      type: "set-action",
      payload: {
        backButton: function () {
          dispatchModalCar({
            type: "update",
            payload: {
              currentModal: "secondaryModal",
              currentSubModal: "tradeByType",
            },
          });
        },
      },
    });
    return () => {
      dispatchModalCar({
        type: "set-action",
        payload: {
          backButton: null,
        },
      });
    };
  }, []);
  function handleOnChange(e) {
    const vin = e.target.value;
    setVinData({ vin });
    setError({
      visible: false,
      message: "Please wait while we check your vin number",
    });
    dispatchModalCar({
      type: "update",
      payload: { msg: { type: null, message: "" } },
    });

    apiFetchVinData(vin, (err, data) => {
      setError({ visible: false, message: "" });
      if (err || data?.success === 0) {
        setError({
          visible: false,
          message: data?.error ?? "Vin number is not valid, please try again",
        });
        return;
      }
      const { success, ...restData } = data;
      setVinData({ tradeType: "vin", vin, ...restData });
    });
  }

  function handleContinueButton() {
    if (!vinData?.vin) {
      dispatchModalCar({
        type: "update",
        payload: {
          msg: {
            type: "error",
            message: "Please enter a valid vin number",
          },
        },
      });
      return;
    }

    if (error.message !== "") {
      dispatchModalCar({
        type: "update",
        payload: {
          msg: {
            type: "error",
            message: error.message,
          },
        },
      });
      return;
    }

    dispatchModalCar({
      type: "trade-set",
      payload: {
        tradeType: "vin",
        ...vinData,
      },
    });
    dispatchModalCar({
      type: "update",
      payload: {
        currentSubModal: "tradeDetails",
      },
    });
  }
  function handleBackButton() {
    dispatchModalCar({
      type: "set",
      payload: {
        currentModal: null,
      },
    });
  }
  return (
    <>
      <div className="w-full border-t border-neutral-light mt-4 p-4">
        <div>
          <Input
            value={vinData?.vin || ""}
            onChange={handleOnChange}
            style={{ minWidth: "300px" }}
            label="What is the VIN on your vehicle?"
            placeholder="Enter VIN"
            rounded="xs"
          />
        </div>
        <div>
          <table className="w-full my-4">
            <tbody>
              {Object.entries(vinData || {})
                .slice(3)
                .map(
                  ([key, value]) =>
                    value && (
                      <tr className="border-b py-2" key={key}>
                        <td>
                          <b>{key.charAt(0).toUpperCase() + key.slice(1)}</b>
                        </td>
                        <td>{value}</td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
        <Button onClick={handleContinueButton} className="my-2 w-full">
          Continue
        </Button>
        <div className="flex text-center justify-center cursor-pointer">
          <div onClick={handleBackButton} className="text-danger mt-2">
            Cancel the offer
          </div>
        </div>
      </div>
    </>
  );
}
