import axios from "axios";
import { baseURL } from "config";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { UserDataContext } from "../App";
import Loading from "./Loading";
import Input from "./shared/Input";
import Button from "./shared/Button";
import Card from "./shared/Card";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ChangePassword = () => {
  var [userData] = useContext(UserDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var { currentPassword, newPassword, confirmPassword } = form;
    setErrors({
      confirmPassword:
        confirmPassword !== newPassword
          ? "Confirm password not maching with new password!"
          : null,
      //"newPassword": (!newPassword || newPassword.length < 6 ? "Your new password must be at least 6 characters long." : null),
      newPassword:
        !newPassword || newPassword === ""
          ? "New Password cannot be empty!"
          : null,
      currentPassword:
        !currentPassword || currentPassword === ""
          ? "Empty current Password"
          : null,
    });
  };

  const sendRequestChange = useCallback(() => {
    const params = new URLSearchParams();
    params.append("current", form.currentPassword);
    params.append("new", form.newPassword);

    setIsLoading(true);
    axios({
      method: "POST",
      url: baseURL + "/seller/password/change",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: userData.token,
      },
    })
      .then((response) => {
        if (response.data.success === 0) {
          //setHasError("Current password incorrect!");
          setErrors({
            ...errors,
            currentPassword: "Current password incorrect!",
          });
        } else {
          toast.success('The password was successfully updated!');
          navigate("/profile");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong direction!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [errors, form.currentPassword, form.newPassword, userData.token]);

  useEffect(() => {
    if (Object.keys(errors).length === 0) return;
    let errorsLength = Object.values(errors).filter((r) => r).length;

    if (errorsLength === 0) {
      sendRequestChange();
    }
  }, [errors, sendRequestChange]);

  if (isLoading) {
    return <Loading>Changing password...</Loading>;
  }

  return (
    <div className="container mt-5 py-8">
      <Card className="mx-auto flex justify-center flex-col w-full lg:w-2/6">
        <div className="flex flex-col space-y-4">
          <Input
            isInvalid={!!errors.currentPassword}
            errorText={errors.currentPassword}
            className="input-control"
            onChange={(e) => setField("currentPassword", e.target.value)}
            type="password"
            label="Current password"
            placeholder="********"
          />

          <Input
            isInvalid={!!errors.newPassword}
            errorText={errors.newPassword}
            className="input-control"
            onChange={(e) => setField("newPassword", e.target.value)}
            type="password"
            label="New password"
            placeholder="********"
          />

          <Input
            className="input-control"
            errorText={errors.confirmPassword}
            isInvalid={!!errors.confirmPassword}
            onChange={(e) => setField("confirmPassword", e.target.value)}
            type="password"
            label="Confirm password"
            placeholder="********"
          />
          <Button
            variant="secondary"
            className="w-full"
            onClick={(e) => handleSubmit(e)}
          >
            Update Password
          </Button>
        </div>
      </Card>
    </div>
  );
};
