import { Link } from "react-router-dom";
import cn from "classnames";

const RegisterNavLinks = ({ list }) => {
  return (
    <>
      <div className="flex h-full items-center">
        {list.map(({ href, text, isActive }) => (
          <Link
            key={href}
            to={href}
            className={cn(
              `relative flex h-full items-center justify-center border-transparent !border-b-[3px] px-5 text-sm font-medium hover:text-black`,
              isActive
                ? "text-black !border-[#DF546A]"
                : "border-white text-[#76859A]"
            )}
          >
            {text}
          </Link>
        ))}
      </div>
    </>
  );
};

export default RegisterNavLinks;
