import axios from "axios";
import { baseURL } from "config";

export const fetchProgressVehicle = async (token) => {
  return await axios({
    method: "GET",
    url: baseURL + "/seller/progress",
    headers: {
      Authorization: token,
    },
  });
};
