import { UserDataContext } from "App";
import { baseURL } from "config.js";
import axios from "axios";
import { useEffect, useContext } from "react";

import initIDB, {
  storeToIDB,
  fetchFromIDB,
  updateIDb,
  removeFromIDb,
} from "utils/indexDBcontrol.js";
initIDB();

export const sellerPersonalDetailsHandler = (token, cb) => {
  axios({
    method: "GET",
    url: baseURL + "/seller/profile",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      cb(null, response.data);
    })
    .catch((error) => {
      cb(error);
    });
};

const uploadImageHandler = (userData, car_id, file, cb) => {
  const formData = new FormData();
  formData.append("file", file, "image.jpg");
  formData.append("car_id", car_id);

  axios({
    method: "POST",
    url: baseURL + "/seller/upload",
    headers: {
      Authorization: userData.token,
    },
    data: formData,
  })
    .then((response) => {
      cb(null, response.data);
    })
    .catch((error) => {
      cb(error);
    });
};

const base64ToBlob = (base64) => {
  let byteString = atob(base64.split(",")[1]);
  let mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  let blob = new Blob([ab], { type: mimeString });
  return blob;
};

const WindowPostMessage = () => {
  const [userData, setUserData] = useContext(UserDataContext);

  useEffect(() => {
    window.onmessage = (event) => {
      if (
        event.origin.includes("300") ||
        event.origin === window.location.origin
      )
        return;

      if (event.data === "userData") {
        let result = { response: "userData" };
        try {
          result = {
            ...JSON.parse(window.localStorage["userData"]),
            ...result,
          };
        } catch (error) {
          /* no line included */
        }
        event.source.postMessage(result, "*"); // data with json stringify or ""
      } else if (event.data.response === "setData") {
        let { response, ...data } = event.data;
        setUserData({
          ...data,
        });
        event.source.postMessage(
          { response: "hasSetData", ...event.data },
          "*"
        );
      } else if (event.data.response === "isReady") {
        event.source.postMessage({ response: "ready" }, "*");
      } else if (event.data.response === "getZipCode") {
        sellerPersonalDetailsHandler(userData?.token || "", (error, data) => {
          if (error || data?.success === 0) {
            event.source.postMessage(
              { response: "zipCode", zip_code: "" },
              "*"
            );
            return false;
          }
          event.source.postMessage(
            {
              response: "zipCode",
              zip_code: data?.zip_code ?? data?.car?.zip_code ?? "",
              userData: userData,
              dataResponse: data,
            },
            "*"
          );
          return true;
        });
      } else if (event.data.response === "uploadImage") {
        storeToIDB({
          ...event.data,
          insertedDate: new Date().getTime(),
        });
        event.source.postMessage(
          {
            response: "uploadImage",
            car_id: event.data.car_id,
            done: true,
            fileId: event.data.fileId,
          },
          "*"
        );
      } else if (event.data.response === "removeUploadImage") {
        removeFromIDb(event.data.fileId);
        event.source.postMessage(
          {
            response: "removeUploadImage",
            done: true,
            fileId: event.data.fileId,
          },
          "*"
        );
      } else if (event.data.response === "runPendingTargetId") {
        let { target_id, car_id, token } = event.data;
        fetchFromIDB(
          { type: "pendingByTargetId", target_id: target_id },
          (pendingList) => {
            for (let i = 0; i < pendingList.length; i++) {
              updateIDb(pendingList[i].fileId, {
                visited: true,
                statusUpload: "done",
                doneTime: new Date().getTime(),
              });
              let fileBlob = base64ToBlob(pendingList[i].file);
              let token_user = {
                token: userData?.token || token,
              };
              uploadImageHandler(token_user, car_id, fileBlob, () => {
                event.source.postMessage(
                  { response: "uploadCompleted", target: pendingList[i] },
                  "*"
                );
              });
            }
          }
        );
      }
    };

    window.top.postMessage(
      {
        response: "ready",
      },
      "*"
    );
  }, [setUserData, userData]);

  return <></>;
};

export default WindowPostMessage;
