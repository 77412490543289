export const variantMapper = {
  filled:
    "bg-primary-600 text-white hover:bg-primary-500 disabled:bg-primary-100",
  outline:
    "bg-white border-1.5 border-neutral-200 text-black hover:bg-neutral-50 disabled:bg-white disabled:text-neutral-200",
  "outline-secondary":
    "bg-neutral-50 text-black hover:bg-neutral-100 border-1.5 border-neutral-50 hover:border-neutral-100 disabled:bg-white disabled:text-neutral-200",
  text: "text-neutral-600 hover:bg-primary-50 hover:text-primary-600 disabled:bg-white disabled:text-neutral-300 rounded-none",
  link: "button-link text-primary-600 hover:text-primary-500",
  "link-secondary": "text-neutral-600 hover:text-neutral-500",
  // OLD
  primary: "bg-primary-light text-white hover:shadow-btn",
  dark: "bg-primary text-white hover:bg-blue-300",
  secondary: "bg-secondary text-white hover:shadow-btn",
  neutral: "bg-gray-200 text-secondary hover:bg-gray-300",
  none: "bg-transparent text-primary",
};

export const textMapper = {
  xs: "text-body-small-medium",
  base: "text-body-medium-medium",
  lg: "text-body-large-medium",
  xl: "text-body-large-medium",
};

export const sizeMapper = {
  xs: "py-2 px-3",
  base: "py-2.5 px-5",
  lg: "py-3 px-5",
  xl: "py-3.5 px-6",
};
