import axios from "axios";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { UserDataContext } from "../../../App";
import { baseURL, baseURLv2 } from "../../../config";

import { validateUSAPhoneNumber } from "utils/validate";

import { parseJSONWithDefault } from "utils/general";
import { FirebaseEvent } from "../../integrations/Firebase";
import Input from "components/shared/Input/Input";
import Select from "components/shared/Select";
import Button from "components/shared/Button";
import Alert from "components/Alert";
import OfflineImageUploader from "components/configDetails/componentDetails/OfflineImageUploader";

const ManualRegister = () => {
  const [, setUserData] = useContext(UserDataContext);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [registerStatus, setRegisterStatus] = useState("Continue");

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    year: window.localStorage.getItem("year") || "",
    make: window.localStorage.getItem("make") || "",
    model: window.localStorage.getItem("model") || "",
    trim: window.localStorage.getItem("trim") || "",
    condition_id: window.localStorage.getItem("condition_id") || "",
    mileage: window.localStorage.getItem("mileage") || "",
    zip_code: window.localStorage.getItem("zip_code") || "",
    email: window.localStorage.getItem("email") || "",
    password: window.localStorage.getItem("password") || "",
    asking_price: window.localStorage.getItem("asking_price") || "",
    phone: window.localStorage.getItem("phone") || "",
  }); // form data generated by user input

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [listMaker, setListMaker] = useState([]);
  const [listModel, setListModel] = useState([]);
  const [listTrim, setListTrim] = useState([]);

  useEffect(() => {
    FirebaseEvent("seller_method_manual");
  }, []);

  useEffect(() => {
    window.localStorage.setItem("year", form.year);
    window.localStorage.setItem("make", form.make);
    window.localStorage.setItem("model", form.model);
    window.localStorage.setItem("trim", form.trim);
    window.localStorage.setItem("condition_id", form.condition_id);
    window.localStorage.setItem("mileage", form.mileage);
    window.localStorage.setItem("zip_code", form.zip_code);
    //window.localStorage.setItem('phone', form.phone);
    window.localStorage.setItem("email", form.email);
    //window.localStorage.setItem('password', form.password);
    window.localStorage.setItem("asking_price", form.asking_price);
    window.localStorage.setItem("phone", form.phone);
  }, [form]);

  async function checkZipCodeValid(zip_code) {
    try {
      const response = await axios.get(`${baseURL}/zip/${zip_code}`);
      return !!response.data.id;
    } catch (error) {
      return false;
    }
  }
  function showError(message) {
    setAlertShow(true);
    setAlertMessage(message);
  }

  async function checkIsFormInvalid() {
    let year = form.year;
    let make = form.make;
    let model = form.model;
    let trim = form.trim;

    if (year === "") {
      showError("Year is required");
      FirebaseEvent("seller_manual_missing_year");
      return true;
    }
    if (make === "") {
      showError("Make is required");
      FirebaseEvent("seller_manual_missing_make");
      return true;
    }
    if (listModel.length > 0 && model === "") {
      showError("Model is required");
      FirebaseEvent("seller_manual_missing_model");
      return true;
    }
    if (listModel.length > 0 && trim === "") {
      showError("Trim is required");
      FirebaseEvent("seller_manual_missing_trim");
      return true;
    }

    if (isNaN(parseInt(form.asking_price || 0))) {
      showError("Asking price must be a number");
      return true;
    }
    let phone = form.phone;
    if (phone === "") {
      showError(
        "Mobile number is required and it will ONLY be used to notify you about offers"
      );
      FirebaseEvent("seller_manual_missing_phone");
      return true;
    } else if (!validateUSAPhoneNumber(phone)) {
      showError("Phone number is invalid");
      return true;
    }

    const mileage = parseInt(form.mileage);
    if (isNaN(mileage) || !mileage) {
      showError("Mileage is required");
      FirebaseEvent("seller_vin_missing_mileage");
      return true;
    }

    if (imagesToUpload.length === 0) {
      showError("At least one image is required");
      return true;
    }

    try {
      const isZipCodeValid = await checkZipCodeValid(form.zip_code);
      if (!isZipCodeValid) {
        showError("Zip code is not valid");
        return true;
      }
    } catch (error) {
      console.error(error);
      showError("An error occurred while checking zip code validity");
      return true;
    }

    return false;
  }

  async function submitForm() {
    const hasError = await checkIsFormInvalid();
    // Validate form fields
    if (hasError) return;

    setLoading(true);

    try {
      FirebaseEvent("seller_manual_submit_click");
      setRegisterStatus("Registering...");

      const formData = { ...form };
      const registerPlateRes = await apiRegisterPlate(formData);

      if (registerPlateRes.data.success !== 1) {
        FirebaseEvent("seller_manual_error");
        showError("An error occurred while submitting data");
        return;
      }

      FirebaseEvent("seller_manual_success");

      const {
        token,
        car: { id: carId },
      } = registerPlateRes.data;

      // NOTE: This step regiters user, we push data to context for later use
      setUserData({
        ...registerPlateRes.data,
      });
      setRegisterStatus("Uploading photos...");

      const photoUploadRes = await apiUploadImages(token, carId);
      if (photoUploadRes.data.success !== 1) {
        showError("An error occurred while uploading images");
        return;
      }

      setRegisterStatus("Logging in...");

      // NOTE: This is the final step, in order to complete registration we make user online and clear local storage
      setUserData((p) => {
        window.localStorage.clear();
        return { ...p, isOnline: true };
      });
    } catch (error) {
      showError("An error occurred while submitting the form");
    } finally {
      setRegisterStatus("Continue");
      setLoading(false);
    }
  }

  async function apiRegisterPlate(form) {
    let params = new URLSearchParams();
    for (var [key, value] of Object.entries({ ...form })) {
      params.append(key, value);
    }

    return await axios({
      method: "POST",
      /// /v2/seller/register-plate
      url: baseURLv2 + "/seller/register-plate",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  async function apiUploadImages(token, carId) {
    const uploadPromises = imagesToUpload.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("car_id", carId);

      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: baseURL + "/seller/upload",
          headers: {
            Authorization: token,
          },
          data: formData,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    });

    try {
      const isSuccess = (await Promise.all(uploadPromises)).every(
        (response) => response.data.success === 1
      );

      return { data: { success: isSuccess ? 1 : 0 } };
    } catch (error) {
      setLoading(false);
      showError("An error occurred while uploading images");
    }
  }

  function fetchMakerListRaw() {
    let year = form.year;
    if (year === "") return;
    axios({
      method: "GET",
      url: baseURL + "/makes/" + year,
    })
      .then((response) => {
        let arrayModels = response.data;
        setListMaker(arrayModels);
      })
      .catch((error) => {
        console.log("error:", error);
        setListMaker([]);
      });
  }

  // const fetchMakerList = useCallback(fetchMakerListRaw, [form.year]);
  const fetchMakerList = useMemo(fetchMakerListRaw, [form.year]);

  function fetchModelMakerRaw() {
    let year = form.year;
    let maker = form.make;
    if (year === "" || maker === "") return;
    axios({
      method: "GET",
      url: baseURL + "/models/" + year + "," + maker,
    })
      .then((response) => {
        let arrayModels = response.data;
        setListModel(arrayModels);
      })
      .catch((error) => {
        console.log("error:", error);
        setListModel([]);
      });
  }

  // const fetchModelMaker = useCallback(fetchModelMakerRaw, [form.year, form.make]);
  const fetchModelMaker = useMemo(fetchModelMakerRaw, [form.year, form.make]);

  function fetchTrimsMakerListRaw() {
    let year = form.year;
    let maker = form.make;
    let model = form.model;
    if (year === "" || maker === "" || model === "") return;
    axios({
      method: "GET",
      url: baseURL + "/trims/" + year + "," + maker + "," + model,
    })
      .then((response) => {
        let arrayModels = response.data;

        setListTrim(arrayModels);
      })
      .catch((error) => {
        console.log("error:", error);
        setListTrim([]);
      });
  }

  // const fetchTrimsMakerList = useCallback(fetchTrimsMakerListRaw, [form.year, form.make, form.model]);
  const fetchTrimsMakerList = useMemo(fetchTrimsMakerListRaw, [
    form.year,
    form.make,
    form.model,
  ]);

  let arrayYearList = Array.from({
    length: new Date().getFullYear() + 2 - 1990,
  }).map((item, index) => 1990 + index);
  arrayYearList.reverse();

  return (
    <>
      <Alert message={alertMessage} show={alertShow} setShow={setAlertShow} />
      <form className="shrink-0 w-full">
        <div className="flex flex-col">
          <div className="flex flex-col space-y-4">
            <div className="w-full">
              <Select
                label="Year"
                defaultValue={form.year}
                placeholder="Choose Year"
                onChange={(e) => {
                  setForm((i) => {
                    return { ...i, year: e.target.value };
                  });
                }}
                labelOptions={arrayYearList}
                valueOptions={arrayYearList}
              />
            </div>

            <div className="w-full">
              <Select
                label="Make"
                defaultValue={form.make}
                placeholder="Choose Make"
                disabled={form.year === ""}
                onChange={(e) => {
                  setForm((i) => {
                    return { ...i, make: e.target.value };
                  });
                }}
                labelOptions={listMaker}
                valueOptions={listMaker}
              />
            </div>

            <div className="w-full">
              <Select
                label="Model"
                defaultValue={form.model}
                disabled={form.make === ""}
                placeholder="Choose Model"
                onChange={(e) => {
                  setForm((i) => {
                    return { ...i, model: e.target.value };
                  });
                }}
                labelOptions={listModel}
                valueOptions={listModel}
              />
            </div>

            <div className="w-full">
              <Select
                label="Trim"
                defaultValue={form.trim}
                placeholder="Choose Trim"
                disabled={form.model === ""}
                onChange={(e) => {
                  setForm((i) => {
                    return { ...i, trim: e.target.value };
                  });
                }}
                labelOptions={listTrim}
                valueOptions={listTrim}
              />
            </div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2">
              <div className="w-full md:w-1/2">
                <Input
                  value={form.mileage}
                  type="number"
                  onChange={(e) => {
                    setForm((i) => {
                      return {
                        ...i,
                        mileage: e.target.value,
                      };
                    });
                  }}
                  label="Mileage"
                />
              </div>
              <div className="w-full md:w-1/2">
                <Input
                  value={form.zip_code}
                  type="number"
                  onChange={(e) => {
                    setForm((i) => {
                      return {
                        ...i,
                        zip_code: e.target.value,
                      };
                    });
                  }}
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="w-full">
              <Input
                value={form.phone}
                format="phoneNumber"
                onChange={(e) => {
                  setForm((i) => {
                    return { ...i, phone: e.target.value };
                  });
                }}
                label="Mobile Phone"
              />
            </div>
            <div className="w-full mt-6">
              <OfflineImageUploader
                onImagesListChange={(files) => setImagesToUpload(files)}
              />
            </div>
            <div className="w-full mt-6">
              <Button
                className="w-full"
                variant="secondary"
                onClick={submitForm}
                disabled={loading}
              >
                {registerStatus}
              </Button>
            </div>
            <div className="accept-terms-text">
              By signing up on our platform you agree to our{" "}
              <Link
                target="_blank"
                to="https://autostoday.com/terms-of-use/"
                rel="noreferrer"
              >
                Terms of Use
              </Link>
            </div>
          </div>
          <div className="hero-box__form__bottom">
            <div className="form-bt-progress">
              <span className="span active"></span>
              <span className="span"></span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ManualRegister;
