import axios from 'axios';
import { baseURL } from 'config';

export function apiFetchOffers(token,callback) {
    axios({
        method: "GET",
        url: baseURL + "/seller/bids",
        headers: {
            'Authorization': token
        }
    }).then((response)=>{
        callback(null,response.data);
    }).catch((error)=>{
        callback(error,null);
    });
}