import { forwardRef } from "react";

const Checkbox = forwardRef(
  (
    {
      label,
      placeholder,
      checked = false,
      onChange = () => {},
      children,
      className = "",
      ...otherAttributes
    },
    ref
  ) => {
    const id = Math.random().toString(36).substring(2, 15);

    return (
      <div className="relative flex items-center">
        <input
          {...otherAttributes}
          ref={ref}
          id={id}
          placeholder={placeholder}
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          className={`opacity-0 absolute top-0 bottom-0 left-0 w-6 cursor-pointer ${className}`}
        />
        <div className="w-6 h-6 flex items-center justify-center bg-[#F2F4F6] mr-2">
          {checked && (
            <svg
              className="w-4 h-4 text-primary"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          )}
        </div>
        {label && (
          <label htmlFor={id} className="text-base font-bold text-[#6A5E55]">
            {label}
          </label>
        )}
      </div>
    );
  }
);

export default Checkbox;
