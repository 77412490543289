import { UserIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

const UserDropdown = ({ onLogout }) => {
	const dropdownRef = useRef(null);
	const [accountPopover, setAccountPopover] = useState(false);

	useEffect(() => {
		if (!dropdownRef.current) return;

		const handleOutsideClick = (event) => {
			if (!dropdownRef.current.contains(event.target)) {
				setAccountPopover(false);
			}
		};

		document.addEventListener("click", handleOutsideClick);
		return () => {
			document.removeEventListener("click", handleOutsideClick);
		};
	}, []);

	return (
		<div className="relative" ref={dropdownRef}>
			<button
				id="dropdownUserAvatarButton"
				data-dropdown-toggle="dropdownAvatar"
				className={cn(
					"flex text-sm rounded-full md:mr-0 p-2 border-2 border-primary ease-in duration-300 hover:bg-primary hover:text-white",
					accountPopover ? "bg-primary text-white" : "text-primary"
				)}
				type="button"
				onClick={() => setAccountPopover(!accountPopover)}
			>
				<span className="sr-only">Open user menu</span>
				<UserIcon className="h-4 w-4 lg:w-5 lg:h-5" />
			</button>
			<div
				id="dropdownAvatar"
				className={`${
					accountPopover === false ? "hidden" : "absolute"
				} z-10 bg-white divide-y divide-gray-100 rounded rounded-tl-none border-t-2 border-primary-light shadow w-44 mt-2 right-0 left-auto dark:bg-gray-700 dark:divide-gray-600`}
			>
				<ul
					className="py-2 text-xl text-gray-700 dark:text-gray-200"
					aria-labelledby="dropdownUserAvatarButton"
				>
					<li className="mt-3 text-center">
						<Link
							className="hover:text-secondary text-black px-4 py-2 text-sm"
							to="/change-password"
							onClick={() => setAccountPopover(false)}
						>
							Change Password
						</Link>
					</li>
					<li className="mt-3 text-center">
						<Link
							className="hover:text-secondary text-black px-4 py-2 text-sm"
							to="/profile"
							onClick={() => setAccountPopover(false)}
						>
							Profile
						</Link>
					</li>
					<li className="mt-3 text-center hover:bg-danger hover:text-white text-black rounded-b">
						<button
							className="px-4 py-2 text-sm"
							onClick={() => {
								onLogout();
								setAccountPopover(false);
							}}
						>
							Logout
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default UserDropdown;
