import cn from "classnames";

const ModalBody = ({ children, isFullScreen = false }) => {
  return (
    <div
      className={cn({
        "bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4": !isFullScreen,
        "bg-black h-full":
          isFullScreen,
      })}
    >
      {children}
    </div>
  );
};

export default ModalBody;
