import RegisterNavbar from "./RegisterNavbar";
import RegisterHero from "./RegisterHero";
import RegisterHowItWorksHero from "./RegisterHowItWorksHero";
import RegisterFooter from "./RegisterFooter";
import RecentSold from "components/home/section/RecentSold";
import RegisterContextProvider from "./contexts/RegisterContext";
import { Helmet } from "react-helmet";

const Register = () => {
  return (
    <>
      <Helmet>
        <title>Sell or Trade Your Car Online - AutosToday</title>
        <meta
          name="title"
          content="Sell or Trade Your Car Online  - AutosToday"
        />
        <meta
          name="description"
          content="Whether you're looking to sell or trade, our user-friendly platform lets you list your vehicle with ease. Join now to unlock a world of potential buyers and seamless selling opportunities. AutosToday empowers you to take control of your selling experience — register, list, and connect with interested buyers or traders. Sell or trade your vehicle hassle-free with AutosToday!"
        />
      </Helmet>
      <div className="overflow-hidden bg-white w-full">
        <RegisterNavbar />

        <RegisterContextProvider>
          <RegisterHero />
        </RegisterContextProvider>

        <RegisterHowItWorksHero />

        <section className="container max-w-6xl">
          <RecentSold />
        </section>
        <RegisterFooter />
      </div>
    </>
  );
};

export default Register;
