import { useContext } from "react";
import { ModalCarContext } from "../Context/ModalCarContext";
import TestDrive from "./SubSecondary/TestDrive";
import MoreInfo from "./SubSecondary/MoreInfo";
import YourAccount from "./SubSecondary/YourAccount";
import Calculator from "./SubSecondary/Calculator";
import TradeByType from "./SubTrade/TradeByType";
import TradeVinNumber from "./SubTrade/TradeVinNumber";
import TradeDetails from "./SubTrade/TradeDetails";
import TradeImages from "./SubTrade/TradeImages";
import TradeLicensePlate from "./SubTrade/TradeLicensePlate";
import TradeManual from "./SubTrade/TradeManual";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

const BuyCarWizard = () => {
  const { modalCar } = useContext(ModalCarContext);
  const vehiclePhotos = modalCar?.photos?.split(",")[0];
  return (
    <>
      {modalCar?.currentSubModal !== "tradeByType" && (
        <div className="flex flex-row gap-2">
          <div className="w-fit">
            <img
              className={cn("rounded-lg object-cover", {
                "h-[180px] w-[180px]":
                  modalCar?.currentSubModal !== "calculator",
                "h-[160px] w-[260px]":
                  modalCar?.currentSubModal === "calculator",
              })}
              src={vehiclePhotos}
              alt={modalCar.title}
            />
          </div>
          <div
            className={cn("w-7/12 space-y-2", {
              "lg:w-7/12 flex flex-col":
                !modalCar?.currentSubModal === "calculator",
              "lg:w-9/12 flex pl-4 flex-row justify-between items-center":
                modalCar?.currentSubModal === "calculator",
            })}
          >
            <div className="flex flex-col">
              <span className="text-xs w-fit px-3 py-[2px] bg-[#EEE] leading-[normal] rounded">
                {modalCar?.year}
              </span>
              <span className="text-lg py-2 text-[#006B9F] leading-[normal]">
                {`${modalCar?.make_name} ${modalCar?.model_name}`}
              </span>
              <span className="flex gap-2 text-secondary font-medium text-sm leading-[normal] pb-2 items-center">
                <MapPinIcon className="w-4 h-4" />
                {modalCar?.location}
              </span>
              <span className="flex gap-2 text-secondary font-medium text-sm leading-[normal] items-center">
                <PhoneIcon className="w-4 h-4" />
                {modalCar?.dealer_phone}
              </span>
            </div>
            <div className= "flex flex-col gap-0.5">
              <p className="text-primary text-[22px] font-normal leading-none tracking-[0.3px]">Car price</p>
              <p className="text-secondary font-medium text-[32px] leading-none tracking-[0.3px]">$ {modalCar?.price?.toLocaleString()}</p>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap flex-col justify-center items-center">
        <div className="flex justify-center my-2">
          {modalCar?.msg?.type === "success" && (
            <span className="msg-success-speed" role="alert">
              {modalCar.msg.message}
            </span>
          )}

          {modalCar?.msg?.type === "error" && (
            <span className="msg-error-speed" role="alert">
              {modalCar.msg.message}
            </span>
          )}
        </div>
        {modalCar?.currentSubModal === "testDrive" && <TestDrive />}
        {modalCar?.currentSubModal === "moreInfo" && <MoreInfo />}
        {modalCar?.currentSubModal === "yourAccount" && <YourAccount />}
        {modalCar?.currentSubModal === "calculator" && <Calculator />}

        {/* start line trade in */}
        {modalCar?.currentSubModal === "tradeByType" && <TradeByType />}

        {modalCar?.currentSubModal === "tradeByVin" && <TradeVinNumber />}
        {modalCar?.currentSubModal === "tradeByLicensePlate" && (
          <TradeLicensePlate />
        )}
        {modalCar?.currentSubModal === "tradeByManual" && <TradeManual />}

        {modalCar?.currentSubModal === "tradeDetails" && <TradeDetails />}
        {modalCar?.currentSubModal === "tradeImages" && <TradeImages />}
        {/* end line trade in */}
      </div>
    </>
  );
};

export default BuyCarWizard;
