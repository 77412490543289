import React, { useContext, useEffect } from "react";
import BuyCarWizard from "./BuyCarWizard";
import Modal from "components/shared/Modal";
import { sellerPersonalDetailsHandler } from "components/WindowPostMessage";
import { UserDataContext } from "App";
import { apiFetchTaxRateFromZipCode } from "../request/apiFetchTaxRateFromZipCode";
import { ModalCarContext } from "../Context/ModalCarContext";

const ModalCar = ({ isOpen, onClose = () => {} }) => {
	const [userData] = useContext(UserDataContext);
	const { modalCar, dispatchModalCar } = useContext(ModalCarContext);

	useEffect(() => {
		if (
			!userData?.token ||
			modalCar?.details_loading ||
			modalCar?.user_email ||
			modalCar?.user_name
		)
			return;

		sellerPersonalDetailsHandler(userData.token, (error, data) => {
			if (error) throw error;

			let user_name = "";
			data?.first_name && (user_name += data?.first_name);
			data?.last_name && (user_name += " " + data?.last_name);
			dispatchModalCar({
				type: "set",
				payload: {
					user_first_name: data?.first_name || "",
					user_last_name: data?.last_name || "",
					user_name: user_name,
					user_phone: data?.phone || "",
					user_email: data?.email || "",
					user_zip_code: data?.zip_code || "",
				},
			});

			if (data?.zip_code) {
				apiFetchTaxRateFromZipCode(data?.zip_code, (error, data) => {
					if (error || data?.success === 0) return;
					dispatchModalCar({
						type: "calc-update",
						payload: {
							taxRate: data?.rate || 0,
						},
					});
				});
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (modalCar?.currentModal === null) {
			onClose();
		}
	}, [modalCar?.currentModal, onClose]);

	return (
		<Modal
			show={isOpen}
			onHide={onClose}
			size={
				modalCar?.currentSubModal === "calculator" ||
				modalCar?.currentSubModal === "tradeByType"
					? "lg"
					: "sm"
			}
		>
			<Modal.Header>
				<Modal.Title>{modalCar?.title}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<BuyCarWizard />
			</Modal.Body>
		</Modal>
	);
};

export default ModalCar;
