import React from 'react';
import SkeletonCarCard from './SkeletonCarCard';

const SkeletonBrowseCars = () => {
  return (
    <div className="bg-[#F6F6F6]">
      <div className="py-4 xl:py-9">
        <div className="mb-2 mx-4 xl:ml-[10%] bg-gray-300 h-8 w-24"></div>
        <div className="px-4 w-full xl:pl-[10%]">
          <div className="flex gap-8 h-96 w-full">
          {Array.from({ length: 5 }).map((_, index) => (
            <SkeletonCarCard key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonBrowseCars;
