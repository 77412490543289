import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, EffectFade, Navigation, Zoom } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import { imageURL } from "config";
import React, { useState } from "react";
import { SWIPER_CONFIG } from "./ImagesCarousel.constants";
import Skeleton from "components/Skeleton";
import useModal from "hooks/use-modal.hook";
import ModalCarousel from "components/ModalCarousel";

const ImagesCarousel = ({ isLoading = false, images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const carouselModal = useModal();

  if (isLoading) {
    return <Skeleton type="mainCarousel" />;
  }

  return (
    <div className="select-none mb-3 md:mb-7">
      <Swiper
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Thumbs, EffectFade]}
        effect="fade"
      >
        {images?.map((image, index) => {
          const url = imageURL + image.name;
          return (
            <SwiperSlide
              key={image?.src ?? url}
              onClick={() => {
                carouselModal.openModal();
                setActiveSlideIndex(index);
              }}
            >
              <img
                className="block w-full h-72 sm:h-80 md:h-[24rem] xl:h-[21.75rem] object-cover object-center rounded-lg cursor-pointer"
                src={image?.src ?? url}
                alt={image?.name}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper
        modules={[Thumbs, Navigation, Zoom]}
        navigation
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
        className="mt-2"
        {...SWIPER_CONFIG}
      >
        {images?.map((image) => {
          const url = imageURL + image.name;
          return (
            <SwiperSlide className="cursor-pointer" key={image?.src ?? url}>
              <img
                className="block w-full h-16 object-cover rounded-lg"
                src={image?.src ?? url}
                alt={image?.name}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {carouselModal.isOpen && (
        <ModalCarousel
          isOpen={carouselModal.isOpen}
          onClose={carouselModal.closeModal}
          images={images}
          activeSlideIndex={activeSlideIndex}
        />
      )}
    </div>
  );
};

export default ImagesCarousel;
