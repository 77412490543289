import { UserDataContext } from "App";
import axios from "axios";
import UploadIcon from "components/componenetIcons/UploadIcon";
import Button from "components/shared/Button";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { baseURL } from "config";
import { useContext, useState, useEffect, useRef } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

const TradeImages = () => {
	const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
	const [userData, setUserData] = useContext(UserDataContext);
	const [files, setFiles] = useState([]);

	const fileRef = useRef(null);
	const refDropzone = useRef(null);

	const handleUpload = () => {
		fileRef.current.click();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = e.dataTransfer.files;
		var listFiles = [];
		for (let i = 0; i < files.length; i++) {
			if (files[i].type.startsWith("image/")) {
				listFiles.push(files[i]);
			}
		}
		appendToState(listFiles);
	};

	function appendToState(listFiles) {
		setFiles((prev) => {
			return [
				...prev,
				...listFiles.map((item) => {
					let newImagesObj = {
						File: item,
						local_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
						progress: 0,
						status: "pending",
					};
					return newImagesObj;
				}),
			];
		});
	}

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.dataTransfer.dropEffect = "copy";
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		var listFiles = [];
		for (let i = 0; i < files.length; i++) {
			if (files[i].type.startsWith("image/")) {
				listFiles.push(files[i]);
			}
		}
		e.target.value = "";
		appendToState(listFiles);
	};

	const removeFile = (localIdSelected) => {
		setFiles((prev) => {
			return prev.filter((item) => item.local_id !== localIdSelected);
		});
	};

	const handleContinue = () => {
		dispatchModalCar({
			type: "update",
			payload: {
				currentSubModal: "calculator",
			},
		});
		dispatchModalCar({
			type: "calc-update",
			payload: {
				hasTradeIn: true,
			},
		});
		dispatchModalCar({
			type: "trade-update",
			payload: {
				images: files,
			},
		});
	};

	return (
		<div className="w-full border-t border-neutral-light mt-4 p-4 flex justify-center flex-col">
			<div>
				<input
					onChange={handleFileChange}
					multiple
					ref={fileRef}
					type="file"
					hidden
				/>
				<div style={{ color: "#535353", textAlign: "left", marginTop: "25px" }}>
					Upload photos (optional)
				</div>
				<div
					onDragOver={handleDragOver}
					onDrop={handleDrop}
					ref={refDropzone}
					onClick={handleUpload}
					className="flex justify-center items-center mt-5 border-dashed border-neutral bg-neutral-light rounded min-w-[400px] min-h-[250px] cursor-pointer"
				>
					<ArrowUpTrayIcon className=" w-20 h-20 text-neutral" />
				</div>
				<div className="flex justify-center">
					<div className="c-pointer text-neutral mt-2" onClick={handleUpload}>
						Drag & drop files or{" "}
						<b className="cursor-pointer text-primary">Browse</b>
					</div>
				</div>
				{files.length > 0 && (
					<div className="my-3 flex flex-wrap justify-center">
						{files.map((item) => {
							return (
								<div className="image-control-tri m-2" key={item.local_id}>
									<span
										onClick={() => removeFile(item.local_id)}
										className="remove"
									>
										x
									</span>
									<img src={URL.createObjectURL(item.File)} alt="" />
								</div>
							);
						})}
					</div>
				)}
				<Button
					variant="primary"
					onClick={handleContinue}
					label="Continue"
					className="w-full py-2 mt-4"
				/>
			</div>
		</div>
	);
};

export default TradeImages;

// function apiUploadImage({
//     file, car_id, token,controller
// }, {
//     progressCallback = () => { },
//     doneCallback = () => { }
// }) {
//     const formData = new FormData();
//     formData.append("file", file);
//     formData.append("car_id", car_id);
//     axios({
//         method: "POST",
//         url: baseURL + "/seller/upload",
//         headers: {
//             'Authorization': token
//         },
//         data: formData,
//         signal: controller.signal,
//         onUploadProgress: function (progressEvent) {
//             let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//             progressCallback(percentCompleted);
//         }
//     }).then((response) => {
//         doneCallback(null, response.data);
//     }).catch((error) => {
//         doneCallback(error);
//     });
// }

// function apiDeleteRequestImage({id,token},callback){
//     axios({
//         method: "DELETE",
//         url: baseURL + "/seller/car/photo/"+id,
//         headers: {
//             'Authorization': token
//         },
//     }).then((response)=>{
//         callback(null,response.data);
//     }).catch((error)=>{
//         callback(error);
//     });
// }
