import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Loading from "../Loading";
import ReciverMsg from "./messageTypes/ReciverMsg";
import SenderMsg from "./messageTypes/SenderMsg";
import ReciverMsgImage from "./messageTypes/ReciverMsgImage";
import SenderMsgImage from "./messageTypes/SenderMsgImage";
import UploadMsgFile from "./messageFunctions/UploadMsgFile";
import axios from "axios";
import { baseURL } from "../../config";
import { UserDataContext } from "../../App";
import ModalImage from "components/componenetInput/ModalImage";
import { ChevronRightIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { ChatContext } from "./contexts/ChatContext";
import { find, findIndex } from "lodash";

const ChatMessage = ({ onTabClick = () => {} }) => {
  const [modalImageShow, setModalImageShow] = useState({
    show: false,
    image: "",
  }); // onclick image fullscreen
  const userData = useContext(UserDataContext)[0]; // userData
  const {
    chatRooms: { rooms, selected, loading },
    setChatRooms,
  } = useContext(ChatContext);
  const chatMessages = useMemo(
    () => find(rooms, { roomData: selected })?.chatMessages ?? [],
    [rooms, selected]
  );
  const textAreaRef = useRef(null); // textArea for sending message
  const bodyChat = useRef(null); // body to view chat messages
  const progressBarRef = useRef(null); // progressBar for imagew upload

  // send button function
  function sendMessage() {
    if (textAreaRef.current.value === "") return;
    // get message
    let messageToSend = textAreaRef.current.value;
    // clean up duty
    textAreaRef.current.value = "";
    // set params
    let params = new URLSearchParams();
    params.append("room_id", selected?.room_id);
    params.append("message", messageToSend);
    // send request
    axios({
      method: "POST",
      url: baseURL + "/seller/message",
      data: params,
      headers: {
        Authorization: userData.token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() => {
        const newMessage = {
          message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
          generated_local: true,
          message: messageToSend,
          message_date: new Date().toISOString(),
          file_name: null,
          you: 1,
        };

        setChatRooms((prevChatRooms) => {
          const { rooms } = prevChatRooms;
          const newRooms = [...rooms];
          const roomIndex = findIndex(
            newRooms,
            (room) => room.roomData.room_id === parseInt(selected?.room_id)
          );
          if (roomIndex === -1) {
            return prevChatRooms;
          }
          const chatMessages = [...newRooms[roomIndex].chatMessages];
          chatMessages.push(newMessage);
          newRooms[roomIndex].chatMessages = chatMessages;
          return {
            ...prevChatRooms,
            rooms: newRooms,
          };
        });
      })
      .catch((error) => {
        console.log("response error:", error);
      });
  }

  function textAreaKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  }

  useEffect(() => {
    //scoll chat to the bottom
    if (bodyChat && bodyChat.current)
      bodyChat.current.scrollTop = Number.MAX_SAFE_INTEGER;
  }, [chatMessages]);

  return (
    <>
      <div className="chat-header flex flex-col justify-end px-3 pt-4 pb-0 xl:max-h-28 xl:h-full xl:pb-3 xl:border-b border-[#EBEBEB] bg-[#0831510D] xl:bg-transparent">
        <div className="flex items-center border-b border-[#EBEBEB] xl:border-none w-full pb-3">
          <div className="ms-3 flex justify-between items-center w-full">
            <div>
              <div className="name-text-header text-2xl text-[#000]">
                {selected?.dealer_name ?? ""}
              </div>
              <div className="text-[#838383CC] text-base">
                {selected?.location ?? ""}
              </div>
            </div>
          </div>
        </div>
        <div
          role="button"
          className="flex justify-between pointer-cursor items-center xl:hidden py-3"
          onClick={() => onTabClick(2)}
        >
          <p className="font-normal text-sm text-primary mb-0">
            See all bids from{" "}
            <span className="font-bold">{selected?.dealer_name}</span>
          </p>
          <ChevronRightIcon className="w-5 h-5" />
        </div>
      </div>

      {loading ? (
        <div className="flex items-center h-full justify-center">
          <Loading />
        </div>
      ) : (
        <div
          ref={bodyChat}
          className="overflow-y-auto h-full custom-scrollbar space-y-2 pt-1 pb-3 px-2"
        >
          {chatMessages.map((item, index) => {
            if (item.file_name !== null && item.you === 0)
              return (
                <ReciverMsgImage
                  modal={[modalImageShow, setModalImageShow]}
                  key={item.message_id}
                  item={item}
                />
              );
            else if (item.file_name !== null && item.you === 1)
              return (
                <SenderMsgImage
                  modal={[modalImageShow, setModalImageShow]}
                  key={item.message_id}
                  item={item}
                />
              );

            if (item.you === 0) {
              const isSameSender = chatMessages[index - 1]?.you !== 0;
              return (
                <ReciverMsg
                  key={item.message_id}
                  showSenderName={isSameSender}
                  item={item}
                />
              );
            } else {
              const isSameSender = chatMessages[index - 1]?.you !== 1;
              return (
                <SenderMsg
                  key={item.message_id}
                  showSenderName={isSameSender}
                  item={item}
                />
              );
            }
          })}
          <div
            className="progressUploadFile mb-1 relative"
            style={{ display: "none" }}
          >
            <div
              className="w-[50%] bg-gray-200 rounded-xl rounded-b-none flex flex-col items-center justify-center ml-auto"
              ref={progressBarRef}
            >
              <div className="bg-gray w-full h-28 p-0.5 text-center text-xs flex items-center justify-center font-medium leading-none text-black"></div>
            </div>
          </div>
        </div>
      )}

      {!loading && rooms.length === 0 ? null : (
        <div className="emoji-list-r">
          <ModalImage
            setModalImageShow={setModalImageShow}
            image={modalImageShow.image}
            show={modalImageShow.show}
            onHide={() => {
              setModalImageShow((i) => {
                return { ...i, show: false };
              });
            }}
          />
          <div className="px-3 mb-3">
            <div className="flex items-center chat-input-container rounded-[50px] bg-[#F9F9F9] p-2">
              {/* File upload */}
              <UploadMsgFile
                chatMessages={chatMessages}
                roomData={selected}
                progressBarRef={progressBarRef}
              />

              <textarea
                maxLength={200}
                ref={textAreaRef}
                onKeyDown={textAreaKeyDown}
                className="focus:outline-none h-10 py-2.5 max-w-full w-full bg-transparent resize-none ml-2 text-sm"
                placeholder="Write your message..."
              />
              <button
                onClick={sendMessage}
                className="bg-secondary rounded-full p-2"
              >
                <PaperAirplaneIcon className="w-5 h-5 text-white -rotate-45" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessage;
