import { imageURL } from "config";
import React from "react";
import { FaTrash } from "react-icons/fa";

const OfflineOneImageBoxRaw = ({ imagesList, onImageDelete, item }) => {
  function deleteImage() {
    onImageDelete([...imagesList.filter((x) => x?.id !== item?.id)]);
  }

  if (!item) return <div className="image-uploaded-box"></div>;

  return (
    <div
      onClick={deleteImage}
      className="image-uploaded-box !h-16 !w-[74px] !min-w-[74px] cursor-pointer"
    >
      {(typeof item.name !== "undefined" ||
        typeof item.localURL !== "undefined") && (
        <>
          <span className="remove-image-button !right-[50%] !top-[50%] !translate-x-[50%] -translate-y-[50%]">
            <FaTrash color="#E71D36" />
          </span>

          <img
            src={item.localURL ? item.localURL : imageURL + item.name}
            alt=""
          />
        </>
      )}
    </div>
  );
};

export const OfflineOneImageBox = React.memo(OfflineOneImageBoxRaw);
