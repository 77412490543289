import React from "react";

const Select = React.forwardRef(
  (
    {
      children,
      className,
      defaultValue,
      placeholder,
      label,
      labelOptions = [],
      valueOptions = [],
      selected,
      invalid,
      ...attrProps
    },
    ref
  ) => {
    const id = Math.random().toString(36).substring(2, 15);

    return (
      <div className="relative">
        {label ? (
          <label htmlFor={id} className="flex text-md font-normal text-primary mb-1">
            {label}
          </label>
        ) : null}
        <select
          ref={ref}
          id={id}
          className={`block pt-3 pb-2.5 pl-4 pr-4 w-full text-md text-primary font-normal bg-neutral-light border-0 appearance-none focus:outline-none focus:ring-1 focus:border-primary peer ${className}`}
          defaultValue={defaultValue ?? ""}
          {...attrProps}
        >
          <option value="" disabled>
            {placeholder}
          </option>

          {labelOptions.map((item, index) => {
            const key = `${item} + ${index}`;
            return (
              <option key={key} value={valueOptions[index]}>
                {item}
              </option>
            );
          })}
        </select>
        {(invalid !== undefined || "") && (
          <p className="italic-info-error">{invalid}</p>
        )}
      </div>
    );
  }
);

export default Select;
