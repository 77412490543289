import Input from "components/shared/Input/Input";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { useContext, useState } from "react";
import { validateEmail } from "utils/validate";
import axios from "axios";
import { baseURL } from "config";
import { UserDataContext } from "App";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/shared/Button";
import { toast } from "react-toastify";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

const TestDrive = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const userData = useContext(UserDataContext)[0];

  const [form, setForm] = useState({
    name: modalCar?.user_name || "",
    email: modalCar?.user_email || "",
    phone: modalCar?.user_phone || "",
    visit_date: modalCar?.user_visit_date || "",
  });

  const [invalidForm, setInvalidForm] = useState({
    name: "",
    email: "",
    phone: "",
    visit_date: "",
  });

  function handleFormChange(e) {
    setInvalidForm({
      ...invalidForm,
      [e.target.name]: "",
    });
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function handleSubmitRequest() {
    if (
      form.visit_date === "" ||
      form.name === "" ||
      !validateEmail(form.email)
    ) {
      let newInvalid = {};
      form.visit_date === "" &&
        (newInvalid["visit_date"] = "Visit date is required!");
      form.name === "" && (newInvalid["name"] = "Name is required!");
      !validateEmail(form.email) &&
        (newInvalid["email"] = "Invalid email format!");
      setInvalidForm({
        ...invalidForm,
        ...newInvalid,
      });
      return;
    }
    ///////
    apiRequestTestDrive(
      {
        code: modalCar.code,
        name: form.name,
        email: form.email,
        phone: form.phone,
        visit_date: form.visit_date,
        token: userData?.token,
      },
      (error, data) => {
        if (error || data?.success === 0) {
          dispatchModalCar({
            type: "update",
            payload: {
              msg: {
                type: "error",
                message:
                  data?.error ??
                  "Something went wrong! Please try again later.",
              },
            },
          });
          return;
        }

        dispatchModalCar({
          type: "update",
          payload: {
            user_name: form.name,
            user_email: form.email,
            user_phone: form.phone,
            user_visit_date: form.visit_date,
            currentModal: null,
          },
        });

        toast.success(
          "Your request for a test drive has been submitted successfully! Our team will be in touch with you shortly."
        );
      }
    );
    ///////
  }

  function handleBackButton() {
    dispatchModalCar({
      type: "set",
      payload: {
        currentModal: null,
      },
    });
  }

  let tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  return (
    <div className="w-full border-t border-neutral-light mt-4 p-4">
      <div className="flex flex-col gap-2">
        <Input
          value={form.name}
          isInvalid={invalidForm.name}
          errorText={invalidForm.name}
          onChange={handleFormChange}
          name="name"
          rounded="xs"
          label={<div className="text-[#111827] text-base">Name</div>}
        />
        <Input
          value={form.email}
          isInvalid={invalidForm.email}
          errorText={invalidForm.email}
          onChange={handleFormChange}
          name="email"
          rounded="xs"
          label={<div className="text-[#111827] text-base">Email Address</div>}
        />
        <Input
          value={form.phone}
          isInvalid={invalidForm.phone}
          errorText={invalidForm.phone}
          format="phoneNumber"
          onChange={handleFormChange}
          name="phone"
          rounded="xs"
          label={
            <div className="text-[#111827] text-base">
              Phone Number{" "}
              <small className="text-muted text-xs">(Optional)</small>
            </div>
          }
          placeholder="Enter Phone here..."
        />

        {/* <Input value={form.visit_date} isInvalid={invalidForm.visit_date} type="datetime-local" onInput={handleFormChange} name="visit_date" label="Date & Time *" placeholder="Enter Date and time here..." /> */}
        <div className="text-[#111827] text-base font-medium my-1">
          Date & Time *
        </div>
        <div className="input-calc-speed flex items-center">
          <CalendarDaysIcon className="me-1 w-4 h-4" />
          <ReactDatePicker
            wrapperClassName="w-full"
            className="input-no-decoration w-full rounded-[2px]"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onFocus={(e) => {
              e.preventDefault();
              e.target.blur();
            }}
            minDate={tomorrowDate}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Select Date & Time"
            selected={form.visit_date}
            onChange={(time) =>
              handleFormChange({ target: { name: "visit_date", value: time } })
            }
          />
        </div>
        {invalidForm.visit_date && (
          <div className="text-danger text-sm">{invalidForm.visit_date}</div>
        )}
      </div>

      <Button
        onClick={handleSubmitRequest}
        className="w-full mt-6 text-base font-medium"
      >
        Request a test drive
      </Button>
      <div className="flex justify-center">
        <div
          onClick={handleBackButton}
          className="text-danger mt-2 cursor-pointer"
        >
          Return to Car Page
        </div>
      </div>
    </div>
  );
};

export default TestDrive;

export function apiRequestTestDrive(
  { token, code, name, email, phone, visit_date },
  cb
) {
  // -/v1/seller/inventory/:code/test-drive
  let params = new URLSearchParams();
  params.append("name", name);
  params.append("email", email);
  phone && params.append("phone", phone);
  params.append("visit_date", visit_date);

  axios({
    method: "post",
    url: `${baseURL}/seller/inventory/${code}/test-drive`,
    data: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
  })
    .then((res) => {
      cb(null, res.data);
    })
    .catch((err) => {
      cb(err, null);
    });
}
