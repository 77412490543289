import { Link } from "react-router-dom";

const RegisterHowItWorksHero = () => {
  return (
    <section className="container max-w-6xl px-4 md:px-0 mb-12 pt-20">
      <div className="flex border-b-2 border-[#6DD7FC] py-20 space-x-20">
        <div className="shrink-0 hidden md:flex w-1/3">
          <img
            src="/images/register/complete-your-autos-today-profile.png"
            alt=""
          />
        </div>
        <div className="w-full md:w-2/3">
          <h3 className="text-primary text-3xl font-bold mb-4">
            How it Works
          </h3>
          <span className="text-primary text-xl font-bold mb-4">
            It’s Easy to Use
          </span>
          <ul className="list-decimal mb-4">
            <li>
              Choose your platform. Use the website or download the free Autos
              Today app.
            </li>
            <li>
              Add your vehicle. Follow the prompts to upload your vehicle
              information.
            </li>
            <li>
              Get bids and finalize your deal! Once your vehicle has been added,
              alerts are sent to pre-screened car dealerships nationwide. All
              messaging can be done securely and privately through the app.
            </li>
          </ul>
          <h3 className="text-primary text-xl font-bold mb-4">
            You’ll also get...
          </h3>
          <div className="flex flex-col md:flex-row md:items-center gap-4 mb-4">
            <img
              className="w-[144px] md:w-auto object-contain"
              src="/images/register/autocheck.svg"
              alt=""
            />
            <p>
              Free AutoCheck vehicle history report to provide verified vehicle
              information to potential buyers
            </p>
          </div>
          <div className="flex flex-col md:flex-row md:items-center gap-4 mb-4">
            <img
              className="w-[144px] md:w-auto object-contain"
              src="/images/register/jd-power.svg"
              alt=""
            />
            <p>
              Free trade-in value from JD Power to help you determine what your
              car is worth and get the best value on a sale
            </p>
          </div>
          <Link to="/" className="text-primary my-2 font-bold text-base">
            Learn more
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RegisterHowItWorksHero;
