import React from "react";
import ImagesCarousel from "components/ImagesCarousel";

const ImagesHandler = ({ isLoading = true, images = [], cover }) => {
  return (
    <ImagesCarousel
      key={isLoading}
      isLoading={isLoading}
      images={images}
      cover={cover}
      isSlideshow
    />
  );
};

export default ImagesHandler;
