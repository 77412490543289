import axios from 'axios';
import { baseURL } from 'config';

export function apiSetLookingForCar(token, checkBoxLooking, description, callback) {
    let params = new URLSearchParams();
    params.append("looking",checkBoxLooking ? 1 : 0);
    params.append("description",description);
    axios({
        method:"POST",
        url: baseURL+"/seller/looking",
        data:params,
        headers:{
            'Authorization': token,
            'Content-Type':'application/x-www-form-urlencoded'
        }
    }).then((response)=>{
        callback(null,response.data);
    }).catch((error)=>{
        callback(error,null);
    });

}