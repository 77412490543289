import axios from "axios";
import { useContext, useRef } from "react";
import { UserDataContext } from "../../../App";
import { baseURL } from "../../../config";
import { toast } from "react-toastify";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { ChatContext } from "../contexts/ChatContext";
import { findIndex } from "lodash";
import { NegotiationsContext } from "components/negotiations/contexts/NegotiationsContext";

const UploadMsgFile = ({ fetchMessage, roomData, progressBarRef, isMessagesPage = true }) => {
  const { setChatRooms } = useContext(isMessagesPage ? ChatContext : NegotiationsContext);
  const userData = useContext(UserDataContext)[0];
  const toastId = useRef(null);

  const uploadFileEvent = async (e) => {
    try {
      if (e.target.files.length === 0) return;

      const fd = new FormData();
      fd.append("room_id", roomData.room_id);
      fd.append("dealer_id", "0");
      fd.append("file", e.target.files[0]);

      const response = await axios.post(
        `${baseURL}/seller/message/upload`,
        fd,
        {
          headers: {
            Authorization: userData.token,
          },
          onUploadProgress: handleUploadProgress,
        }
      );

      updateChatData(response.data.file_name);
      if (fetchMessage) fetchMessage();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toast.done(toastId.current);
      e.target.value = "";
    }
  };

  const handleUploadProgress = (progressEvent) => {
    const totalLength = progressEvent.lengthComputable
      ? progressEvent.total
      : progressEvent.target.getResponseHeader("content-length") ||
        progressEvent.target.getResponseHeader("x-decompressed-content-length");

    if (totalLength === null) return;

    const progressData = Math.round((progressEvent.loaded * 100) / totalLength);
    updateProgressBar(progressData);

    if (progressData === 100) {
      setTimeout(() => {
        if (
          progressBarRef &&
          progressBarRef.current &&
          progressBarRef.current.children[0]
        ) {
          progressBarRef.current.parentNode.style.display = "none";
        }
      }, 3000);
    }
  };

  const updateChatData = (file_name) => {
    const newMessage = {
      message_id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      message_date: new Date().toISOString(),
      file_name,
      you: 1,
    };

    setChatRooms((prevChatRooms) => {
      const { rooms } = prevChatRooms;
      const newRooms = [...rooms];
      const roomIndex = findIndex(
        newRooms,
        (room) => room.roomData.room_id === parseInt(roomData.room_id)
      );

      if (roomIndex === -1) {
        return prevChatRooms;
      }

      const chatMessages = [...newRooms[roomIndex].chatMessages];
      chatMessages.push(newMessage);
      newRooms[roomIndex].chatMessages = chatMessages;
      return {
        ...prevChatRooms,
        rooms: newRooms,
      };
    });
  };

  const updateProgressBar = (progressData) => {
    if (
      progressBarRef &&
      progressBarRef.current &&
      progressBarRef.current.children[0]
    ) {
      const progressBarVisual = progressBarRef.current.children[0];
      progressBarRef.current.parentNode.style.display = "";
      progressBarVisual.style.position = "relative";
      progressBarVisual.style.backgroundImage = `linear-gradient(to right, #0176AC ${progressData}%,  #F9F9F9 ${progressData}%)`;
      progressBarVisual.style.backgroundSize = "100% 4px";
      progressBarVisual.style.backgroundPosition = `bottom`;
      progressBarVisual.style.backgroundRepeat = "no-repeat";

      progressBarRef.current.children[0].innerHTML = `${progressData}%`;

      if (progressData === 100) {
        progressBarRef.current.children[0].innerHTML = "UPLOADED";
      }
    }
  };

  return (
    <>
      <input
        accept="image/*"
        onChange={uploadFileEvent}
        type="file"
        id="uploadMsgFile"
        hidden
      />
      <label
        htmlFor="uploadMsgFile"
        className="p-2 me-1 c-pointer bg-white rounded-full"
      >
        <PaperClipIcon className="w-6 h-6 text-[#ADA7A7]" />
      </label>
    </>
  );
};

export default UploadMsgFile;
