import ImagesAndUpload from "./ImagesAndUpload";
import ModalConfirm from "./ModalConfirm";

const CarInformation = ({
  reload = () => {},
  onTradeInClick = () => {},
  offer,
  handleTradeInChange,
  handleTradeLoanBalanceChange,
}) => {
  let mileageValue = parseInt(offer?.trade_mileage)
    ? parseInt(offer?.trade_mileage).toLocaleString()
    : "N/A";
  let tradeInValue = parseInt(offer?.trade_in)
    ? parseInt(offer?.trade_in).toLocaleString()
    : 0;
  let tradeConditionValue = offer?.trade_condition
    ? offer?.trade_condition
    : "N/A";
  let tradeLoanBalanceValue = offer?.trade_loan_balance
    ? parseInt(offer?.trade_loan_balance)
    : 0;

  return (
    <>
      {offer.trade_condition && (
        <div className="card-info-about mt-2 mb-8">
          <div className="flex flex-col justify-between">
            <div>
              <div className="text-speed-medium text-center text-speed-bold">
                Trade in Details
              </div>
              <span className="block border-b border-neutral w-full mt-1"></span>
              {offer.trade_condition && (
                <div className="flex gap-4 mt-2">
                  <div className="grow text-speed-small text-primary text-xs text-center pr-4 border-r border-neutral">
                    {offer?.trade_title ?? "N/A"}
                  </div>
                  <ModalConfirm
                    onConfirm={() => {
                      window.location.reload();
                    }}
                    offer={offer}
                  />
                </div>
              )}
            </div>
            {/* <Button
              onClick={() => document.querySelector("#image-pusher").click()}
            >
              Add Photos
            </Button> */}
          </div>

          <div>
            <div className="pb-2 mt-4">
              <ImagesAndUpload
                carState={offer}
                photos={[...(offer?.trade_photos?.split(",") ?? [])]}
              />

              {offer.trade_condition && (
                <div className="card-row-info-about">
                  <div>
                    <small className="fw-semibold">Mileage</small>
                  </div>
                  <div>{mileageValue}</div>
                </div>
              )}

              {offer.trade_condition && (
                <div className="card-row-info-about">
                  <div>
                    <small className="fw-semibold">Condition</small>
                  </div>
                  <div
                    className="fw-semibold"
                    style={{
                      color: ["Good", "Excellent"].includes(tradeConditionValue)
                        ? "#12DE00"
                        : "black",
                    }}
                  >
                    {tradeConditionValue}
                  </div>
                </div>
              )}

              <div
                className="card-row-info-about c-pointer"
                onClick={onTradeInClick}
              >
                <div>
                  <small className="fw-semibold">Trade In Value</small>
                </div>
                <div>
                  <input
                    disabled={!!offer?.dealer_trade_in_accepted}
                    onChange={handleTradeInChange}
                    onFocus={(e) => e.target.select()}
                    value={"$" + tradeInValue}
                    type="text"
                    className="press-input-transit"
                  />
                </div>
              </div>

              <div className="card-row-info-about">
                <div>
                  <small className="fw-semibold">Loan Balance</small>
                </div>
                <div>
                  <input
                    onChange={handleTradeLoanBalanceChange}
                    onFocus={(e) => e.target.select()}
                    value={"$" + tradeLoanBalanceValue.toLocaleString()}
                    type="text"
                    className="press-input-transit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CarInformation;
