import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Input from "components/shared/Input/Input";
import Button from "components/shared/Button";
import Alert from "components/Alert";
import { FirebaseEvent } from "../../integrations/Firebase";
import { UserDataContext } from "../../../App";
import { baseURL, baseURLv2 } from "../../../config";
import { parseJSONWithDefault } from "utils/general";
import Select from "components/shared/Select";
import OfflineImageUploader from "components/configDetails/componentDetails/OfflineImageUploader";

const PlateRegister = () => {
  const [, setUserData] = useContext(UserDataContext);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [registerStatus, setRegisterStatus] = useState("Continue");

  const [form, setForm] = useState({
    plate: "",
    state: "",
    condition_id: "",
    mileage: "",
    zip_code: "",
    phone: "",
    email: "",
    password: "",
    asking_price: "",
  });

  const extraFormRef = useRef(
    parseJSONWithDefault(window.localStorage.getItem("extraForm-plate"), {})
  );

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    FirebaseEvent("seller_method_plate");
  }, []);

  async function checkZipCodeValid(zip_code) {
    try {
      const response = await axios.get(`${baseURL}/zip/${zip_code}`);
      return !!response.data.id;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    const localStorageKeys = [
      "plate",
      "state",
      "condition_id",
      "mileage",
      "zip_code",
      "email",
      "asking_price",
      "phone",
    ];
    localStorageKeys.forEach((key) => {
      window.localStorage.setItem(key, form[key]);
    });
  }, [form]);

  async function checkIsFormInvalid() {
    if (form.plate === "") {
      showError("License Plate is required");
      FirebaseEvent("seller_plate_missing_plate");
      return true;
    }

    if (form.state === "") {
      showError("License State is required");
      FirebaseEvent("seller_plate_missing_state");
      return true;
    }

    const mileage = parseInt(form.mileage);
    if (isNaN(mileage) || !mileage) {
      showError("Mileage is required");
      FirebaseEvent("seller_vin_missing_mileage");
      return true;
    }

    if (form.zip_code === "") {
      showError("Zip code is required");
      FirebaseEvent("seller_vin_missing_zip");
      return true;
    }

    if (imagesToUpload.length === 0) {
      showError("At least one image is required");
      return true;
    }

    try {
      const isZipCodeValid = await checkZipCodeValid(form.zip_code);
      if (!isZipCodeValid) {
        showError("Zip code is not valid");
        return true;
      }
    } catch (error) {
      console.error(error);
      showError("An error occurred while checking zip code validity");
      return true;
    }

    return false;
  }

  async function submitForm() {
    const hasError = await checkIsFormInvalid();
    // Validate form fields
    if (hasError) return;

    setLoading(true);

    try {
      FirebaseEvent("seller_plate_submit_click");
      setRegisterStatus("Validating Plate...");
      const plateValidationRes = await apiValidatePlate();

      if (plateValidationRes.data.success !== 1) {
        FirebaseEvent("seller_plate_bad_plate");
        showError("An error occurred while validating Plate");
        return;
      }

      FirebaseEvent("seller_plate_ok_plate");
      setRegisterStatus("Registering...");

      const formData = { ...form, ...plateValidationRes.data };
      const registerPlateRes = await apiRegisterPlate(formData);

      if (registerPlateRes.data.success !== 1) {
        FirebaseEvent("seller_plate_error");
        showError("An error occurred while submitting plate data");
        return;
      }

      FirebaseEvent("seller_plate_success");

      const {
        token,
        car: { id: carId },
      } = registerPlateRes.data;

      // NOTE: This step regiters user, we push data to context for later use
      setUserData({
        ...registerPlateRes.data,
      });
      setRegisterStatus("Uploading photos...");

      const photoUploadRes = await apiUploadImages(token, carId);
      if (photoUploadRes.data.success !== 1) {
        showError("An error occurred while uploading images");
        return;
      }

      setRegisterStatus("Finishing up...");

      const registerCompleteRes = await apiPostRegisterComplete(token);
      if (registerCompleteRes.data.success !== 1) {
        showError("An error occurred while completing registration");
        return;
      }

      setRegisterStatus("Logging in...");

      // NOTE: This is the final step, in order to complete registration we make user online and clear local storage
      setUserData((p) => {
        window.localStorage.clear();
        return { ...p, isOnline: true };
      });
    } catch (error) {
      showError("An error occurred while submitting the form");
    } finally {
      setRegisterStatus("Continue");
      setLoading(false);
    }
  }

  async function apiValidatePlate() {
    return await axios.post(
      `${baseURL}/seller/register/plate-to-vin`,
      new URLSearchParams({ plate: form.plate, state: form.state }),
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  }

  async function apiRegisterPlate(formData) {
    let formDataParams = new URLSearchParams();
    let allowedKeys = [
      "plate",
      "state",
      "condition_id",
      "mileage",
      "zip_code",
      "asking_price",
      "phone",
      "vin_id",
      "vin",
      "plate_state",
      "year",
      "make",
      "model",
      "trim",
    ];
    for (var [key, value] of Object.entries(formData)) {
      if (allowedKeys.includes(key.toLowerCase())) {
        formDataParams.append(key, value);
      }
    }
    return await axios.post(
      `${baseURLv2}/seller/register-plate`,
      formDataParams,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  }

  async function apiPostRegisterComplete(token) {
    const completeParams = new URLSearchParams();
    completeParams.append("asking_price", "");
    completeParams.append("phone", "");

    try {
      return await axios.post(
        `${baseURL}/seller/register/complete`,
        completeParams,
        {
          headers: { Authorization: token },
        }
      );
    } catch (error) {
      throw new Error("An error occurred while completing registration");
    }
  }

  async function apiUploadImages(token, carId) {
    const uploadPromises = imagesToUpload.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("car_id", carId);

      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: baseURL + "/seller/upload",
          headers: {
            Authorization: token,
          },
          data: formData,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    });

    try {
      const isSuccess = (await Promise.all(uploadPromises)).every(
        (response) => response.data.success === 1
      );

      return { data: { success: isSuccess ? 1 : 0 } };
    } catch (error) {
      setLoading(false);
      showError("An error occurred while uploading images");
    }
  }

  function showError(message) {
    setAlertShow(true);
    setAlertMessage(message);
  }

  let countryCode = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
    "DC",
    "AS",
    "GU",
    "MP",
    "PR",
    "UM",
    "VI",
  ];

  return (
    <>
      <Alert message={alertMessage} show={alertShow} setShow={setAlertShow} />

      <form className="shrink-0 w-full">
        <div className="flex flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2">
              <div className="w-full md:w-1/2">
                <Input
                  value={form.plate}
                  type="text"
                  onChange={(e) =>
                    setForm((i) => {
                      return { ...i, plate: e.target.value };
                    })
                  }
                  label="License Plate"
                />
              </div>

              <div className="w-full md:w-1/2">
                <Select
                  onChange={(e) => {
                    setForm((i) => {
                      return { ...i, state: e.target.value };
                    });
                  }}
                  label="State"
                  placeholder="Choose State"
                  labelOptions={countryCode}
                  valueOptions={countryCode}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2">
              <div className="w-full md:w-1/2">
                <Input
                  value={form.mileage}
                  type="number"
                  onChange={(e) => {
                    setForm((i) => {
                      return {
                        ...i,
                        mileage: e.target.value,
                      };
                    });
                  }}
                  label="Mileage"
                />
              </div>
              <div className="w-full md:w-1/2">
                <Input
                  value={form.zip_code}
                  type="number"
                  onChange={(e) => {
                    setForm((i) => {
                      return {
                        ...i,
                        zip_code: e.target.value,
                      };
                    });
                  }}
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="w-full mt-6">
              <OfflineImageUploader
                onImagesListChange={(files) => setImagesToUpload(files)}
              />
            </div>
            <div className="w-full mt-6">
              <Button
                className="w-full"
                variant="secondary"
                onClick={submitForm}
                disabled={loading}
              >
                {registerStatus}
              </Button>
            </div>
            <div className="accept-terms-text">
              By signing up on our platform you agree to our{" "}
              <Link
                target="_blank"
                to="https://autostoday.com/terms-of-use/"
                rel="noreferrer"
              >
                Terms of Use
              </Link>
            </div>
          </div>
          <div className="hero-box__form__bottom">
            <div className="form-bt-progress">
              <span className="span active"></span>
              <span className="span"></span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PlateRegister;
