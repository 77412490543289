import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import React from "react";

function ModalImage(props) {
  const { setModalImageShow, imageLink, image, ...attrProps } = props;

  return (
    <Modal {...attrProps} size="lg">
      <Button
        onClick={() => {
          setModalImageShow((p) => {
            return { ...p, show: false };
          });
        }}
      >
        Hide
      </Button>
      <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={image} alt="" />
    </Modal>
  );
}

export default ModalImage;
