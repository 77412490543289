import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Input from "components/shared/Input/Input";
import Button from "components/shared/Button";
import Alert from "components/Alert";
import VinLocation from "components/VinLocation";
import { FirebaseEvent } from "../../integrations/Firebase";
import { UserDataContext } from "../../../App";
import { baseURL, baseURLv2 } from "../../../config";
import OfflineImageUploader from "components/configDetails/componentDetails/OfflineImageUploader";

export default function VinRegister() {
  const [, setUserData] = useContext(UserDataContext);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [registerStatus, setRegisterStatus] = useState("Continue");

  const [form, setForm] = useState({
    vin: window.localStorage.getItem("vin") || "",
    mileage: window.localStorage.getItem("mileage") || "",
    condition_id: window.localStorage.getItem("condition_id") || "",
    zip_code: window.localStorage.getItem("zip_code") || "",
    phone: window.localStorage.getItem("phone") || "",
    email: window.localStorage.getItem("email") || "",
    password: window.localStorage.getItem("password") || "",
    asking_price: window.localStorage.getItem("asking_price") || "",
  });

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [vinLocationShow, setVinLocationShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    FirebaseEvent("seller_method_vin");
  }, []);

  useEffect(() => {
    window.localStorage.setItem("vin", form.vin);
    window.localStorage.setItem("mileage", form.mileage);
    window.localStorage.setItem("condition_id", form.condition_id);
    window.localStorage.setItem("zip_code", form.zip_code);
    window.localStorage.setItem("email", form.email);
    window.localStorage.setItem("asking_price", form.asking_price);
    window.localStorage.setItem("phone", form.phone);
  }, [form]);

  async function apiValidateZipCode(zip_code) {
    try {
      const response = await axios.get(`${baseURL}/zip/${zip_code}`);
      return !!response.data.id;
    } catch (error) {
      throw new Error("An error occurred while checking zip code validity");
    }
  }

  function showError(message) {
    setAlertShow(true);
    setAlertMessage(message);
  }

  async function submitForm() {
    FirebaseEvent("seller_vin_submit_click");

    const hasError = await checkIsFormInvalid();
    // Validate form fields
    if (hasError) return;

    setLoading(true);

    try {
      setRegisterStatus("Validating VIN...");
      const vinValidationRes = await apiValidateVin();

      if (vinValidationRes.data.success !== 1) {
        FirebaseEvent("seller_vin_bad_vin");
        showError("An error occurred while validating VIN");
        return;
      }

      FirebaseEvent("seller_vin_ok_vin");
      setRegisterStatus("Registering...");

      const formData = { ...form, ...vinValidationRes.data };
      const registerPlateRes = await apiRegisterPlate(formData);

      if (registerPlateRes.data.success !== 1) {
        FirebaseEvent("seller_vin_error");
        showError("An error occurred while submitting plate data");
        return;
      }

      FirebaseEvent("seller_vin_success");

      const {
        token,
        car: { id: carId },
      } = registerPlateRes.data;

      // NOTE: This step regiters user, we push data to context for later use
      setUserData({
        ...registerPlateRes.data,
      });
      setRegisterStatus("Uploading photos...");

      const photoUploadRes = await apiUploadImages(token, carId);
      if (photoUploadRes.data.success !== 1) {
        showError("An error occurred while uploading images");
        return;
      }

      setRegisterStatus("Finishing up...");

      const registerCompleteRes = await apiPostRegisterComplete(token);
      if (registerCompleteRes.data.success !== 1) {
        FirebaseEvent("seller_vin_bad_vin");
        showError("An error occurred while completing registration");
        return;
      }

      FirebaseEvent("seller_vin_ok_vin");
      setRegisterStatus("Logging in...");

      // NOTE: This is the final step, in order to complete registration we make user online and clear local storage
      setUserData((p) => {
        window.localStorage.clear();
        return { ...p, isOnline: true };
      });
    } catch (error) {
      showError("An error occurred while submitting the form");
    } finally {
      setRegisterStatus("Continue");
      setLoading(false);
    }
  }

  async function checkIsFormInvalid() {
    if (form.vin === "") {
      showError("VIN number is required");
      FirebaseEvent("seller_vin_missing_vin");
      return true;
    }

    const mileage = parseInt(form.mileage);
    if (isNaN(mileage) || !mileage) {
      showError("Mileage is required");
      FirebaseEvent("seller_vin_missing_mileage");
      return true;
    }

    if (form.zip_code === "") {
      showError("Zip code is required");
      FirebaseEvent("seller_vin_missing_zip");
      return true;
    }

    if (imagesToUpload.length === 0) {
      showError("At least one image is required");
      return true;
    }

    try {
      const isZipCodeValid = await apiValidateZipCode(form.zip_code);
      if (!isZipCodeValid) {
        showError("Zip code is not valid");
        return true;
      }
    } catch (error) {
      console.error(error);
      showError("An error occurred while checking zip code validity");
      return true;
    }

    return false;
  }

  async function apiValidateVin() {
    try {
      return await axios.post(
        `${baseURL}/seller/register/vin`,
        new URLSearchParams({ vin: form.vin }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
    } catch (error) {
      throw new Error("An error occurred while validating VIN");
    }
  }

  async function apiRegisterPlate(formData) {
    const allowedKeys = [
      "plate",
      "state",
      "condition_id",
      "mileage",
      "zip_code",
      "asking_price",
      "phone",
      "vin_id",
      "vin",
      "plate_state",
      "year",
      "make",
      "model",
      "trim",
    ];
    const formDataParams = Object.entries(formData)
      .filter(([key]) => allowedKeys.includes(key.toLowerCase()))
      .reduce((params, [key, value]) => {
        params.append(key, value);
        return params;
      }, new URLSearchParams());

    try {
      return await axios.post(
        `${baseURLv2}/seller/register-plate`,
        formDataParams,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
    } catch (error) {
      throw new Error("An error occurred while submitting form data");
    }
  }

  async function apiPostRegisterComplete(token) {
    const completeParams = new URLSearchParams();
    completeParams.append("asking_price", "");
    completeParams.append("phone", "");

    try {
      return await axios.post(
        `${baseURL}/seller/register/complete`,
        completeParams,
        {
          headers: { Authorization: token },
        }
      );
    } catch (error) {
      throw new Error("An error occurred while completing registration");
    }
  }

  async function apiUploadImages(token, carId) {
    const uploadPromises = imagesToUpload.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("car_id", carId);

      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: baseURL + "/seller/upload",
          headers: {
            Authorization: token,
          },
          data: formData,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    });

    try {
      const isSuccess = (await Promise.all(uploadPromises)).every(
        (response) => response.data.success === 1
      );

      return { data: { success: isSuccess ? 1 : 0 } };
    } catch (error) {
      setLoading(false);
      showError("An error occurred while uploading images");
    }
  }

  return (
    <>
      <VinLocation
        show={vinLocationShow}
        setShow={setVinLocationShow}
      ></VinLocation>
      <Alert message={alertMessage} show={alertShow} setShow={setAlertShow} />

      <form className="shrink-0 w-full">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="w-full">
              <div className="flex flex-col">
                <Input
                  value={form.vin}
                  type="text"
                  className="w-full"
                  placeholder=""
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      vin: e.target.value,
                    }))
                  }
                  label="VIN number"
                />
                <div className="x-bt-lbl">
                  <Link
                    className="find-vin"
                    onClick={() => setVinLocationShow(true)}
                  >
                    Where to find the VIN?
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2">
                <div className="single-input-x">
                  <div className="input-x-outer">
                    <Input
                      value={form.mileage}
                      type="number"
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          mileage: e.target.value,
                        }))
                      }
                      className="input-x"
                      label="Mileage"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="single-input-x">
                  <div className="input-x-outer">
                    <Input
                      value={form.zip_code}
                      type="number"
                      onChange={(e) =>
                        setForm((prevState) => ({
                          ...prevState,
                          zip_code: e.target.value,
                        }))
                      }
                      label="Zip Code"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-6">
              <OfflineImageUploader
                onImagesListChange={(files) => setImagesToUpload(files)}
              />
            </div>
            <div className="w-full mt-6">
              <Button
                className="w-full"
                variant="secondary"
                onClick={submitForm}
                disabled={loading}
              >
                {registerStatus}
              </Button>
            </div>
            <div className="accept-terms-text">
              By signing up on our platform you agree to our{" "}
              <Link target="_blank" to="https://autostoday.com/terms-of-use/">
                Terms of Use
              </Link>
            </div>
          </div>
          <div className="hero-box__form__bottom">
            <div className="form-bt-progress">
              <span className="span active"></span>
              <span className="span"></span>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
