import {
  AppStoreIcon,
  PlayStoreIcon,
} from "components/componenetIcons/MobileStoreIcon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#F6F6F6] py-4 md:py-10">
      <div className="container flex flex-col md:flex-row gap-4 md:gap-0 justify-between items-center">
        <span className="text-neutral text-sm !order-2 !md:order-0">
          © 2023 AutosToday, Inc. All rights reserved.
        </span>

        <div className="flex items-center space-x-4 !order-1">
          <span className="hidden xl:flex text-base text-neutral">Get the mobile app</span>
          <a
            href="https://play.google.com/store/apps/details?id=com.caripa.seller"
            target="_blank"
            rel="noreferrer"
          >
            <PlayStoreIcon size="28px" className="ms-2 c-pointer" />
          </a>
          <a
            href="https://apps.apple.com/us/app/autos-today-sell-your-car/id1476273908"
            target="_blank"
            rel="noreferrer"
          >
            <AppStoreIcon size="28px" className="me-2 c-pointer" />
          </a>
        </div>

        <Link to="/" className="!order-0 !md:order-2">
          <img
            src="/images/logo-hq.png"
            className="w-[170px] xl:w-[220px]"
            alt="logo"
          />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
