import cn from "classnames";

const ModalCloseButton = ({ onHide, isFullScreen }) => {
  return (
    <span
      className={cn("absolute z-10 cursor-pointer", {
        "text-gray-400 right-4 top-3 hover:text-gray-500": !isFullScreen,
        "text-white rounded-lg top-4 right-0 p-0": isFullScreen,
      })}
      role="button"
      onClick={onHide}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke={isFullScreen ? "#fff" : "currentColor"}
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  );
};

export default ModalCloseButton;
