import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// main style
import "./App.css";

import { setCookie } from "utils/cookies";
import { projectMode } from "config";

// PageComponent
import { ChangePassword } from "./components/ChangePassword";

import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import Profile from "./components/Profile";
import { MessageLayout } from "./components/messages/MessageLayout";
import CarPhotos from "components/configDetails/CarPhotos";

import MainSearch from "components/searchCars/MainSearch";
import TestGround from "components/TestGround";
import NegotiationsRoute from "components/negotiations/NegotationsRoute";
import WindowPostMessage from "components/WindowPostMessage";
import Home from "components/home/Home";
import FilterContext from "components/searchCars/Context/FilterContext";
import AutoLogin from "components/AutoLogin";
import Register from "components/register";
import VinRegister from "components/register/wayRegister/VinRegister";
import PlateRegister from "components/register/wayRegister/PlateRegister";
import ManualRegister from "components/register/wayRegister/ManualRegister";
import Layout from "components/layout/Layout";
import ChatContextProvider from "components/messages/contexts/ChatContext";
import NegotiationsContextProvider from "components/negotiations/contexts/NegotiationsContext";
import Car from "components/car/Car";
import ModalCarContextProvider from "components/searchCars/Context/ModalCarContext";
import { Helmet } from "react-helmet";

// socket io lib
export const UserDataContext = React.createContext(); // create Contect for userData
export const SocketDataContext = React.createContext(); // create Contect for socket

//
const localData = JSON.parse(window?.localStorage?.["userData"] || "{}"); // load userData in local if already logged!

function App() {
  const [userData, setUserData] = useState(localData); // use hook for data
  const [socketData, setSocketData] = useState({});

  useEffect(() => {
    if (userData.isOnline && userData.isOnline === true) {
      window.localStorage.clear(); // clear local storage
    }
    const isProfileCompleteBaseEmail = !(
      userData.email === "" ||
      userData.email === null ||
      typeof userData.email === "undefined"
    );

    userData["isProfileCompleted"] = isProfileCompleteBaseEmail;
    window.localStorage["userData"] = JSON.stringify(userData); // load data from localStorage
    setCookie("userData", JSON.stringify(userData), 3600); // set data to cookie
    return () => {};
  }, [userData]);

  return (
    <SocketDataContext.Provider value={{ socketData, setSocketData }}>
      <UserDataContext.Provider value={[userData, setUserData]}>
        {/* Usage to comunicate with top iframe website if is in iframe */}
        <WindowPostMessage />

        {userData.isOnline && (
          <Layout>
            <Helmet>
              <title>Autos Today Seller</title>
              <meta
                name="description"
                content="Dealers bid, and you win! Sell your car fast via our free online auction. Simply add your car and start receiving bids in minutes."
              />
            </Helmet>
            <Routes>
              {/* Main Car Details/HomePage */}
              <Route path={"/"} element={<Home />} />
              <Route path={"/home"} element={<Home />} />

              {userData.car && (
                <>
                  <Route path="/car-photos" element={<CarPhotos />} />

                  {/* Messages */}
                  <Route
                    path="/messages"
                    element={
                      <ChatContextProvider>
                        <MessageLayout />
                      </ChatContextProvider>
                    }
                  />
                </>
              )}

              {/* Main Profile/edit/change */}
              <Route path="/profile" element={<Profile />} />
              <Route path="/change-password" element={<ChangePassword />} />

              {/* Search for cars */}
              <Route path="/car-search">
                <Route
                  index
                  element={
                    <FilterContext>
                      <MainSearch />
                    </FilterContext>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ModalCarContextProvider>
                      <Car />
                    </ModalCarContextProvider>
                  }
                />
              </Route>

              {/* negotiation */}
              <Route
                path="/negotiations"
                element={
                  <NegotiationsContextProvider>
                    <NegotiationsRoute />
                  </NegotiationsContextProvider>
                }
              />

              {/* DEV */}
              {projectMode === "DEV" && (
                <Route path="/test" element={<TestGround />} />
              )}

              {/* 404 error */}
              <Route
                path="*"
                status={404}
                element={<Navigate to="/home" replace />}
              />
            </Routes>
          </Layout>
        )}

        {!userData.isOnline && (
          <Routes>
            <Route
              path="/t/n/:token"
              element={<AutoLogin redirect="negotiations" />}
            />

            <Route
              path="/t/m/:token"
              element={<AutoLogin redirect="messages" />}
            />
            <Route
              path="/t/v/:token"
              element={<AutoLogin redirect="home" case="noVin" />}
            />
            <Route
              path="/t/p/:token"
              element={<AutoLogin redirect="home" case="noPhoto" />}
            />
            <Route path="/" element={<Login />} />
            <Route path="/forgot-pasword" element={<ForgetPassword />} />

            {/* Register */}
            <Route path="/register" element={<Register />}>
              <Route index element={<Navigate to="vin" replace />} />
              <Route path="vin" element={<VinRegister />} />
              <Route path="plate" element={<PlateRegister />} />
              <Route path="make" element={<ManualRegister />} />
            </Route>

            <Route
              path="*"
              status={404}
              element={<Navigate to="/" replace />}
            />
          </Routes>
        )}
      </UserDataContext.Provider>
    </SocketDataContext.Provider>
  );
}

export default App;
