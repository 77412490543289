import axios from "axios";
import { baseURL } from "config";
import { projectMode } from "../../../config";


export function fetchMoreDataFromPlate({plate,state},callback=()=>{}){
    let parmas = new URLSearchParams();
    parmas.append("plate", plate);
    parmas.append("state", state);
    axios({
        method: "POST",
        url: baseURL + "/seller/register/plate-to-vin",
        data: parmas,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => {
        /*
            {
                "success": 1,
                "vin_id": 580,
                "vin": "WDDXK7JB7HA025393",
                "year": 2017,
                "make": "Mercedes-benz",
                "model": "S-class",
                "trim": "AMG S63",
                "msrp": null,
                "type": "Passenger Car",
                "engine": null
            }
            {
                "success": 0,
                "error": "Vehicle with a license plate BH375492 in state of il was not found."
            }
        */
        callback(null,response.data);
    }).catch((error) => {
        callback(error,null);
    });      
}
