import { NavLink, Outlet } from "react-router-dom";

import cn from "classnames";
import { useContext } from "react";
import { RegisterContext } from "./contexts/RegisterContext";

const RegisterHero = () => {
  const [isInRegisterProgress] = useContext(RegisterContext);

  return (
    <section className="relative bg-gradient-to-r from-primary to-[#5A6696] pb-4">
      <div className="container max-w-6xl flex flex-col p-8 pt-24 md:pt-auto pr-0 md:p-auto ml-auto md:flex-row items-center">
        <div className="w-full md:w-1/2 pr-8 md:pr-auto">
          <h3 className="text-white font-bold text-5xl mb-8">
            Sell your car today.
          </h3>
          <p className="text-white text-3xl font-normal pr-8">
            With AutosToday, you’re in control. It’s fast and simple: list your
            car and start receiving offers from over 100 dealerships in our
            network.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="w-[130%] max-w-none"
            src="/images/register/hero-car-image.png"
            alt=""
          />
        </div>
      </div>
      <div className="container max-w-6xl px-4 md:px-0">
        <div className="p-8 flex flex-col md:flex-row md:space-x-4 md:px-24 md:py-20 rounded shadow bg-white -mb-24">
          <div className="w-full md:w-1/2 !order-1 md:!order-0">
            <h4 className="hidden md:block text-primary text-3xl mb-5 font-bold">
              List your Vehicle
            </h4>
            <p className="text-base font-normal mb-2 flex items-center gap-4">
              <img alt="tick icon" src="/images/register/ul-check-circle.svg" />
              Create a vehicle listing in minutes using your car’s VIN number
              and mileage, plate number, or make and model
            </p>
            <p className="text-base font-normal mb-2 flex items-center gap-4">
              <img alt="tick icon" src="/images/register/ul-check-circle.svg" />
              Add optional photos and additional details
            </p>
            <p className="text-base font-normal mb-2 flex items-center gap-4">
              <img alt="tick icon" src="/images/register/ul-check-circle.svg" />
              Wait for offers!
            </p>
            <p className="text-sm font-normal mt-6">
              This platform is free to use and keeps your information anonymous.
              You are not obligated to sell your car once it is listed.
            </p>
          </div>
          <div className="w-full md:w-1/2 !order-0 md:!order-1">
            <h4 className="block md:hidden text-primary text-3xl mb-5 font-bold">
              List your Vehicle
            </h4>
            <div className="ml-auto">
              <div className="flex mb-3">
                <NavLink
                  className={({ isActive }) =>
                    cn(
                      "flex-1 rounded-l border !border-primary text-center text-lg font-bold py-2 px-2",
                      {
                        "bg-primary text-white": isActive,
                        "text-primary": !isActive,
                      }
                    )
                  }
                  to="/register/vin"
                >
                  Vin
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    cn(
                      "flex-1 border !border-primary text-center text-lg font-bold py-2 px-2",
                      {
                        "bg-primary text-white": isActive,
                        "text-primary": !isActive,
                      }
                    )
                  }
                  to="/register/plate"
                >
                  Plate
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    cn(
                      "flex-1 rounded-r border !border-primary text-center text-lg font-bold py-2 px-2",
                      {
                        "bg-primary text-white": isActive,
                        "text-primary": !isActive,
                      }
                    )
                  }
                  to="/register/make"
                >
                  Make
                </NavLink>
              </div>
              <div className="flex flex-wrap">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterHero;
