import React from 'react';

const SkeletonPrimaryDetails = () => (
  <div className="flex justify-between border-t border-[#08315159] py-2 animate-pulse">
    <div className="h-4 w-1/3 bg-gray-300"></div>
    <div className="h-4 w-2/3 bg-gray-300"></div>
  </div>
);

const SkeletonVehicleFeatures = () => (
  <div className="list-disc text-sm font-normal bg-gray-300 h-5 w-full animate-pulse"></div>
);

const SkeletonCarDetails = () => {
  return (
    <div className="w-full lg:w-1/2 mt-4 md:p-4">
      <div className="bg-gray-300 h-24 w-full mb-4"></div>
      <div className="pb-2 flex-1 flex justify-between items-center">
        <div className="h-6 w-3/4 bg-gray-300"></div>
      </div>
      <div className="grid grid-cols-1 grid-rows-auto">
        {[...Array(10)].map((_, index) => (
          <SkeletonPrimaryDetails key={index} />
        ))}
      </div>
      <div className="flex flex-col mt-4">
        <div className="text-primary text-lg xl:text-2xl font-bold pb-2 border-b border-[#08315159] mb-2 bg-gray-300 h-8 w-2/3"></div>
        <ul className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-auto gap-3">
          {[...Array(5)].map((_, index) => (
            <SkeletonVehicleFeatures key={index} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SkeletonCarDetails;
