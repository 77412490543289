import cn from "classnames";

const ModalTitle = ({ children }) => {
  return (
    <div
      className={cn("text-primary font-bold text-sm sm:text-lg", {
        "py-2": !children,
      })}
    >
      {children}
    </div>
  );
};

export default ModalTitle;
