import axios from "axios";
import { baseURL } from "config";

export const apiFetchCarDetails = async (token, carId) => {
  return await axios({
    method: "GET",
    url: baseURL + "/car/details/" + carId,
    headers: {
      Authorization: token,
    },
  });
};
