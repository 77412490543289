import { baseURL } from "config";
import axios from "axios";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { SWIPER_CONFIG } from "./BrowseCars.constants";
import Loading from "components/Loading";
import CardCar from "components/searchCars/compontentSearch/CardCar";
import Skeleton from "components/Skeleton";
import { useNavigate } from "react-router-dom";

const BrowseCars = () => {
  const navigate = useNavigate();
  const [cars, setCars] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchDealerInventory = () => {
    axios({
      method: "get",
      url: `${baseURL}/seller/inventory-home`,
    })
      .then((response) => {
        setCars(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false); // Set loading to false when the API request is completed
      });
  };

  useEffect(() => {
    fetchDealerInventory();
  }, []);

  return (
    <div className="bg-[#F6F6F6]">
      {loading ? (
        <Skeleton type="browseCars" />
      ) : (
        <div className="py-4 xl:py-9">
          <h3 className="text-primary text-xl mb-2 font-bold px-4 xl:text-2xl xl:pl-[10%]">
            Cars for Sale
          </h3>
          {cars.length === 0 ? (
            <Loading />
          ) : (
            <Swiper
              slidesPerView={4.5}
              spaceBetween={30}
              className="!px-4 xl:!px-[10%]"
              {...SWIPER_CONFIG}
            >
              {cars.length > 0 &&
                cars.map((carItem, index) => (
                  <SwiperSlide
                    key={`${carItem?.title} - ${index}`}
                    className="pt-4 pb-8 cursor-pointer !w-[256px]"
                  >
                    <CardCar
                      car={carItem}
                      url={`/car-search/${carItem?.code}`}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
        </div>
      )}
    </div>
  );
};

export default BrowseCars;
