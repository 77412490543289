import DualRangeSliderInput from "components/componenetInput/DualRangeSliderInput";
import SlimSelectAdapter from "components/componenetInput/SlimSelect/SlimSelectAdapter";
import { useEffect, useState, useContext } from "react";
import {
  FilterMainContext,
  FILTER_DEFAULT_VALUES,
} from "../Context/FilterContext";
import { GrClose } from "@react-icons/all-files/gr/GrClose";
import Input from "components/shared/Input/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { isEqual } from "lodash";
import Button from "components/shared/Button";
import cn from "classnames";
import {
  allAvailableItems,
  capitalizeFirstLetterOfEachWord,
  findColorMatch,
} from "./helpers";

const COLORS = {
  Red: "#ff0000",
  Black: "#000000",
  Gray: "#808080",
  Silver: "#c0c0c0",
  White: "#ffffff",
  Burgundy: "#800020",
  Gobi: "#d2b48c",
  Blue: "#0000ff",
  "Blue Dark": "#00008b",
  "Gray Metalic": "#a9a9a9",
  Marron: "#c0c0c0",
  Beige: "#f5f5dc",
  "White Sand": "#f5f5dc",
  Brown: "#a52a2a",
  Gold: "#ffd700",
};

export const COLOR_NAMES = Object.keys(COLORS);

const SideFilter = ({
  listCars,
  makeList,
  setMakeList,
  trimList,
  setTrimList,
  modelList,
  setModelList,
  handleChangeModel,
  handleChangeMake,
  handleChangeTrim,
  onFilterChange = () => {},
  setFiltersPopover,
}) => {
  const [dealerNamesList, setDealerNamesList] = useState([
    { placeholder: true, text: "Select Dealers" },
  ]);
  const { filterReducer, setFilterReducer } = useContext(FilterMainContext);
  const showReset = !isEqual(filterReducer, FILTER_DEFAULT_VALUES);
  const [colors, setColors] = useState([]);

  const filterAvailableColors = (listCars) => {
    const ALL_COLORS = allAvailableItems(listCars, "exterior_color");

    const allColorCategories = new Set();
    ALL_COLORS.forEach((ext_color) => {
      if (!!findColorMatch(ext_color, COLOR_NAMES)) {
        allColorCategories.add(findColorMatch(ext_color, COLOR_NAMES));
      }
    });

    const AVAILABLE_COLORS = Array.from(allColorCategories).sort();
    return AVAILABLE_COLORS;
  };

  useEffect(() => {
    setMakeList(
      [...new Set(listCars.map((i) => i.make))].sort((a, b) =>
        a.localeCompare(b)
      )
    );
    setDealerNamesList(
      [...new Set(listCars.map((i) => i.dealer_name))]
        .sort((a, b) => a.localeCompare(b))
        .map((i) => ({ text: i, value: i }))
    );

    setColors(filterAvailableColors(listCars));

    document
      ?.querySelector(".content-grid")
      ?.addEventListener("scroll", handleScrollContentGrid);
    return () => {
      if (document.querySelector(".content-grid")) {
        document
          .querySelector(".content-grid")
          .removeEventListener("scroll", handleScrollContentGrid);
      }
    };
  }, []);

  function handleScrollContentGrid(e) {
    let contentGrid = e.target;
    let targetPinned = document.querySelector(
      ".header-filter-speed-container-mobile"
    );
    let targetButtonMoreFilter = document.querySelector("#more-filers-button");
    if (contentGrid.scrollTop > targetPinned.getBoundingClientRect().height) {
      targetPinned.classList.add("is-pinned");
      targetButtonMoreFilter.innerHTML = " Filters";
    } else {
      targetPinned.classList.remove("is-pinned");
      targetButtonMoreFilter.innerHTML = " More Filters";
    }
  }

  useEffect(() => {
    var result = [];
    for (var i = 0; i < modelList.length; i++) {
      const oneMakeObje = modelList[i];
      if (oneMakeObje?.options) {
        for (var j = 0; j < oneMakeObje.options.length; j++) {
          if (oneMakeObje.options[j].selected) {
            result.push(oneMakeObje.options[j].value);
          }
        }
      }
    }
    setFilterReducer({
      model: result,
    });
  }, [modelList]);

  useEffect(() => {
    var result = [];
    for (var i = 0; i < trimList.length; i++) {
      const oneMakeObje = trimList[i];
      if (oneMakeObje?.options) {
        for (var j = 0; j < oneMakeObje.options.length; j++) {
          if (oneMakeObje.options[j].selected) {
            result.push(oneMakeObje.options[j].value);
          }
        }
      }
    }
    setFilterReducer({
      trim: result,
    });
  }, [trimList]);

  function handleChangePrice(min, max) {
    setFilterReducer({
      price_min: min,
      price_max: max,
    });
    onFilterChange();
  }

  function handleChangeMileage(min, max) {
    setFilterReducer({
      mileage_min: min,
      mileage_max: max,
    });
    onFilterChange();
  }

  function handleChangeYear(min, max) {
    onFilterChange();
    setFilterReducer({
      year_min: min,
      year_max: max,
    });
  }

  function resetDefault() {
    onFilterChange();
    setModelList([{ placeholder: true, text: "Select Model" }]);
    setTrimList([{ placeholder: true, text: "Select Trim" }]);
    setDealerNamesList([...dealerNamesList]);
    setFilterReducer("resetDefault");
  }

  function handleChangeKeywords(event) {
    onFilterChange();
    setFilterReducer({
      search_keywords: event.target.value,
    });
  }

  function handleChangeColor(info) {
    onFilterChange();
    setFilterReducer({
      color: info.map((i) => i.value),
    });
  }

  function handleChangeDealer(info) {
    onFilterChange();
    setFilterReducer({
      dealersNames: info.map((i) => i.value),
    });
  }

  function handleChangeNewUsed(state) {
    onFilterChange();
    setFilterReducer({
      new_used: state,
    });
  }

  function hideModal(e) {
    setTimeout(() => {
      let target = e.target;
      let parent = target.closest(".filter-left-side-car-search");
      if (parent) {
        parent.classList.remove("show");
      }
    }, 200);
  }

  return (
    <>
      <div className="flex justify-end">
        <div
          onClick={() => setFiltersPopover()}
          className="show-on-mobile cursor-pointer"
        >
          <GrClose />
        </div>
      </div>
      <div className="overflow-y-auto h-full md:overflow-y-hidden flex flex-col space-y-3 pb-24">
        <div className="flex border-b border-neutral-light">
          <span
            role="button"
            onClick={() => handleChangeNewUsed("")}
            className={cn(
              "w-1/3 text-base text-[#ADA7A7] font-medium text-center py-3",
              {
                "border-b-4 border-[#B62949]": filterReducer.new_used === "",
              }
            )}
          >
            All Cars
          </span>
          <span
            role="button"
            onClick={() => handleChangeNewUsed("used")}
            className={cn(
              "w-1/3 text-base text-[#ADA7A7] font-medium text-center py-3",
              {
                "border-b-4 border-[#B62949]":
                  filterReducer.new_used === "used",
              }
            )}
          >
            Used
          </span>
          <span
            role="button"
            onClick={() => handleChangeNewUsed("new")}
            className={cn(
              "w-1/3 text-base text-[#ADA7A7] font-medium text-center py-3",
              {
                "border-b-4 border-[#B62949]": filterReducer.new_used === "new",
              }
            )}
          >
            New
          </span>
        </div>
        <Input
          type="search"
          onChange={handleChangeKeywords}
          value={filterReducer.search_keywords}
          id="default-search"
          rounded="small"
          containerClassName="w-full p-[1px]"
          placeholder="Search keywords"
          autocomplete="off"
          icon={
            <MagnifyingGlassIcon className="w-6 h-6 text-[#ADA7A7] rounded-sm" />
          }
        />

        <SlimSelectAdapter
          defaultValue={filterReducer.make}
          onSelectTarget={(info) => {
            handleChangeMake(info);
          }}
          placeholder="Make"
          data={makeList.map((i) => ({ text: i, value: i }))}
          multiple={true}
        />

        {/* Model List */}
        <SlimSelectAdapter
          disabled={filterReducer.make.length === 0}
          onSelectTarget={handleChangeModel}
          placeholder="Model"
          data={modelList}
          multiple={true}
        />
        <SlimSelectAdapter
          disabled={filterReducer.model.length === 0}
          onSelectTarget={handleChangeTrim}
          placeholder="Trim"
          data={trimList}
          multiple={true}
        />
        <SlimSelectAdapter
          defaultValue={filterReducer.color}
          placeholder="Select Colors"
          onSelectTarget={handleChangeColor}
          data={[
            ...colors.map((i, idx) => {
              return {
                innerHTML: `<div class="!flex items-center capitalize"><span class="dot-circle-iot" style="background-color:${
                  COLORS[capitalizeFirstLetterOfEachWord(i)]
                }"></span> ${i}</div>`,
                text: capitalizeFirstLetterOfEachWord(i),
                value: capitalizeFirstLetterOfEachWord(i),
              };
            }),
          ]}
          multiple={true}
        />

        {/* Dealer Names List */}
        <SlimSelectAdapter
          defaultValue={filterReducer.dealersNames}
          placeholder="By Dealer"
          onSelectTarget={handleChangeDealer}
          data={dealerNamesList}
          multiple={true}
        />

        <DualRangeSliderInput
          values={{
            min: filterReducer.price_min,
            max: filterReducer.price_max,
          }}
          onValueChange={handleChangePrice}
          label="PRICE"
          format={(v) => `$${v.toLocaleString()}`}
          max={500_000}
          step={1_000}
        />
        <DualRangeSliderInput
          values={{
            min: filterReducer.mileage_min,
            max: filterReducer.mileage_max,
          }}
          onValueChange={handleChangeMileage}
          label="MILEAGE"
          format={(v) => `${Math.floor(v / 1000)}k`}
          max={200_000}
          step={1_000}
        />
        <DualRangeSliderInput
          values={{ min: filterReducer.year_min, max: filterReducer.year_max }}
          onValueChange={handleChangeYear}
          label="YEAR"
          min={1980}
          max={new Date().getFullYear() + 1}
          step={1}
        />
      </div>
      <div
        className={cn(
          "flex flex-row items-center md:justify-center fixed bottom-0 right-0 left-0 md:relative z-40 bg-white md:bg-none shadow md:shadow-none py-4 px-4 md:p-0 mt-2",
          {
            "justify-between": showReset,
            "justify-center": !showReset,
          }
        )}
      >
        {showReset && (
          <div
            id="clear-filers"
            onClick={(e) => {
              resetDefault();
              hideModal(e);
            }}
            className="c-pointer text-danger text-sm"
          >
            Reset Filters
          </div>
        )}
        <Button
          onClick={() => setFiltersPopover()}
          variant="primary"
          className="mt-3 uppercase md:hidden"
          label="Apply"
        />
      </div>
    </>
  );
};

export default SideFilter;
