import { UserDataContext } from "App";
import axios from "axios";
import ImagesCarousel from "components/ImagesCarousel";
import Loading from "components/Loading";
import Modal from "components/shared/Modal";
import { baseURL } from "config";
import React, { useCallback, useContext, useEffect, useState } from "react";

const ModalCarOverview = ({ isOpen, onHide, data }) => {
  const userData = useContext(UserDataContext)[0];
  const [isLoading, setIsLoading] = useState(false);
  const [fulfilledData, setFulfilledData] = useState(null);

  const requestDetails = useCallback(({ token, code }, cb) => {
    axios
      .get(`${baseURL}/seller/negotiation/${code}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        let inventory_code = res?.data?.inventory_code;
        requestDetailsInventory({ inventory_code, token }, cb);
      })
      .catch((err) => {
        cb(err);
      });
  }, []);

  const requestDetailsInventory = ({ inventory_code, token }, cb) => {
    axios
      .get(`${baseURL}/seller/inventory/${inventory_code}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        cb(null, res?.data);
      });
  };

  useEffect(() => {
    if (!data?.code) return;
    setIsLoading(true);
    requestDetails(
      { code: data?.code, token: userData.token },
      (error, data) => {
        if (error) throw error;
        setFulfilledData(data);
        setIsLoading(false);
      }
    );
  }, [data?.code, requestDetails, userData.token]);

  if (!fulfilledData && !isLoading) return <></>;

  const vehiclePhotos = fulfilledData?.photos?.split(",").map((photo) => {
    return { src: photo };
  });

  return (
    <Modal size="lg" show={isOpen} onHide={onHide} withCloseButton>
      <Modal.Header>
        <Modal.Title>{fulfilledData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="px-xl-3">
            <div className="flex flex-col lg:flex-row gap-4">
              <div className="w-full lg:w-1/2">
                {fulfilledData.photos && (
                  <ImagesCarousel images={vehiclePhotos ?? []} />
                )}
              </div>
              <div className="w-full lg:w-1/2 mt-0 lg:mt-4">
                <div className="p-2 rounded-lg border border-natural-light">
                  <div className="flex justify-between mt-2">
                    <span className="row-header-speed flex items-center">
                      Dealership Name
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData.dealer_name}
                    </span>
                  </div>
                  <div className="flex justify-between mt-2">
                    <span className="row-header-speed flex items-center">
                      Location
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData.location}
                    </span>
                  </div>
                  <div className="flex justify-between mt-2">
                    <span className="row-header-speed flex items-center">
                      Dealer Phone Number
                    </span>
                    <a
                      href={`tel:${fulfilledData.dealer_phone}`}
                      className="anchor-no-decoration row-info-speed color-theme"
                    >
                      {fulfilledData.dealer_phone}
                      <img
                        className="ms-2"
                        src="./images/icons/phoneCircle.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Mileage
                    </span>
                    <span className="row-info-speed">
                      {data?.mileage || "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Vin
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData?.vin || "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Stock #
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData?.stock_number || "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Make
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData?.make_name || "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Model
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData?.model_name || "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between my-3">
                    <span className="row-header-speed flex items-center">
                      Trim
                    </span>
                    <span className="row-info-speed">
                      {fulfilledData?.trim_name || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="mt-3 w-full space-y-4">
                  <div className="px-xl-3">
                    <div className="main-info-speed">
                      <img
                        className="m-3"
                        src="./images/icons/EngineIcon.svg"
                        alt=""
                      />
                      <div className="main-info-text">
                        <div>Engine</div>
                        <div>{fulfilledData?.engine_cylinders ?? "N/A"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="px-xl-3">
                    <div className="main-info-speed">
                      <img
                        className="m-3"
                        src="./images/icons/DriveTypeIcon.svg"
                        alt=""
                      />
                      <div className="main-info-text">
                        <div>Drive Type</div>
                        <div>{fulfilledData?.driven_wheels ?? "N/A"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="px-xl-3">
                    <div className="main-info-speed">
                      <img
                        className="m-3"
                        src="./images/icons/VehicleTypeIcon.svg"
                        alt=""
                      />
                      <div className="main-info-text">
                        <div>Vehicle Type</div>
                        <div>{fulfilledData?.vehicle_type ?? "N/A"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="px-xl-3">
                    <div className="main-info-speed">
                      <img
                        className="m-3"
                        src="./images/icons/TransmissionIcon.svg"
                        alt=""
                      />
                      <div className="main-info-text">
                        <div>Transmission</div>
                        <div>{fulfilledData?.transmission_type ?? "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCarOverview;
