import React, { useEffect, useRef } from "react";
import SideList from "../content/SideList";
import Input from "components/shared/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const SideBarList = ({ initDefaultRef, setActiveTab }) => {
  const refSearchInput = useRef(null);

  useEffect(() => {
    refSearchInput.current.addEventListener("keyup", searchFromList);
    return () => {
      if (refSearchInput.current) {
        refSearchInput.current.removeEventListener("keyup", searchFromList);
      }
    };
  }, []);

  function searchFromList() {
    // query all row-container-op1 and hide them if not match with value of refSearchInput.current.value
    const list = [...document.querySelectorAll(".row-container-op1")];
    list.forEach((parent) => {
      let item = parent.querySelector(".title");
      if (
        item.innerText
          .toLowerCase()
          .indexOf(refSearchInput.current.value.toLowerCase()) === -1
      ) {
        parent.style.display = "none";
      } else {
        parent.style.display = "";
      }
    });
  }

  return (
    <>
      <div className="w-full p-4">
        <div>
          <Input
            ref={refSearchInput}
            placeholder="Search"
            type="text"
            icon={<MagnifyingGlassIcon className="w-6 h-6" />}
          />
        </div>
      </div>
      <div className="overflow-y-auto h-full custom-scrollbar">
        <SideList initDefaultRef={initDefaultRef} setActiveTab={setActiveTab} />
      </div>
    </>
  );
};

export default SideBarList;
