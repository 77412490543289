import React from "react";

const Radio = React.forwardRef(({ label, description, ...attrProps }, ref) => {
  return (
    <label className="text-md text-primary">
      <input className="mr-2" ref={ref} {...attrProps} type="radio" />
      {label}
      <div className="text-xs ml-6" type="valid">
        {description}
      </div>
    </label>
  );
});

export default Radio;
