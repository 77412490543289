import React, { useCallback, useContext, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cn from "classnames";
import { PlusIcon } from "@heroicons/react/24/outline";

import { UserDataContext } from "App";
import useModal from "hooks/use-modal.hook";
import ModalVin from "components/ModalVin";
import { apiSaveVin } from "../request/apiSaveVin";
import { toast } from "react-toastify";
import ModalPhotosUploader from "components/ModalPhotosUploader";
import ModalLookingForCar from "components/ModalLookingForCar";

const ProgressBar = ({
  carProgress,
  carImages,
  onRefetchDetails,
  onRefetchImages,
}) => {
  const [userData] = useContext(UserDataContext);
  const lookingForCarModal = useModal();
  const vinModal = useModal();
  const vehicleImagesModal = useModal();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("case") === "noPhoto") {
      vehicleImagesModal.openModal();
    }
    if (searchParams.get("case") === "noVin") {
      vinModal.openModal();
    }
  }, [searchParams]);

  const navigate = useNavigate();

  const handleVinSave = useCallback(
    (vin) => {
      apiSaveVin(userData.token, vin, (error, data) => {
        if (error) throw error;

        if (data?.success === 0) {
          toast.error(data?.error);
        } else {
          toast.success("VIN number saved successfully");
          onRefetchDetails();
          vinModal.closeModal();
        }
      });
    },
    [onRefetchDetails, userData.token, vinModal]
  );

  const handlePhotosModalClose = useCallback(() => {
    vehicleImagesModal.closeModal();
    onRefetchDetails();
    onRefetchImages();
  }, [onRefetchDetails, onRefetchImages, vehicleImagesModal]);

  const progressPercentage =
    carProgress?.details?.length > 0 ? parseInt(carProgress.overall * 100) : 0;
  const sortedProgressList =
    carProgress?.details?.length > 0
      ? carProgress?.details
          ?.filter((progress) => progress.title !== "Initial Listing")
          .sort((a, b) => b.completed - a.completed)
      : [];

  const handleButtonClick = (title) => {
    const actions = {
      "VIN Information": () => vinModal.openModal(),
      "Car Photos": () => vehicleImagesModal.openModal(),
      "User Profile": () => navigate("/profile"),
      "Looking For Car": () => lookingForCarModal.openModal(),
    };

    actions[title]();
  };

  if (progressPercentage === 100) {
    return <></>;
  }

  return (
    <>
      {sortedProgressList.length === 0 ? null : (
        <div className="bg-[#A1A1A10D] shadow p-4 xl:p-8 pb-20 xl:pb-24 mb-4 rounded-lg">
          <h3 className="text-lg mb-1 xl:text-2xl text-primary font-bold xl:mb-4">
            Your progress is {progressPercentage}%
          </h3>
          <p className="text-sm xl:text-lg text-primary font-normal max-w-full xl:max-w-[40%] mb-5">
            To have your car listed, please ensure that you provide all the
            necessary information.
          </p>
          <div className={cn("mx-8 flex justify-between relative")}>
            <span
              style={{ width: `${progressPercentage}%` }}
              className={cn(
                "h-2 absolute top-1/2 -translate-y-1/2 bg-gradient-to-r from-success z-[2]",
                carProgress?.overall === 100 ? "from-100%" : "from-90%"
              )}
            ></span>
            <span
              className={cn("h-2 w-full absolute top-1/2 -translate-y-1/2", {
                "bg-success": progressPercentage === 100,
                "bg-warning":
                  progressPercentage >= 60 && progressPercentage < 100,
                "bg-danger": progressPercentage < 60,
              })}
            ></span>
            {sortedProgressList.map((item, index) => (
              <div
                className={cn("relative w-10 xl:w-12 h-10 xl:h-12", {
                  "-left-1": index === 0,
                  "-right-1": index === sortedProgressList.length - 1,
                })}
                key={index}
              >
                <button
                  onClick={() => handleButtonClick(item.title)}
                  disabled={item?.completed && item?.completed === 1}
                  className={cn(
                    "absolute -left-1/2 -top-1/2 translate-y-1/2 translate-x-1/2 rounded-full w-full h-full z-[3] flex justify-center items-center",
                    {
                      "bg-warning": item.completed > 0 && item.completed < 1,
                      "bg-danger": !item.completed,
                      "bg-success": item.completed,
                    }
                  )}
                >
                  <PlusIcon className="w-5 h-5 text-white" />
                </button>
                <span
                  className={cn(
                    "text-[10px] xl:text-base font-bold absolute top-12 xl:top-14 w-min text-center right-1/2 translate-x-1/2",
                    {
                      "text-warning": item.completed > 0 && item.completed < 1,
                      "text-danger": !item.completed,
                      "text-success": item.completed,
                    }
                  )}
                >
                  {item.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* TODO: Create new Modal component with proper props */}
      {lookingForCarModal.isOpen && (
        <ModalLookingForCar
          isOpen={lookingForCarModal.isOpen}
          onClose={() => {
            lookingForCarModal.closeModal();
          }}
          onSave={() => {
            onRefetchDetails();
            lookingForCarModal.closeModal();
          }}
        />
      )}

      {vinModal.isOpen && (
        <ModalVin
          isOpen={vinModal.isOpen}
          onClose={() => vinModal.closeModal()}
          onSave={handleVinSave}
        />
      )}

      {vehicleImagesModal.isOpen && (
        <ModalPhotosUploader
          isOpen={vehicleImagesModal.isOpen}
          images={carImages?.listImages}
          onClose={handlePhotosModalClose}
        />
      )}
    </>
  );
};

export default ProgressBar;
