export const NAV_LINKS = [
  {
    to: "/",
    icon: {
      alt: "home icon",
      src: "/images/speed/home.svg",
    },
    text: "Overview",
  },
  {
    to: "/messages",
    icon: {
      alt: "chat icon",
      src: "/images/speed/chat.svg",
    },
    text: "Messages",
  },
  {
    to: "/profile",
    icon: {
      alt: "setting icon",
      src: "/images/speed/setting.svg",
    },
    text: "Profile",
  },
  {
    to: "/car-search",
    icon: {
      alt: "key icon",
      src: "/images/speed/key.svg",
    },
    text: "Buy a Car",
  },
  {
    to: "/negotiations",
    icon: {
      alt: "shield icon",
      src: "/images/speed/shield.svg",
    },
    text: "Buying",
  },
];
