import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useRef } from "react";
import CardCar from "./compontentSearch/CardCar";
import { FilterMainContext } from "./Context/FilterContext";
import SideFilter, { COLOR_NAMES } from "./section/SideFilter";
import Loading from "components/Loading";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/shared/Button";
import axios from "axios";
import cn from "classnames";
import { baseURLv2 } from "config";
import {
  AdjustmentsHorizontalIcon,
  FaceFrownIcon,
} from "@heroicons/react/24/outline";
import { SkeletonSideFilters } from "components/Skeleton/SkeletonSideFilters";
import { findColorMatch } from "./section/helpers";

const POSTS_PER_PAGE = 30;
const SEARCH_SEED = Math.floor(Math.random() * 1000);
const PageResults = ({ page, pageSize, from, to, listingsCount }) => {
  const currentPage = to < listingsCount ? to : listingsCount;
  const isFirstPage = listingsCount === 0 || from === 0;
  const start = (page - 1) * pageSize + 1;

  return (
    <span>
      {isFirstPage ? <strong>1</strong> : <strong>{start}</strong>} -{" "}
      <strong>{currentPage}</strong> of <strong>{listingsCount}</strong>{" "}
      Listings
    </span>
  );
};

export default function MainCarSearchContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);
  const { filterReducer, setFilterReducer, getChangedKeysFromDefault } =
    useContext(FilterMainContext);
  const [listCars, setListCars] = useState([]);
  const [page, setPage] = useState(1);
  const filterRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [secondBatchLoading, setSecondBatchLoading] = useState(true);
  const [makeList, setMakeList] = useState([]);
  const [trimList, setTrimList] = useState([
    { placeholder: true, text: "Select Trim" },
  ]);
  const [modelList, setModelList] = useState([
    { placeholder: true, text: "Select Model" },
  ]);
  const [filtersPopover, setFiltersPopover] = useState(false);

  const [isFirstDataFetched, setIsFirstDataFetched] = useState(false);

  const fetchCars = async (page) => {
    try {
      const res = await axios.get(
        `${baseURLv2}/seller/inventory-search/${SEARCH_SEED}/${page}/${POSTS_PER_PAGE}`
      );
      const { data } = res.data;
      return data;
    } catch (error) {
      if (error) throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchCars(1);
      setListCars(data);
      setLoading(false);
      setIsFirstDataFetched(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isFirstDataFetched) return;
      const data = await fetchCars(2);
      setListCars((prevCars) => [...prevCars, ...data]);
      setSecondBatchLoading(false);
    };

    fetchData();
  }, [isFirstDataFetched]);

  useEffect(() => {
    const codeParam = searchParams.get("code");
    if (!codeParam) return;
    const car = listCars.find((car) => car.code === codeParam);
    if (car) {
      navigate(`/car-search/${car.code}`);
    }
  }, [listCars, navigate, searchParams]);

  const filteredCars = listCars.filter((car) => {
    let isMatch = true;
    if (filterReducer.search_keywords) {
      isMatch = car.title
        .toLowerCase()
        .includes(filterReducer.search_keywords.toLowerCase());
    }
    if (filterReducer.new_used === "") {
      isMatch = isMatch && true;
    }
    if (filterReducer.new_used === "new") {
      isMatch = isMatch && car?.mileage < 100;
    }
    if (filterReducer.new_used === "used") {
      isMatch = isMatch && car?.mileage > 100;
    }
    if (filterReducer.price_min || filterReducer.price_max) {
      isMatch =
        isMatch &&
        car.price >= filterReducer.price_min &&
        car.price <= filterReducer.price_max;
    }
    if (filterReducer.mileage_min || filterReducer.mileage_max) {
      isMatch =
        isMatch &&
        car.mileage >= filterReducer.mileage_min &&
        car.mileage <= filterReducer.mileage_max;
    }
    if (filterReducer.year_min && filterReducer.year_max) {
      isMatch =
        isMatch &&
        car.year >= filterReducer.year_min &&
        car.year <= filterReducer.year_max;
    }
    if (filterReducer.make && filterReducer.make.length > 0) {
      isMatch = isMatch && filterReducer.make.includes(car.make);
    }
    if (filterReducer.color && filterReducer.color.length > 0) {
      const colArray = filterReducer.color.map((col) => col.toLowerCase())
      isMatch = isMatch && colArray.includes(findColorMatch(car.exterior_color, COLOR_NAMES));
    }
    if (filterReducer.model && filterReducer.model.length > 0) {
      isMatch = isMatch && filterReducer.model.includes(car.model);
    }
    if (filterReducer.trim && filterReducer.trim.length > 0) {
      isMatch = isMatch && filterReducer.trim.includes(car.trim);
    }

    if (filterReducer.dealersNames && filterReducer.dealersNames.length > 0) {
      isMatch = isMatch && filterReducer.dealersNames.includes(car.dealer_name);
    }

    return isMatch;
  });

  function handleChangeTrim(info) {
    const valuesArray = info.map((i) => i.value);
    setFilterReducer({
      trim: valuesArray,
    });
    setPage(1);
    setTrimList((pState) => {
      return [
        ...pState.map((i) => {
          if (i.options) {
            i.options = i.options.map((j) => {
              if (info.map((i) => i.value).includes(j.value)) {
                j.selected = true;
              } else {
                j.selected = false;
              }
              return j;
            });
          }
          return i;
        }),
      ];
    });
  }
  function handleChangeModel(info) {
    const valuesArray = info.map((i) => i.value);
    setFilterReducer({
      model: valuesArray,
    });
    setPage(1);
    setModelList((pState) => {
      return [
        ...pState.map((i) => {
          if (i.options) {
            i.options = i.options.map((j) => {
              if (info.map((i) => i.value).includes(j.value)) {
                j.selected = true;
              } else {
                j.selected = false;
              }
              return j;
            });
          }
          return i;
        }),
      ];
    });
    setTrimList((pState) => {
      var result = [{ placeholder: true, text: "Select Trim" }];
      if (valuesArray.length > 0) {
        for (var i = 0; valuesArray.length > i; i++) {
          const oneModel = valuesArray[i];
          var trimOfModel = listCars
            ?.filter((i) => i?.model === oneModel)
            ?.map((i) => i?.trim);
          // modelOfMakes.sort();
          trimOfModel.sort();

          var trimOfModelUnique = [...new Set(trimOfModel)];
          // modelOfMakesUnique.sort((a,b)=>b.localeCompare(a));

          const objectModel = pState.find((i) => i.label === oneModel);
          var trimListOptions = [];
          if (objectModel) {
            trimListOptions = objectModel.options
              .filter((i) => i.selected === true)
              .map((i) => i.value);
          }

          result.push({
            label: oneModel,
            // eslint-disable-next-line no-loop-func
            options: trimOfModelUnique.map((trim) => ({
              text: trim,
              value: trim,
              selected: trimListOptions.includes(trim),
            })),
          });
        }
      }
      return result;
    });
  }
  function handleChangeMake(info) {
    const listMakes = [...info.map((i) => i.value)];
    setFilterReducer({
      make: listMakes,
    });
    setPage(1);
    setModelList((pState) => {
      var result = [{ placeholder: true, text: "Select Models" }];
      if (listMakes.length > 0) {
        for (var i = 0; listMakes.length > i; i++) {
          const oneMake = listMakes[i];
          var modelOfMakes = listCars
            .filter((i) => i.make === oneMake)
            .map((i) => i.model);
          // modelOfMakes.sort((a,b)=>a-b);
          modelOfMakes.sort();

          var modelOfMakesUnique = [...new Set(modelOfMakes)];
          // modelOfMakesUnique.sort((a,b)=>b.localeCompare(a));

          const objectMake = pState.find((i) => i.label === oneMake);
          var makeListOptions = [];
          if (objectMake) {
            makeListOptions = objectMake.options
              .filter((i) => i.selected === true)
              .map((i) => i.value);
          }

          result.push({
            label: oneMake,
            // eslint-disable-next-line no-loop-func
            options: modelOfMakesUnique.map((model) => ({
              text: model,
              value: model,
              selected: makeListOptions.includes(model),
            })),
          });
        }
      }

      return result;
    });
  }

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  if (loading) {
    return <Loading />;
  }
  const from = (page - 1) * POSTS_PER_PAGE;
  const to = page * POSTS_PER_PAGE;
  const handleFiltersPopover = () => {
    setFiltersPopover(!filtersPopover);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow =
        filtersPopover === false ? "hidden" : "scroll";
    }
  };
  return (
    <>
      <div className="container py-4">
        <div className="flex flex-col md:flex-row justify-center w-full gap-4">
          <div className="flex bg-white sticky top-32 sm:top-20 md:top-[74px] shadow p-4 rounded justify-between items-center text-neutral mb-3 md:hidden">
            {secondBatchLoading ? (
              <div className="animate-pulse py-2">
                <div className="w-40 h-3 bg-neutral dark:bg-neutral-light rounded"></div>
              </div>
            ) : (
              <PageResults
                page={page}
                from={from}
                to={to}
                pageSize={POSTS_PER_PAGE}
                listingsCount={filteredCars.length}
              />
            )}
            <Button
              variant="none"
              className="py-0 px-0"
              onClick={() => handleFiltersPopover()}
              label={
                <AdjustmentsHorizontalIcon className="w-5 h-5 md:hidden" />
              }
            />
          </div>
          <div
            className={cn("w-full p-3 md:h-full rounded md:block md:w-3/12", {
              "fixed top-0 left-0 z-20 max-h-full overflow-y-auto":
                filtersPopover,
              hidden: !filtersPopover,
            })}
          >
            <div
              ref={filterRef}
              className="p-6 md:p-3 bg-white shadow rounded-[10px] relative"
            >
              {secondBatchLoading ? (
                <SkeletonSideFilters />
              ) : (
                <SideFilter
                  handleChangeMake={handleChangeMake}
                  handleChangeModel={handleChangeModel}
                  handleChangeTrim={handleChangeTrim}
                  makeList={makeList}
                  setMakeList={setMakeList}
                  trimList={trimList}
                  setTrimList={setTrimList}
                  modelList={modelList}
                  setModelList={setModelList}
                  listCars={listCars}
                  setFiltersPopover={handleFiltersPopover}
                />
              )}
            </div>
          </div>
          <div className="flex-1 md:w-9/12 flex flex-col justify-between">
            <div>
              {filteredCars.length === 0 ? (
                <div className="w-full h-full flex flex-col space-y-4 items-center justify-start pt-20 text-center">
                  <FaceFrownIcon className=" w-16 h-16 text-neutral" />
                  <span className="text-neutral text-xl text-center font-bold">
                    No results found for your search. Please try using other
                    filters.
                  </span>
                </div>
              ) : null}
              {filteredCars.length === 0 ? null : (
                <div className="hidden md:flex bg-white shadow p-4 rounded text-neutral relative mb-3">
                  {secondBatchLoading ? (
                    <div className="animate-pulse py-2">
                      <div className="w-40 h-3 bg-neutral dark:bg-neutral-light rounded"></div>
                    </div>
                  ) : (
                    <PageResults
                      page={page}
                      from={from}
                      to={to}
                      pageSize={POSTS_PER_PAGE}
                      listingsCount={filteredCars.length}
                    />
                  )}
                </div>
              )}
              <div className="gap-4 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 grid-rows-auto">
                {[...filteredCars]?.slice(from, to)?.map((car, index) => {
                  return (
                    <div
                      key={car.code}
                      className="flex justify-center items-center"
                    >
                      <CardCar
                        url={`/car-search/${car?.code}`}
                        car={car}
                        index={index}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {secondBatchLoading ? (
              <div className="mx-auto flex space-x-4 animate-pulse py-2 mt-10">
                <div className="w-12 h-6 bg-neutral dark:bg-neutral-light rounded"></div>
                <div className="w-20 h-6 bg-neutral dark:bg-neutral-light rounded"></div>
                <div className="w-12 h-6 bg-neutral dark:bg-neutral-light rounded"></div>
              </div>
            ) : (
              <div
                className={cn(
                  "w-full flex justify-center items-center space-x-4 mt-10",
                  {
                    hidden: filteredCars.length === 0,
                  }
                )}
              >
                <Button
                  onClick={handlePrevPage}
                  className="disabled:bg-neutral"
                  disabled={page === 1}
                >
                  Prev
                </Button>
                <span className="text-xs text-neutral">
                  Page {page} of{" "}
                  {isNaN(filteredCars.length / POSTS_PER_PAGE) ||
                  filteredCars.length === 0
                    ? 1
                    : Math.ceil(filteredCars.length / POSTS_PER_PAGE)}
                </span>
                <Button
                  onClick={handleNextPage}
                  className="disabled:bg-neutral"
                  disabled={page * POSTS_PER_PAGE >= filteredCars.length}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
