import { memo } from "react";
import { Link } from "react-router-dom";
import { BsSpeedometer } from "react-icons/bs";
import { MapPinIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

const DEAL_RATING = {
  3: "Great Deal",
  4: "Good Deal",
  5: "Fair Deal",
};

const CardCar = ({ car = {}, url = "" }) => {
  const marketDiff = car?.price - car?.market_value;

  return (
    <Link
      to={url}
      className="bg-white cursor-pointer xl:max-w-[278px] text-black w-full h-full rounded-[5px] shadow-soft select-none"
    >
      <div className="flex flex-col rounded-lg">
        <div className="h-[190px]">
          <img
            className="h-full w-full object-cover rounded-t-lg"
            src={car.photo ? car.photo : "/images/car_no_image.jpg"}
            alt={car.title + "'s photo"}
          />
        </div>
        <div className="flex flex-col p-4 h-1/2">
          <div className="flex items-center gap-3 break-words text-sm font-semibold">
            <span className="px-2 py-1 rounded bg-[#eee] text-xs font-normal">
              {car.year}
            </span>
            <span className="truncate">{`${car?.make} ${car?.model}`}</span>
          </div>
          {car?.model && <div className="text-xs font-bold">{car?.dealer}</div>}
          <div className="flex border-b border-[#ccc] mt-2 pb-2">
            <div className="flex flex-col w-7/12">
              {car?.price === 0 || !DEAL_RATING?.[car?.price_rating] ? (
                <span className="uppercase text-[#888] text-sm">
                  No Price
                  <br />
                  Analysis
                </span>
              ) : (
                <>
                  <span
                    className={cn("text-sm font-bold uppercase", {
                      "text-deal-great": car?.price_rating === 3,
                      "text-deal-good": car?.price_rating === 4,
                      "text-deal-fair": car?.price_rating === 5,
                    })}
                  >
                    {DEAL_RATING?.[car?.price_rating] || "No Price Analysis"}
                  </span>
                  <span className="text-[10px]">
                    ${Math.abs(marketDiff).toLocaleString()}{" "}
                    {marketDiff < 0 ? "below" : "above"} market
                  </span>
                </>
              )}
            </div>
            <div className="w-1/3 border-l text-base font-bold border-[#ccc] uppercase pl-2 flex items-center">
              {car?.price === 0
                ? "Contact Us"
                : "$" + car?.price?.toLocaleString()}
            </div>
          </div>
          <div className="flex flex-col mt-2 -y-1 text-[#393939]">
            <span className="flex gap-2 text-xs">
              <BsSpeedometer className="w-4 h-4" />
              {car?.mileage?.toLocaleString() ?? "-"} miles
            </span>
            <span className="flex gap-2 text-xs">
              <MapPinIcon className="w-4 h-4" />
              {car?.location}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(CardCar);
