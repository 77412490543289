const SkeletonMainCarousel = () => (
	<div className="animate-pulse flex flex-col">
		<div className="rounded-lg w-full h-72 sm:h-80 md:h-[26rem] lg:h-[21.75rem] bg-slate-200"></div>
		<div className="flex justify-between space-x-6 mt-2">
			<div className="rounded-lg w-full h-16 bg-slate-200"></div>
			<div className="rounded-lg w-full h-16 bg-slate-200"></div>
			<div className="hidden sm:block rounded-lg w-full h-16 bg-slate-200"></div>
			<div className="hidden md:block rounded-lg w-full h-16 bg-slate-200"></div>
			<div className="hidden lg:block rounded-lg w-full h-16 bg-slate-200"></div>
		</div>
	</div>
);

export default SkeletonMainCarousel;
