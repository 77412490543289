let l = window.location.href;

let baseURL = "https://dev-api.autostoday.com/v1";
let baseURLv2 = "https://dev-api.autostoday.com/v2";
let socketURL = "https://dev-api.autostoday.com/";
if (l.includes("autostoday.com") && !l.includes("dev-")) {
  baseURL = "https://api.autostoday.com/v1";
  baseURLv2 = "https://api.autostoday.com/v2";
  socketURL = "https://api.autostoday.com/";
} else if (l.includes("prod-v1") && !l.includes("dev-")) {
  baseURL = "https://api.autostoday.com/v1";
  baseURLv2 = "https://api.autostoday.com/v2";
  socketURL = "https://api.autostoday.com/";
} else if (l.includes("localhost")) {
  baseURL = "https://dev-api.autostoday.com/v1";
  baseURLv2 = "https://dev-api.autostoday.com/v2";
  socketURL = "https://dev-api.autostoday.com/";
}

export { baseURL, baseURLv2, socketURL };
/*
socket io connection, usage in chat message
https://dev-api.autostoday.com/ 
http://localhost:3333/
*/

// lower quality images
export const imageUrlThumb = "https://thumbs.autostoday.com/";
// image load server, usage in car phots
export const imageURL = "https://photos.caripa.com/";
// image uploaded server, usage in chat images upload
export const imageStorageUrl =
  "https://caripa-messages.s3-us-west-2.amazonaws.com/";
// is project in production, usage for logs/debug
export const projectMode = "dev".toUpperCase(); // Developer or Production DEV or anystring for production
