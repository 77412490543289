import React from "react";

function Loading(props) {
  let headerText = props?.header ? props.header : "Please wait";

  let loadingImage = Math.floor(Math.random() * 1);
  let theLoad =
    loadingImage === 0 ? (
      <div className="load9">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="line"></div>
      </div>
    ) : (
      <div className="load6">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  return (
    <div className="container-box-loading">
      <h4>{headerText}</h4>
      <div className="box-loading">{theLoad}</div>
      <div>{props.children}</div>
    </div>
  );
}

export default Loading;
