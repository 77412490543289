import { UserDataContext } from "App";
import { createContext, useContext, useEffect, useReducer } from "react";

export const ModalCarContext = createContext();

function modalCarReducer(state, action) {
  switch (action.type) {
    case "update":
    case "set":
    case "add":
      return { ...state, ...action.payload };
    case "add-action":
    case "add-function":
    case "set-action":
      let actions = state?.actions || {};
      return { ...state, actions: { ...actions, ...action.payload } };
    case "calc-update":
    case "calc-set":
      var calc = state?.calc || {};
      return { ...state, calc: { ...calc, ...action.payload } };

    case "trade-update":
    case "trade-set":
      var trade = state?.trade || {};
      return { ...state, trade: { ...trade, ...action.payload } };
    case "calculate":
    case "calc":
      /*
                state:{
                    calc:{
                        price: 0,
                        downPayment: 0,
                        needFinancing: true,
                        taxRate: 0,
                        tradeIn: 0,
                        tradeLoanBalance: 0,
                        hasTradeIn: false,
                        monthlyPayment: 0,
                        totalDue: 0,
                        apr: 0,
                        loanTerm: 0,
                        tradeInValue: 0,
                        tradeInLoanBalance: 0
                    }
                }
            */
      let downPayment = parseInt(state?.calc?.downPayment) || 0;
      let price = parseInt(state?.calc?.price) || 0;
      let needFinancing = state?.calc?.needFinancing ?? true;
      let taxRate = parseFloat(state?.calc?.taxRate) || 0;
      let tradeIn = parseInt(state?.calc?.tradeInValue) || 0;
      let tradeLoanBalance = parseInt(state?.calc?.tradeInLoanBalance) || 0;
      let hasTradeIn = state?.calc?.hasTradeIn ?? false;
      let apr = parseFloat(state?.calc?.apr) || 0;
      let loanTerm = parseInt(state?.calc?.loanTerm) || 72;

      if (needFinancing === false) {
        downPayment = 0;
        apr = 0;
        loanTerm = 0;
      }
      if (hasTradeIn === false) {
        tradeLoanBalance = 0;
        tradeIn = 0;
      }

      let tax = (price - tradeIn) * taxRate;
      tax = tax < 0 ? 0 : tax;

      let loanAmountCalc =
        price - downPayment + tax - (tradeIn - tradeLoanBalance);
      loanAmountCalc = loanAmountCalc < 0 ? 0 : loanAmountCalc;

      const monthlyPayment = calculateMonthlyPayment(
        apr,
        loanTerm,
        loanAmountCalc
      );

      var calc = state?.calc || {};
      return {
        ...state,
        calc: {
          ...calc,
          monthlyPayment,
          totalDue: loanAmountCalc,
        },
      };

    default:
      throw new Error("Invalid action type");
  }
}

const ModalCarContextProvider = ({ children }) => {
  const [userData] = useContext(UserDataContext);
  const [modalCar, dispatchModalCar] = useReducer(modalCarReducer, {
    user_first_name: userData?.first_name || "",
    user_last_name: userData?.last_name || "",
    user_name: userData?.first_name + " " + userData?.last_name || "",
    user_phone: userData?.phone || "",
    user_email: userData?.email || "",
    user_zip_code: userData?.zip_code || "",
  });

  useEffect(() => {
    if (modalCar?.msg) {
      let counterLeft = modalCar?.msg?.counterLeft || 0;
      if (counterLeft > 0) {
        dispatchModalCar({
          type: "update",
          payload: { msg: { ...modalCar?.msg, counterLeft: counterLeft - 1 } },
        });
      } else {
        dispatchModalCar({ type: "update", payload: { msg: null } });
      }
    }
  }, [modalCar?.currentModal]);

  return (
    <ModalCarContext.Provider value={{ dispatchModalCar, modalCar }}>
      {children}
    </ModalCarContext.Provider>
  );
};

export default ModalCarContextProvider;

export function calculateMonthlyPayment(apr, loanTerm, loanAmount) {
  apr = apr / 100;
  if (apr === 0) {
    return loanAmount / loanTerm;
  }
  var monthlyPayment =
    (loanAmount * (apr / 12)) / (1 - Math.pow(1 + apr / 12, -loanTerm));
  return monthlyPayment;
}
/*
    let downPayment = 0;
    let price = parseInt(state?.calc_price) || 0;

    if(state?.calc_needFinancing === "yes"){
        downPayment = parseInt(state?.calc_down_payment) || 0;
    }
    let tradeIn = parseInt(state?.calc_trade_in) || 0;
    let tradeCarPayLoanBalance = parseInt(state?.calc_trade_carPayLoanBalance) || 0;
    let taxRate = parseFloat(state?.calc_tax_rate) || 0;
    let tax = (price - tradeIn ) * taxRate;
    tax = tax < 0 ? 0 : tax;

    let loanAmountCalc = (price - downPayment + tax) - (tradeIn - tradeCarPayLoanBalance);
    loanAmountCalc = loanAmountCalc < 0 ? 0 : loanAmountCalc;

    const monthlyPayment = calculateMonthlyPayment(state.calc_apr, state.calc_loan_term, loanAmountCalc);
    return {
        ...state,
        calc_monthly_payment: monthlyPayment,
        calc_total_due: loanAmountCalc,
        calc_total: loanAmountCalc,
    }
*/
