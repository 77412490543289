import React, { useEffect } from "react";
import anyImageModal from "utils/anyImageModal";
import { imageStorageUrl } from "../../../config";

var anyIM = null;

const SenderMsgImage = ({ item }) => {
  function onLoadImageScroll(e) {
    let parent = e.target.closest(".scroll-as-height-chat-body");
    if (parent) {
      parent.scrollTop += e.target.getBoundingClientRect().height;
    }
  }

  useEffect(() => {
    if (!anyIM) {
      anyIM = new anyImageModal(".any-modal-image", {
        preventDefault: true,
        image: {
          style: {
            //height: "100%"
          },
        },
        type: "canvas",
        modal: {
          style: {
            zIndex: 99999,
            //backdropFilter: "blur(50px)"
          },
        },
      });
    }
  }, []);

  return (
    <div className="sender-msg relative">
      <span
        className="sender-msg-text any-modal-image"
        time={new Date(item.message_date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      >
        <img
          onLoad={onLoadImageScroll}
          style={{
            maxWidth: "100%",
            objectFit: "contain",
            maxHeight: "300px",
            height: "100%",
            cursor: "pointer",
          }}
          src={imageStorageUrl + item.file_name}
          alt={item.file_name}
        />
      </span>
    </div>
  );
};

export default SenderMsgImage;
