
import React, { useState, createContext } from 'react'
import { useEffect } from 'react';
import { projectMode } from '../../../config';


export const ContextForm = createContext();

const FormContext = ({ children }) => {
    const initState = {
        stepType: "choose",
        loading: false,
        errorMessage: "",
    }
    const [formRegister, setFormRegister] = useState(initState);
    function appendFormRegister(value, callback = () => { }) {
        setFormRegister((pState) => {
            const newState = { ...formRegister, ...value };
            callback(newState);
            return newState;
        });
    }
    function resetFormRegister() {
        setFormRegister(initState);
    }
    function removeFormRegister(key) {
        const newFormRegister = { ...formRegister };
        delete newFormRegister[key];
        setFormRegister(newFormRegister);
    }
    function editFormRegister(key, value) {
        setFormRegister((pState) => {
            if (pState.hasOwnProperty(key)) {
                pState[key] = value;
            } else {
                if (projectMode === "DEV") {
                    console.error("DEV MODE:: key not found:", key);
                }
            }
            return { ...pState }; // new state
        });
    }

    useEffect(() => {
        editFormRegister("errorMessage", "");
    }, [formRegister.stepType]);

    return (
        <ContextForm.Provider value={{ 
            formRegister, setFormRegister, appendFormRegister,
            resetFormRegister, removeFormRegister, editFormRegister
        }}>
            {children}
        </ContextForm.Provider>
    )
}

export default FormContext