import axios from "axios";
import { baseURL } from "config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserDataContext } from "../App";
import { validateEmail, validateUSAPhoneNumber } from "../utils/validate";
import Button from "./shared/Button";
import Loading from "./Loading";
import Alert from "components/Alert";
import { toast } from "react-toastify";
import Input from "components/shared/Input/Input";
import Modal from "./shared/Modal";
import Card from "./shared/Card";
import { PencilSquareIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";

const ConfirmDeleteAccount = () => {
  const [userData, setUserData] = useContext(UserDataContext);
  const [showModal, setShowModal] = useState(false);

  function removeAccount() {
    axios({
      method: "POST",
      url: baseURL + "/seller/unlist",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: userData.token,
      },
    }).then((request) => {
      setUserData({});
    });
  }

  function RenderModalConfirmRemove() {
    return (
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "red" }}>IMPORTANT!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wish to remove your account from out platform?
          <br />
          You will lose access to your <b>bids</b> and <b>messages</b>.
        </Modal.Body>
        <Modal.Footer className="flex">
          <Button
            label="Yes, Remove"
            variant="secondary"
            onClick={() => {
              removeAccount();
            }}
          />
          <Button
            label="Cancel"
            className="mr-2"
            variant="neutral"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  return [setShowModal, RenderModalConfirmRemove];
};

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  var [userData, setUserData] = useContext(UserDataContext);
  var [hasError, setHasError] = useState("");
  var [hasSuccess] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  const [isProfileChanged, setIsProfileChanged] = useState(false);

  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    zip_code: "",
  });
  const [setShowModalRemoveAccount, RenderModalConfirmRemoveAccount] =
    ConfirmDeleteAccount();

  function showError(message) {
    setAlertShow(true);
    setAlertMessage(message);
  }
  const fetchProfile = useCallback(() => {
    axios({
      method: "GET",
      url: baseURL + "/seller/profile",
      headers: {
        Authorization: userData.token,
      },
    })
      .then((request) => {
        var data = request.data;
        if (data.phone == null) data.phone = "";

        setProfile(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [userData.token]);

  function inputProfileChange(e) {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value ? e.target.value : "",
    });
    setIsProfileChanged(true);
  }

  function submitProfileUpdate(e) {
    e.preventDefault();

    if (!profile.first_name) {
      showError("First name is required");
      return;
    }
    if (!profile?.last_name) {
      showError("Last name is required");
      return;
    }
    if (!profile?.zip_code) {
      showError("Zip code is required");
      return;
    }
    if (!validateEmail(profile.email ? profile.email : "")) {
      showError("Invalid email format");
      return;
    }
    if (profile.phone !== "") {
      if (!validateUSAPhoneNumber(profile.phone)) {
        showError("Phone number is invalid");
        return;
      }
    }
    if (profile.password) {
      if (profile.password !== profile.retype_password) {
        showError("Passwords don't match");
        return;
      }
    }
    setIsProfileChanged(false);
    setIsLoading(true);
    axios({
      method: "POST",
      url: baseURL + "/seller/profile",
      headers: {
        "Content-Type": "application/json",
        Authorization: userData.token,
      },
      data: profile,
    })
      .then((request) => {
        if (request.data.success === 0) {
          showError(request.data.error);
        } else {
          if (profile.password) profile.password_set = 1;
          setHasError("");
          //   setHasSuccess(true);
          setUserData({
            ...userData,
            ...profile,
          });
          toast.success("Profile updated");
        }
      })
      .catch((error) => {
        setHasError("Something went wrong!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  function onChangePhone(e) {
    setProfile({
      ...profile,
      phone: e.target.value,
    });
  }

  if (isLoading) {
    return <Loading>Loading profile info...</Loading>;
  }

  return (
    <div className="container p-4 lg:p-8">
      <Alert message={alertMessage} show={alertShow} setShow={setAlertShow} />
      <Button
        variant="none"
        className="!px-0 pb-5 lg:pb-10"
        onClick={() => navigate(-1)}
        label={
          <div className="flex items-center">
            <ChevronLeftIcon className="w-4 h-4 text-[#14181F]" />{" "}
            <span className="text-primary text-xs sm:text-base font-medium">
              Back to previous page
            </span>
          </div>
        }
      />
      <div>
        <div>
          <form className="flex justify-center">
            <Card className="w-full xl:w-[926px] py-7 px-3 xl:py-14 xl:px-28">
              <h4 className="mb-8 text-primary text-lg font-bold">Profile</h4>
              {hasError !== "" && (
                <div className="error-message">{hasError}</div>
              )}
              {hasError === "" && hasSuccess && (
                <div className="bg-color-success rounded text-center text-white py-2">
                  Profile Updated!
                </div>
              )}

              <div className="flex flex-col space-y-4">
                <div className="w-full flex flex-col lg:flex-row columns-2 gap-x-32 gap-y-3">
                  <Input
                    labelstyle={{ marginTop: "15px" }}
                    name="first_name"
                    onChange={inputProfileChange}
                    value={profile.first_name || ""}
                    label={<b className="text-[#111827]">First name</b>}
                    placeholder="Your first name"
                    containerClassName="w-full lg:w-1/2"
                    className="!rounded !pt-2 !pb-2"
                  />

                  <Input
                    labelstyle={{ marginTop: "15px" }}
                    name="last_name"
                    onChange={inputProfileChange}
                    value={profile.last_name || ""}
                    label={<b className="text-[#111827]">Last name</b>}
                    placeholder="Your last name"
                    containerClassName="w-full lg:w-1/2"
                    className="!rounded !pt-2 !pb-2"
                  />
                </div>
                <div
                  className={`w-full flex flex-col lg:flex-row columns-2 gap-x-32 gap-y-3 ${
                    !profile.password_set && "xl:pr-32"
                  }`}
                >
                  <Input
                    labelstyle={{ marginTop: "15px" }}
                    name="zip_code"
                    onChange={inputProfileChange}
                    value={profile.zip_code || ""}
                    label={<b className="text-[#111827]">Zip code</b>}
                    type="number"
                    max="5"
                    placeholder="Your zip code"
                    containerClassName="w-full lg:w-1/2"
                    className="!rounded !pt-2 !pb-2"
                  />
                  {!profile.password_set ? null : (
                    <div className="w-full flex flex-col lg:w-1/2">
                      <span className="text-text-[#111827] text-md font-bold text-nowrap truncate">
                        Password
                      </span>
                      <div className="pt-3 pb-2.5 pr-4 flex">
                        <div className="flex items-center gap-x-2 text-[#111827] font-bold">
                          ********
                          <Button
                            variant="none"
                            label={<PencilSquareIcon className="w-4 h-4" />}
                            className="py-0 !px-0"
                            onClick={() => navigate("/change-password")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full flex flex-col lg:pr-32 space-y-4">
                  <Input
                    labelstyle={{ marginTop: "20px" }}
                    name="email"
                    onChange={inputProfileChange}
                    value={profile.email || ""}
                    label={<b className="text-[#111827]">Email</b>}
                    placeholder="Your email (this will be your login)"
                    containerClassName="w-full lg:w-1/2"
                    className="!rounded !pt-2 !pb-2"
                  />
                  <Input
                    value={profile.phone}
                    format="phoneNumber"
                    onChange={onChangePhone}
                    name="phone"
                    label={<b className="text-[#111827]">Mobile Number</b>}
                    containerClassName="w-full lg:w-1/2"
                    className="!rounded !pt-2 !pb-2"
                  />
                </div>
                {!profile.password_set && (
                  <div className="w-full flex flex-col">
                    <div className="flex flex-col lg:flex-row columns-2 gap-x-28 gap-y-3 mb-3">
                      <Input
                        labelstyle={{ marginTop: "15px" }}
                        name="password"
                        type="password"
                        onChange={inputProfileChange}
                        label={<b className="text-[#111827]">New password</b>}
                        placeholder=""
                        autoComplete="new-password"
                        containerClassName="w-full lg:w-1/2"
                        className="!rounded !pt-2 !pb-2"
                      />
                      <Input
                        labelstyle={{ marginTop: "15px" }}
                        name="retype_password"
                        type="password"
                        onChange={inputProfileChange}
                        label={
                          <b className="text-[#111827]">Re-type password</b>
                        }
                        placeholder=""
                        autoComplete="new-password"
                        containerClassName="w-full lg:w-1/2"
                        className="!rounded !pt-2 !pb-2"
                      />
                    </div>
                    <div className="text-secondary border-t border-neutral text-center lg:text-left text-sm pt-3">
                      If you wish to create an account so you can log back in,
                      you must set up new password below.
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-center lg:justify-end mt-3">
                <Button
                  className={`bg-primary w-1/2 hover:bg-primary ${
                    isProfileChanged ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={submitProfileUpdate}
                  disabled={!isProfileChanged}
                  label={!profile.password_set ? "Save" : "Update"}
                />
              </div>
            </Card>
          </form>
        </div>
        <div className="mt-4 xl:w-[926px] lg:flex lg:items-center lg:justify-center text-sm">
          If you wish to remove your account and remove your car from our
          platform please{" "}
          <button
            className="text-primary-light underline lg:ml-1"
            onClick={() => {
              setShowModalRemoveAccount(true);
            }}
          >
            Click Here
          </button>
        </div>
        <RenderModalConfirmRemoveAccount />
      </div>
    </div>
  );
};

export default Profile;
