import Input from "components/shared/Input/Input";
import { ModalCarContext } from "components/searchCars/Context/ModalCarContext";
import { useContext, useState } from "react";
import { validateEmail } from "utils/validate";
import axios from "axios";
import { baseURL } from "config";
import { UserDataContext } from "App";
import { apiUploadProfile } from "components/searchCars/request/apiUploadProfile";
import Button from "components/shared/Button";

const YourAccount = () => {
  const { modalCar, dispatchModalCar } = useContext(ModalCarContext);
  const [userData, setUserData] = useContext(UserDataContext);
  const [visibleInput] = useState({
    name: modalCar?.user_name ? false : true,
    email: modalCar?.user_email ? false : true,
    phone: modalCar?.user_phone ? false : true,
  });
  const [form, setForm] = useState({
    name: modalCar?.user_name || "",
    email: modalCar?.user_email || "",
    phone: modalCar?.user_phone || "",
    offer:
      modalCar?.user_offer || modalCar?.calc?.price || modalCar?.price || "",
  });
  const [invalidForm, setInvalidForm] = useState({
    name: "",
    email: "",
    phone: "",
    offer: "",
  });

  function handleFormChange(e) {
    setInvalidForm({
      ...invalidForm,
      [e.target.name]: "",
    });
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  function handleSubmitRequest() {
    if (form.offer === "" || form.name === "" || !validateEmail(form.email)) {
      let newInvalid = {};
      form.visit_date === "" &&
        (newInvalid["visit_date"] = "Visit date is required!");
      form.name === "" && (newInvalid["name"] = "Name is required!");
      !validateEmail(form.email) &&
        (newInvalid["email"] = "Invalid email format!");
      form.offer === "" && (newInvalid["offer"] = "Offer is required!");
      setInvalidForm({
        ...invalidForm,
        ...newInvalid,
      });
      return;
    }
    if (isNaN(parseInt(form.offer))) {
      setInvalidForm({
        ...invalidForm,
        offer: "Offer must be a number!",
      });
      return;
    }

    const partsNames = form.name.split(" ");
    const first_name = partsNames[0];
    const last_name = partsNames.slice(1).join(" ");

    apiUploadProfile(
      {
        first_name,
        last_name,
        email: form.email,
        phone: form.phone,
        token: userData?.token,
        zip_code: modalCar?.user_zip_code,
      },
      (error, data) => {
        if (error || data?.success === 0) {
          return;
        }

        setUserData((prev) => ({
          ...prev,
          first_name,
          last_name,
          email: form.email,
          phone: form.phone,
          zip_code: modalCar?.user_zip_code,
          lastSetterBy: "yourAccount",
        }));
      }
    );

    apiRequestMakeOfferSoftLead(
      {
        code: modalCar.code,
        name: form.name,
        email: form.email,
        phone: form.phone,
        offer: form.offer,
        token: userData?.token,
      },
      (error, data) => {
        if (error || data?.success === 0) {
          dispatchModalCar({
            type: "update",
            payload: {
              msg: {
                type: "error",
                message:
                  data?.error ??
                  "Something went wrong! Please try again later.",
              },
            },
          });
          return;
        }
        dispatchModalCar({
          type: "update",
          payload: {
            user_name: form.name,
            user_email: form.email,
            user_phone: form.phone,
            user_offer: form.offer,
            msg: {
              type: null,
              message: "",
            },
            currentSubModal: "calculator",
          },
        });
        dispatchModalCar({
          type: "calc-update",
          payload: {
            price: form?.offer,
          },
        });
      }
    );
  }

  function handleBackButton() {
    dispatchModalCar({
      type: "set",
      payload: {
        currentModal: null,
      },
    });
  }

  return (
    <div className="w-full relative border-t border-neutral-light mt-4 p-4 pb-0">
      <div className="flex flex-col gap-2">
        {visibleInput.name && (
          <Input
            value={form.name}
            isInvalid={invalidForm.name}
            errorText={invalidForm.name}
            onChange={handleFormChange}
            name="name"
            rounded="xs"
            label={<div className="text-[#111827] text-base">Name</div>}
          />
        )}
        {visibleInput.email && (
          <Input
            value={form.email}
            isInvalid={invalidForm.email}
            errorText={invalidForm.email}
            onChange={handleFormChange}
            name="email"
            rounded="xs"
            label={
              <div className="text-[#111827] text-base">Email Address</div>
            }
          />
        )}
        {visibleInput.phone && (
          <Input
            value={form.phone}
            isInvalid={invalidForm.phone}
            errorText={invalidForm.phone}
            format="phoneNumber"
            onChange={handleFormChange}
            name="phone"
            rounded="xs"
            label={
              <div className="text-[#111827] text-base">
                Phone Number{" "}
                <small className="text-muted text-xs">(Optional)</small>
              </div>
            }
          />
        )}
        <Input
          value={form.offer}
          isInvalid={invalidForm.offer}
          errorText={invalidForm.offer}
          format="Number"
          onChange={handleFormChange}
          name="offer"
          rounded="xs"
          label={<div className="text-[#111827] text-base">Offer Amount</div>}
        />
      </div>

      <Button
        onClick={handleSubmitRequest}
        className="mt-6 w-full font-medium text-base"
      >
        Continue
      </Button>
      <div className="flex justify-center">
        <div
          onClick={handleBackButton}
          className="text-danger mt-2 cursor-pointer"
        >
          Return to Car Page
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 mt-6">
        <div className="w-3 h-3 rounded-full bg-secondary"></div>
        <div
          onClick={handleSubmitRequest}
          className="w-3 h-3 rounded-full bg-neutral cursor-pointer"
        ></div>
      </div>
    </div>
  );
};

export default YourAccount;

export function apiRequestMakeOfferSoftLead(
  { code, name, email, phone, offer, token },
  callback
) {
  //   /v1/seller/inventory/:code/make-offer
  let params = new URLSearchParams();
  params.append("name", name);
  params.append("email", email);
  phone && params.append("phone", phone);
  params.append("offer", offer);

  axios({
    method: "POST",
    url: `${baseURL}/seller/inventory/${code}/make-offer`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: token,
    },
    data: params,
  })
    .then((response) => {
      callback(null, response.data);
    })
    .catch((error) => {
      callback(error, null);
    });
}
