import { useState, useMemo } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useMemo(() => () => setIsOpen(true), []);
  const closeModal = useMemo(() => () => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
};

export default useModal;
