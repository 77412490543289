import { Children } from "react";
import Modal from ".";

const ModalHeader = ({ children }) => {
  const childrenArray = Children.toArray(children);
  const title = childrenArray.find((child) => child.type === Modal.Title);

  if (!title) return null;

  return (
    <div className="bg-gray-50 px-4 py-3 sm:flex sm:px-6 xl:px-8">
      {title}
    </div>
  );
};

export default ModalHeader;
