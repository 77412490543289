import { useState, useEffect, useRef, useContext } from "react";
import APRcalculatorStar from "./content/APRcalculatorStar";
import SideBarList from "./parts/SideBarList";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { ChatMessageIN } from "components/messages/ChatMessageIN";
import cn from "classnames";
import { NegotiationsContext } from "./contexts/NegotiationsContext";

const NegotiationsRoute = () => {
	const {
		chatRooms: { selected },
	} = useContext(NegotiationsContext);
	const initDefaultRef = useRef(true);
	const [activeTab, setActiveTab] = useState(1); // control view in mobile

	useEffect(() => {
		if (selected && selected.code) {
			window.history.replaceState(
				{},
				"",
				window.location.pathname + "?code=" + selected?.code
			);
		}
	}, [selected, selected?.code]);

	return (
		<div className="container py-8">
			<div className="flex xl:hidden">
				{/*  when is chat active */}
				{activeTab === 1 && (
					<div
						className="c-pointer flex text-xs items-center font-medium pb-3"
						onClick={() => setActiveTab(0)}
					>
						<ChevronLeftIcon className="w-4 h-4" />
						Back
					</div>
				)}
				{activeTab === 2 && (
					<div
						className="c-pointer flex text-xs items-center font-medium pb-3"
						onClick={() => setActiveTab(1)}
					>
						<ChevronLeftIcon className="w-4 h-4" />
						Back
					</div>
				)}
			</div>
			<div className="h-[600px] relative flex bg-white shadow rounded-xl">
				<div
					className={cn(
						"w-full xl:border-r xl:flex xl:flex-col px-0 xl:w-1/4",
						activeTab === 0 ? "flex flex-col" : "hidden"
					)}
				>
					<SideBarList
						initDefaultRef={initDefaultRef}
						setActiveTab={setActiveTab}
					/>
				</div>
				<div
					className={cn(
						"w-full xl:w-2/4 xl:flex bg-white xl:flex-col h-full m-0 border-r border-[#EBEBEB]",
						activeTab === 1 ? "flex flex-col" : "hidden"
					)}
				>
					<ChatMessageIN setActiveTab={setActiveTab} />
				</div>
				<div
					className={cn(
						"relative w-full xl:w-1/4 xl:flex xl:flex-col h-full",
						activeTab === 2 ? "flex flex-col" : "hidden"
					)}
				>
					{!selected || !selected.code ? (
						<div className="flex h-full justify-center items-center">
							<p className="text-sm text-[#8B8B8B]">
								There is no offer to show
							</p>
						</div>
					) : (
						<APRcalculatorStar />
					)}
				</div>
			</div>
		</div>
	);
};

export default NegotiationsRoute;
