import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/solid";
import ModalLookingForCar from "components/ModalLookingForCar";
import { useLookingForCar } from "components/ModalLookingForCar/ModalLookingForCar.hooks";
import Button from "components/shared/Button";
import Card from "components/shared/Card";
import useModal from "hooks/use-modal.hook";

const LookingForCar = () => {
  const lookingForCarModal = useModal();
  const { lookingForCar, refetch: fetchLookingForCar } = useLookingForCar();

  return (
    <Card>
      <div className="flex items-center justify-between py-2">
        <span className="flex items-center text-primary text-md font-medium shrink-0 mr-10">
          Looking for car ?
          <Button
            variant="none"
            onClick={() => lookingForCarModal.openModal()}
            className="!py-0 !px-0 m-0 ml-2"
          >
            <PencilSquareIcon className="h-4 w-4 text-neutral" />
          </Button>
        </span>
        <span className="border-l border-neutral-dark pl-4 flex items-center text-xs text-neutral text-justify">
          {lookingForCar?.isLooking ? (
            <span className="flex flex-col items-center text-primary">
              <CheckCircleIcon className="h-5 w-5 text-success" /> Yes
            </span>
          ) : (
            <span className="flex flex-col items-center text-primary">
              <EllipsisHorizontalCircleIcon className="h-5 w-5 text-neutral" />
              No
            </span>
          )}
        </span>
      </div>

      {lookingForCarModal.isOpen && (
        <ModalLookingForCar
          isOpen={lookingForCarModal.isOpen}
          onSave={() => {
            lookingForCarModal.closeModal();
            fetchLookingForCar();
          }}
          onClose={() => {
            lookingForCarModal.closeModal();
          }}
        />
      )}
    </Card>
  );
};

export default LookingForCar;
