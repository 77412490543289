import { useContext, useCallback } from "react";
import cn from "classnames";
import { NegotiationsContext } from "../contexts/NegotiationsContext";

const SideList = ({ setActiveTab, initDefaultRef }) => {
  const {
    chatRooms: { selected, rooms, loading },
    setChatRooms,
  } = useContext(NegotiationsContext);

  /*
    const urlParams = new URLSearchParams(window.location.search);
    const pageSize = urlParams.get('code');
  */
  const setActiveDelaer = useCallback(
    (item) => {
      setChatRooms((prevState) => ({
        ...prevState,
        selected: item,
      }));

      if (initDefaultRef?.current) {
        setActiveTab(1);
      }
      initDefaultRef.current = true;
    },
    [initDefaultRef, setActiveTab, setChatRooms]
  );

  return (
    <>
      {!loading && rooms.length === 0 && (
        <div
          style={{ marginTop: "auto", marginBottom: "auto" }}
          className="text-center"
        >
          Nothing to show!
        </div>
      )}
      {rooms.map((room, index) => {
        const item = room?.roomData;
        return (
          <div
            onClick={() => {
              setActiveDelaer(item);
            }}
            key={item.room_id}
            className={cn(
              "row-container-op1 flex cursor-pointer relative items-center justify-between p-2",
              {
                "bg-[#F9F9F9] after:content-[''] after:absolute after:inset-y-0 after:left-0 after:border-l-2 after:border-secondary":
                  selected?.room_id === item.room_id,
              }
            )}
          >
            <div className="title grow py-2 ml-3">
              <div className="text-lg font-bold text-[#000]">
                {item.dealer_name}
              </div>
            </div>
            <div className="">
              <div className="time-message">
                {new Date(item.created_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SideList;
