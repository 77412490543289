import React from 'react';
import SkeletonCarDetails from './SkeletonCarDetails';
import SkeletonMainCarousel from './SkeletonMainCarousel';

const SkeletonCarPage = () => {
  return (
    <div className="container py-4 xl:py-8">
      <div className="animate-pulse flex flex-col lg:flex-row flex-wrap">
        <div className="w-full lg:w-6/12 md:p-4">
          <SkeletonMainCarousel />
          <div className="flex flex-wrap mb-3">
            {Array.from({ length: 4 }).map((_, index) => (
              <div
              key={index}
              className="p-2 w-full sm:w-1/2 lg:w-1/4">
                <div
                  className="shadow-lg rounded-lg p-2 flex items-center gap-4 animate-pulse"
                >
                  <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
                  <div className="flex flex-col text-xs text-primary">
                    <span className="font-bold bg-gray-300 h-4 w-8 mb-1 rounded"></span>
                    <span className="font-normal bg-gray-300 h-3 w-16 rounded"></span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-6/12 md:p-4">
          <div className="bg-gray-300 h-8 w-36 mb-4"></div>
          <div className="bg-gray-300 h-4 w-full mb-4"></div>
          <div className="bg-gray-300 h-4 w-5/6 mb-4"></div>
          <div className="bg-gray-300 h-24 w-full p-2 mb-4">
            <div className="bg-gray-200 w-12 my-2 h-4"></div>
            <div className="flex justify-between items-end">
              <div className="bg-gray-200 h-4 w-14"></div>
              <div className="bg-gray-200 h-3 w-8"></div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="skeleton-buttons flex gap-5">
              <div className="skeleton-button bg-gray-300 w-1/2 h-5"></div>
              <div className="skeleton-button bg-gray-300 w-1/2 h-5"></div>
            </div>
            <div className="skeleton-button w-full bg-gray-300 h-5 mt-4"></div>
          </div>
        </div>
        <div className="w-full lg-w-1/2 mt-4 md:p-4">
          <SkeletonCarDetails />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCarPage;
