import React from "react";
import Modal from "./shared/Modal";
import Button from "./shared/Button";

const Alert = ({ message, setShow, show }) => {
  return (
    <Modal show={show} withCloseButton size="sm">
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Alert;
