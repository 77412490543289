const VehicleInfoBox = ({ icon, title, value }) => {
	return (
		<div className="bg-white shadow-box rounded-lg p-2 h-auto flex shrink-0 items-center gap-4 w-[120px] sm:w-[160px]">
			{icon}
			<div className="flex flex-col text-xs text-primary">
				<span className="font-bold text-sm break-all">{title}</span>
				<span className="font-normal">{value}</span>
			</div>
		</div>
	);
};

export default VehicleInfoBox;
